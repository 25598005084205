import React from 'react';
import styled from 'styled-components';
import { Button, Container, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';

const CustomContainer = styled(Container)`
  padding: 10px;
  display: flex;
`;

function RegisterManagerInput({
  managerName,
  managerCellphone,
  managerEmail,
  managerLoginId,
  managerPassword,
  managerConfirmPassword,
  onChangeManagerLoginId,
  onChangeManagerPassword,
  onChangeManagerEmail,
  onChangeManagerName,
  onChangeManagerCellphone,
  onChangeManagerConfirmPassword,
  onRegisterManager,
}) {
  return (
    <CustomContainer>
      <TextField
        label="성명"
        value={managerName}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={e => onChangeManagerName(e.target.value)}
      />
      <TextField
        label="연락처"
        value={managerCellphone}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={e => onChangeManagerCellphone(e.target.value)}
      />
      <TextField
        label="이메일"
        value={managerEmail}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={e => onChangeManagerEmail(e.target.value)}
      />
      <TextField
        label="아이디"
        value={managerLoginId}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={e => onChangeManagerLoginId(e.target.value)}
      />
      <TextField
        label="비밀번호"
        value={managerPassword}
        variant="standard"
        style={{ marginRight: 15 }}
        type="password"
        onChange={e => onChangeManagerPassword(e.target.value)}
      />
      <TextField
        label="비밀번호 확인"
        value={managerConfirmPassword}
        variant="standard"
        style={{ marginRight: 15 }}
        type="password"
        onChange={e => onChangeManagerConfirmPassword(e.target.value)}
      />
      <Button variant="outlined" onClick={onRegisterManager}>
        등록
      </Button>
    </CustomContainer>
  );
}

RegisterManagerInput.propTypes = {
  managerName: PropTypes.string,
  managerCellphone: PropTypes.string,
  managerEmail: PropTypes.string,
  managerLoginId: PropTypes.string,
  managerPassword: PropTypes.string,
  managerConfirmPassword: PropTypes.string,
  onChangeManagerName: PropTypes.func,
  onChangeManagerCellphone: PropTypes.func,
  onChangeManagerEmail: PropTypes.func,
  onChangeManagerLoginId: PropTypes.func,
  onChangeManagerPassword: PropTypes.func,
  onChangeManagerConfirmPassword: PropTypes.func,
  onRegisterManager: PropTypes.func,
};

export default RegisterManagerInput;
