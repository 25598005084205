import React from 'react';
import styled from 'styled-components';
import { Button, Container, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const CustomContainer = styled(Container)`
  padding: 10px;
`;

function RegisterModemInput({
  modemModelList,
  modemModel,
  modemImei,
  modemVersion,
  modemLabel,
  onChangeModemImei,
  onRegisterModem,
  onChangeModemLabel,
  onChangeModemVersion,
  onChangeModemModel,
}) {
  return (
    <CustomContainer>
      <FormControl variant="standard">
        <InputLabel>모델</InputLabel>
        <Select
          label="모델"
          displayEmpty
          value={modemModel || ''}
          style={{ width: 180, marginRight: 10 }}
          onChange={event => onChangeModemModel(event.target.value)}
        >
          {modemModelList.map(item => (
            <MenuItem key={item.uid} value={item}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="라벨"
        value={modemLabel}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={e => onChangeModemLabel(e.target.value)}
      />
      <TextField
        label="IMEI"
        value={modemImei}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={e => onChangeModemImei(e.target.value)}
      />
      <TextField
        label="단말기 버전"
        value={modemVersion}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={e => onChangeModemVersion(e.target.value)}
      />
      <Button variant="outlined" onClick={onRegisterModem}>
        등록
      </Button>
    </CustomContainer>
  );
}

RegisterModemInput.propTypes = {
  modemModelList: PropTypes.array,
  modemModel: PropTypes.object,
  modemImei: PropTypes.string,
  modemLabel: PropTypes.string,
  modemVersion: PropTypes.string,
  onChangeModemLabel: PropTypes.func,
  onChangeModemImei: PropTypes.func,
  onChangeModemVersion: PropTypes.func,
  onRegisterModem: PropTypes.func,
  onChangeModemModel: PropTypes.func,
};

export default RegisterModemInput;
