import { createAction } from '@reduxjs/toolkit';

/**
 * 모든 Reducer state 초기화 Action
 */
export const CLEAR_ALL_STATE = 'GLOBAL/CLEAR_ALL_STATE';
export const clearAllState = createAction(CLEAR_ALL_STATE);

/**
 * loading indicator 상태 Action
 */
export const CHANGE_LOADING_INDICATOR_STATUS =
  'GLOBAL/CHANGE_LOADING_INDICATOR_STATUS';
export const changeLoadingIndicatorStatus = createAction(
  CHANGE_LOADING_INDICATOR_STATUS,
);
