import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'components/common/modal';
import PageTitle from 'components/common/page-title';
import { Button, Container, Stack, TextField } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeAttachments,
  changeUpdateModemModelDescription,
  changeUpdateModemModelDiscontinuedDate,
  changeUpdateModemModelName,
  changeUpdateModemModelProducer,
  changeUpdateModemModelProductionDate,
  requestPreSignedURL,
  updateModemModelInfo,
} from 'reducers/modem-model/action';
import dayjs from 'dayjs';
import { modemModelStateSelector } from 'reducers/modem-model/reducer';
import FormControl from '@mui/material/FormControl';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const CustomContainer = styled(Container)`
  padding: 10px;
`;

export default function UpdateModemModelInfoModal({
  isVisibleModifyModemInfoModal,
  onChangeModifyModemInfoModalStatus,
}) {
  const onClickBackdrop = () => {
    onChangeModifyModemInfoModalStatus(false);
  };

  const dispatch = useDispatch();

  function onChangeUpdateModemModelName(name) {
    dispatch(changeUpdateModemModelName(name));
  }
  function onChangeUpdateModemModelProducer(producer) {
    dispatch(changeUpdateModemModelProducer(producer));
  }
  function onChangeUpdateModemModelDescription(description) {
    dispatch(changeUpdateModemModelDescription(description));
  }
  function onChangeUpdateModemModelProductionDate(productionDate) {
    dispatch(
      changeUpdateModemModelProductionDate(
        dayjs(productionDate).format('YYYY-MM-DD'),
      ),
    );
  }
  function onChangeUpdateModemModelDiscontinuedDate(discontinuedDate) {
    dispatch(
      changeUpdateModemModelDiscontinuedDate(
        dayjs(discontinuedDate).format('YYYY-MM-DD'),
      ),
    );
  }
  function onChangeUpdateModemModelAttachments(attachments) {
    dispatch(changeAttachments(attachments));
    dispatch(requestPreSignedURL());
  }

  function onUpdateModemModelInfo() {
    dispatch(updateModemModelInfo());
    onClickBackdrop();
  }

  const { updateModemModel, registerAttachments } = useSelector(
    modemModelStateSelector,
  );

  return (
    <CommonModal
      visible={isVisibleModifyModemInfoModal}
      onClose={onClickBackdrop}
    >
      <Container
        style={{
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <PageTitle>모델 정보 수정</PageTitle>
        <CustomContainer>
          <Stack spacing={2}>
            <Stack spacing={2} direction="row">
              <TextField
                label="모델명"
                value={updateModemModel.name}
                variant="standard"
                style={{ width: 300 }}
                onChange={e => onChangeUpdateModemModelName(e.target.value)}
              />
              <TextField
                label="제조사"
                value={updateModemModel.producer}
                variant="standard"
                style={{ width: 300 }}
                onChange={e => onChangeUpdateModemModelProducer(e.target.value)}
              />
            </Stack>
            <div>
              <TextField
                label="상세 스펙"
                value={updateModemModel.description}
                aria-placeholder="상세 스펙을 입력하세요."
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                onChange={e =>
                  onChangeUpdateModemModelDescription(e.target.value)
                }
              />
            </div>
            <FormControl style={{ width: 600 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack direction="row" spacing={1}>
                  <DesktopDatePicker
                    defaultValue={null}
                    label="생산 일자"
                    mask="____/__/__"
                    inputFormat="yyyy/MM/dd"
                    value={
                      updateModemModel.productionDate != null
                        ? updateModemModel.productionDate
                        : null
                    }
                    onChange={onChangeUpdateModemModelProductionDate}
                    renderInput={params => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    defaultValue={null}
                    label="단종 일자"
                    mask="____/__/__"
                    inputFormat="yyyy/MM/dd"
                    value={
                      updateModemModel.discontinuedDate != null
                        ? updateModemModel.discontinuedDate
                        : null
                    }
                    onChange={onChangeUpdateModemModelDiscontinuedDate}
                    renderInput={params => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl>
            <div>
              <Button
                variant="contained"
                component="label"
                style={{ width: 150, marginRight: 5 }}
              >
                첨부파일 업로드
                <input
                  type="file"
                  hidden
                  onChange={event =>
                    onChangeUpdateModemModelAttachments(event.target.files[0])
                  }
                />
              </Button>
              {registerAttachments.name}
            </div>
          </Stack>
        </CustomContainer>
        <Container style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button variant="outlined" onClick={onUpdateModemModelInfo}>
            변경
          </Button>
        </Container>
      </Container>
    </CommonModal>
  );
}

UpdateModemModelInfoModal.propTypes = {
  isVisibleModifyModemInfoModal: PropTypes.bool,
  onChangeModifyModemInfoModalStatus: PropTypes.func,
};
