import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CommonAppBar from 'components/common/app-bar';
import CommonDrawer from 'components/common/drawer';
import { useDispatch } from 'react-redux';
import { loadCompanyInfo } from 'reducers/company/action';
import { loadMyInfo } from 'reducers/manager/action';
import { USER_ROLE } from 'constants/UserType';
import { getTokenRole } from 'commons/token-helper';
import { ToastContainer } from 'react-toastify';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }),
);

const defaultTheme = createTheme({
  palette: {
    primary: {
      light: '#4b4f58',
      main: '#1e232f',
      dark: '#151820',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Nanum Gothic', 'Arial'].join(','),
    body1: {
      fontSize: '12px',
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minWidth: '1024px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'rgb(30, 35, 47)',
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
    padding: '50px',
  },
  toolbar: {
    padding: '24px',
  },
}));

function MainLayout() {
  const classes = useStyles(defaultTheme);

  const dispatch = useDispatch();
  const [isOpenDrawer, setIsOpenDrawer] = useState(true);

  const handleOpenDrawer = () => {
    setIsOpenDrawer(prevState => !prevState);
  };

  useEffect(() => {
    dispatch(loadCompanyInfo());
    const userType = getTokenRole();
    if (userType !== USER_ROLE.USER) {
      dispatch(loadMyInfo());
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className={classes.root}>
        <CssBaseline />
        <CommonAppBar
          isOpenDrawer={isOpenDrawer}
          handleOpenDrawer={handleOpenDrawer}
        />
        <CommonDrawer
          isOpenDrawer={isOpenDrawer}
          handleOpenDrawer={handleOpenDrawer}
        />
        <Main open={isOpenDrawer}>
          <div className={classes.toolbar} />
          <Outlet />
        </Main>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  );
}

export default MainLayout;
