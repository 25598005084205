import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card, Container, TextField, Button } from '@mui/material';
import PageTitle from 'components/common/page-title';
import ContainerSubTitle from 'components/common/sub-title';
import { useDispatch, useSelector } from 'react-redux';
import { companyStateSelector } from 'reducers/company/reducer';
import { managerStateSelector } from 'reducers/manager/reducer';
import {
  changeConfirmNewPassword,
  changeNewMyName,
  changeNewMyEmail,
  changeNewMyCellphone,
  changeNewPassword,
  changeOldPassword,
  clearMyInfo,
  updateMyInfo,
  updatePassword,
} from 'reducers/manager/action';
import { getTokenRole } from 'commons/token-helper';
import { updateUserPassword } from 'reducers/user/action';
import { USER_ROLE } from 'constants/UserType';

export default function MyPage() {
  const { selectedCompany } = useSelector(companyStateSelector);
  const {
    newMyInfo,
    oldPassword,
    newPassword,
    confirmNewPassword,
  } = useSelector(managerStateSelector);

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(clearMyInfo()), []);

  function onChangeUpdateMyName(e) {
    dispatch(changeNewMyName(e.target.value));
  }

  function onChangeUpdateMyCellphone(e) {
    dispatch(changeNewMyCellphone(e.target.value));
  }

  function onChangeUpdateMyEmail(e) {
    dispatch(changeNewMyEmail(e.target.value));
  }

  function onClickUpdateMyInfo() {
    dispatch(updateMyInfo());
  }

  function onChangeOldPassword(e) {
    dispatch(changeOldPassword(e.target.value));
  }

  function onChangeNewPassword(e) {
    dispatch(changeNewPassword(e.target.value));
  }

  function onChangeConfirmNewPassword(e) {
    dispatch(changeConfirmNewPassword(e.target.value));
  }

  function onClickUpdatePassword() {
    const userType = getTokenRole();
    if (userType === USER_ROLE.USER) {
      dispatch(updateUserPassword());
    } else {
      dispatch(updatePassword());
    }
  }

  return (
    <MyPageWrapper>
      <StyledCard>
        <StyledPageTitle>마이 페이지</StyledPageTitle>
        <Container>
          <div style={{ marginLeft: '20%', marginBottom: '15px' }}>
            <ContainerSubTitle>내 정보 수정</ContainerSubTitle>
            <div style={{ height: '10px' }} />
            <StyledInfoDiv>
              <StyledInfoText>회사명</StyledInfoText>
              <StyledTextField
                multiline
                size="small"
                value={selectedCompany.companyName}
                disabled
              />
            </StyledInfoDiv>
            <StyledInfoDiv>
              <StyledInfoText>성명</StyledInfoText>
              <StyledTextField
                multiline
                size="small"
                value={newMyInfo.name}
                onChange={e => onChangeUpdateMyName(e)}
              />
            </StyledInfoDiv>
            <StyledInfoDiv>
              <StyledInfoText>전화번호</StyledInfoText>
              <StyledTextField
                multiline
                size="small"
                value={newMyInfo.cellphone}
                onChange={e => onChangeUpdateMyCellphone(e)}
              />
            </StyledInfoDiv>
            <StyledInfoDiv>
              <StyledInfoText>이메일</StyledInfoText>
              <StyledTextField
                multiline
                size="small"
                value={newMyInfo.email}
                onChange={e => onChangeUpdateMyEmail(e)}
              />
            </StyledInfoDiv>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Button variant="outlined" onClick={onClickUpdateMyInfo}>
              수정
            </Button>
          </div>
          <div style={{ marginLeft: '20%', marginBottom: '15px' }}>
            <ContainerSubTitle>비밀번호 변경</ContainerSubTitle>
            <div style={{ height: '10px' }} />
            <StyledInfoDiv>
              <StyledInfoText>현재 비밀번호</StyledInfoText>
              <StyledTextField
                label="현재 비밀번호"
                size="small"
                type="password"
                value={oldPassword}
                onChange={e => onChangeOldPassword(e)}
              />
            </StyledInfoDiv>
            <StyledInfoDiv>
              <StyledInfoText>새 비밀번호</StyledInfoText>
              <StyledTextField
                label="새 비밀번호"
                size="small"
                type="password"
                value={newPassword}
                onChange={e => onChangeNewPassword(e)}
              />
            </StyledInfoDiv>
            <StyledInfoDiv>
              <StyledInfoText>새 비밀번호 확인</StyledInfoText>
              <StyledTextField
                label="새 비밀번호 확인"
                size="small"
                type="password"
                value={confirmNewPassword}
                onChange={e => onChangeConfirmNewPassword(e)}
              />
            </StyledInfoDiv>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Button variant="outlined" onClick={() => onClickUpdatePassword()}>
              변경
            </Button>
          </div>
        </Container>
      </StyledCard>
    </MyPageWrapper>
  );
}

const StyledCard = styled(Card)`
  border-radius: 20px;
  padding: 5px;
  margin-bottom: 10px;
  width: 1000px;
`;

const StyledPageTitle = styled(PageTitle)`
  text-align: center;
`;

const StyledInfoDiv = styled.div`
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
`;

const StyledInfoText = styled.div`
  padding: 5px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  width: 100px;
  align-items: center; /*세로 정렬*/
  justify-content: center; /*가로 정렬*/
`;

const StyledTextField = styled(TextField)`
  width: 60%;
`;

const MyPageWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
