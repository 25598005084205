import executeAxios from 'commons/http-request-helper';

export async function requestGetLogData(filter) {
  const url = '/logs';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  const params = { ...filter };
  return executeAxios(url, method, headers, requestBody, params);
}

/**
 * v2_logs 데이터 조회 API
 * @param filter
 * @returns {Promise<*|{body: *, statusCode: *}>}
 */
export async function requestGetV2LogsData(filter) {
  const url = '/v2-logs';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  const params = { ...filter };
  return executeAxios(url, method, headers, requestBody, params);
}

/**
 * v2_logs 시간대별 데이터 조회 API
 * @returns {Promise<*|{body: *, statusCode: *}>}
 * @param filter
 */
export async function requestGetV2LogsTimeData(filter) {
  const url = '/v2-logs/time-data';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  const params = { ...filter };
  return executeAxios(url, method, headers, requestBody, params);
}

/**
 * v2-raw-logs 데이터 조회 API
 * @param filter
 * @returns {Promise<*>}
 */
export async function requestGetV2RawLogsData(filter) {
  const url = '/v2-raw-logs';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  const params = { ...filter };
  return executeAxios(url, method, headers, requestBody, params);
}
