/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_ATTACHMENTS,
  CHANGE_MODEM_MODEL_DESCRIPTION,
  CHANGE_MODEM_MODEL_DISCONTINUED_DATE,
  CHANGE_MODEM_MODEL_LIST,
  CHANGE_MODEM_MODEL_NAME,
  CHANGE_MODEM_MODEL_PRODUCER,
  CHANGE_MODEM_MODEL_PRODUCTION_DATE,
  CHANGE_PRE_SIGNED_URL,
  CHANGE_SEARCH_MODEM_MODEL_NAME,
  CHANGE_SEARCH_MODEM_MODEL_PRODUCER,
  CHANGE_SELECTED_UPDATE_MODEM_MODEL,
  CHANGE_UPDATE_MODEM_MODEL_DESCRIPTION,
  CHANGE_UPDATE_MODEM_MODEL_DISCONTINUED_DATE,
  CHANGE_UPDATE_MODEM_MODEL_MODAL_STATUS,
  CHANGE_UPDATE_MODEM_MODEL_NAME,
  CHANGE_UPDATE_MODEM_MODEL_PRODUCER,
  CHANGE_UPDATE_MODEM_MODEL_PRODUCTION_DATE,
} from 'reducers/modem-model/action';

const initialState = {
  registerModemModelInfo: {
    name: '',
    producer: '',
    description: '',
    productionDate: null,
    discontinuedDate: null,
  },
  registerAttachments: {},
  preSignedURL: null,
  modemModelList: [],
  searchFilter: {
    name: '',
    producer: '',
  },
  updateModemModel: {
    selectedModemModel: {},
    name: '',
    producer: '',
    description: '',
    productionDate: null,
    discontinuedDate: null,
  },
  isVisibleUpdateModemModelInfoModal: false,
};

export const modemModelStateSelector = state => state.modemModelReducer;

export default createReducer(initialState, {
  [CHANGE_MODEM_MODEL_NAME]: (state, { payload: name }) => {
    state.registerModemModelInfo.name = name;
  },
  [CHANGE_MODEM_MODEL_PRODUCER]: (state, { payload: producer }) => {
    state.registerModemModelInfo.producer = producer;
  },
  [CHANGE_MODEM_MODEL_DESCRIPTION]: (state, { payload: description }) => {
    state.registerModemModelInfo.description = description;
  },
  [CHANGE_MODEM_MODEL_PRODUCTION_DATE]: (
    state,
    { payload: productionDate },
  ) => {
    state.registerModemModelInfo.productionDate = productionDate;
  },
  [CHANGE_MODEM_MODEL_DISCONTINUED_DATE]: (
    state,
    { payload: discontinuedDate },
  ) => {
    state.registerModemModelInfo.discontinuedDate = discontinuedDate;
  },
  [CHANGE_ATTACHMENTS]: (state, { payload: attachments }) => {
    state.registerAttachments = attachments;
  },
  [CHANGE_PRE_SIGNED_URL]: (state, { payload: preSignedURL }) => {
    state.preSignedURL = preSignedURL;
  },
  [CHANGE_SEARCH_MODEM_MODEL_NAME]: (state, { payload: name }) => {
    state.searchFilter.name = name;
  },
  [CHANGE_SEARCH_MODEM_MODEL_PRODUCER]: (state, { payload: producer }) => {
    state.searchFilter.producer = producer;
  },
  [CHANGE_MODEM_MODEL_LIST]: (state, { payload: modemModelList }) => {
    state.modemModelList = modemModelList;
  },
  [CHANGE_UPDATE_MODEM_MODEL_MODAL_STATUS]: (
    state,
    { payload: modalStatus },
  ) => {
    state.isVisibleUpdateModemModelInfoModal = modalStatus;
  },
  [CHANGE_SELECTED_UPDATE_MODEM_MODEL]: (
    state,
    { payload: selectedModemModel },
  ) => {
    state.updateModemModel.selectedModemModel = selectedModemModel;

    state.updateModemModel.name = selectedModemModel.name;
    state.updateModemModel.producer = selectedModemModel.producer;
    state.updateModemModel.description = selectedModemModel.description;
    state.updateModemModel.productionDate = selectedModemModel.productionDate;
    state.updateModemModel.discontinuedDate =
      selectedModemModel.discontinuedDate;
    state.updateModemModel.attachmentsURL = selectedModemModel.attachmentsURL;
  },
  [CHANGE_UPDATE_MODEM_MODEL_NAME]: (state, { payload: name }) => {
    state.updateModemModel.name = name;
  },
  [CHANGE_UPDATE_MODEM_MODEL_PRODUCER]: (state, { payload: producer }) => {
    state.updateModemModel.producer = producer;
  },
  [CHANGE_UPDATE_MODEM_MODEL_DESCRIPTION]: (
    state,
    { payload: description },
  ) => {
    state.updateModemModel.description = description;
  },
  [CHANGE_UPDATE_MODEM_MODEL_PRODUCTION_DATE]: (
    state,
    { payload: productionDate },
  ) => {
    state.updateModemModel.productionDate = productionDate;
  },
  [CHANGE_UPDATE_MODEM_MODEL_DISCONTINUED_DATE]: (
    state,
    { payload: discontinuedDate },
  ) => {
    state.updateModemModel.discontinuedDate = discontinuedDate;
  },
});
