import React, { useEffect } from 'react';
import BasicCard from 'components/dashboard/basic-card';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { loadDashboardData } from 'reducers/dashboard/action';
import { companyStateSelector } from 'reducers/company/reducer';
import { dashboardStateSelector } from 'reducers/dashboard/reducer';
import { getTokenRole } from 'commons/token-helper';
import { USER_ROLE } from 'constants/UserType';

const CardsContainer = styled.div`
  float: left;
`;

function Dashboard() {
  const dispatch = useDispatch();

  const { selectedCompany } = useSelector(companyStateSelector);
  const { dashboardData } = useSelector(dashboardStateSelector);

  useEffect(() => {
    dispatch(loadDashboardData());
  }, [selectedCompany]);

  const userType = getTokenRole();

  return (
    <div>
      <CardsContainer>
        <BasicCard
          title="등록 단말기"
          data={dashboardData.registerModemCount}
          url="/main/modem"
        />
        {userType === USER_ROLE.SUPER_ADMIN && (
          <BasicCard
            title="미등록 단말기"
            data={dashboardData.noRegisterModemCount}
            url="/main/no-register-modem"
          />
        )}
        <BasicCard
          title="등록 암호화 방식"
          data={dashboardData.registerEncryptKeyCount}
          url="/main/encrypt-key"
        />
      </CardsContainer>
    </div>
  );
}

export default Dashboard;
