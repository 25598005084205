import React from 'react';
import PageTitle from 'components/common/page-title';
import { Container } from '@mui/material';
import { CommonCard } from 'components/common/card';
import ContainerSubTitle from 'components/common/sub-title';
import RegisterCompanyInput from 'components/company/register-company-input';
import { useDispatch, useSelector } from 'react-redux';
import { companyStateSelector } from 'reducers/company/reducer';
import {
  changeCompanyAddress,
  changeCompanyName,
  changeCompanyPhone,
  changeCompanyRegisterNum,
  changeModifyCompanyModalStatus,
  registerCompany,
} from 'reducers/company/action';
import CompanyTable from 'components/company/company-table';

export default function Company() {
  const dispatch = useDispatch();

  const {
    companyList,
    registerCompanyInfo,
    isVisibleModifyCompanyModal,
  } = useSelector(companyStateSelector);

  function onChangeCompanyName(companyName) {
    dispatch(changeCompanyName(companyName));
  }

  function onChangeCompanyAddress(companyAddress) {
    dispatch(changeCompanyAddress(companyAddress));
  }

  function onChangeCompanyPhone(companyPhone) {
    dispatch(changeCompanyPhone(companyPhone));
  }

  function onChangeCompanyRegisterNum(companyRegisterNum) {
    dispatch(changeCompanyRegisterNum(companyRegisterNum));
  }

  function onRegisterCompany() {
    dispatch(registerCompany());
  }

  function onChangeModifyCompanyModalStatus(isVisibleModalStatus) {
    dispatch(changeModifyCompanyModalStatus(isVisibleModalStatus));
  }

  return (
    <Container>
      <PageTitle>회사 관리</PageTitle>
      <Container style={{ marginBottom: 20, padding: 20 }}>
        <CommonCard>
          <ContainerSubTitle>회사 등록</ContainerSubTitle>
          <RegisterCompanyInput
            companyName={registerCompanyInfo.companyName}
            companyPhone={registerCompanyInfo.companyPhone}
            companyAddress={registerCompanyInfo.companyAddress}
            companyRegisterNum={registerCompanyInfo.companyRegisterNum}
            onChangeCompanyName={onChangeCompanyName}
            onChangeCompanyPhone={onChangeCompanyPhone}
            onChangeCompanyAddress={onChangeCompanyAddress}
            onChangeCompanyRegisterNum={onChangeCompanyRegisterNum}
            onRegisterCompany={onRegisterCompany}
          />
        </CommonCard>
      </Container>
      <Container>
        <ContainerSubTitle>등록 회사 리스트</ContainerSubTitle>
        <div style={{ height: 20 }} />
        <CompanyTable
          data={companyList}
          onChangeModifyCompanyModalStatus={onChangeModifyCompanyModalStatus}
          isVisibleModifyCompanyModal={isVisibleModifyCompanyModal}
        />
      </Container>
    </Container>
  );
}
