import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'components/common/modal';
import PageTitle from 'components/common/page-title';
import { Button, Container, TextField } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeNewManagerCellphone,
  changeNewManagerEmail,
  changeNewManagerName,
  updateManagerInfo,
} from 'reducers/manager/action';
import { managerStateSelector } from 'reducers/manager/reducer';

const CustomContainer = styled(Container)`
  padding: 10px;
`;

export default function UpdateManagerModal({
  isVisibleUpdateManagerInfoModal,
  onChangeUpdateManagerInfoModalStatus,
}) {
  const onClickBackdrop = () => {
    onChangeUpdateManagerInfoModalStatus(false);
  };

  const dispatch = useDispatch();

  function onChangeNewManagerName(event) {
    dispatch(changeNewManagerName(event.target.value));
  }

  function onChangeNewManagerCellphone(event) {
    dispatch(changeNewManagerCellphone(event.target.value));
  }

  function onChangeNewManagerEmail(event) {
    dispatch(changeNewManagerEmail(event.target.value));
  }

  function onUpdateManagerInfo() {
    dispatch(updateManagerInfo());
    onClickBackdrop();
  }

  const { newManagerInfo } = useSelector(managerStateSelector);

  return (
    <CommonModal
      visible={isVisibleUpdateManagerInfoModal}
      onClose={onClickBackdrop}
    >
      <Container
        style={{
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <PageTitle>관리자 정보 수정</PageTitle>
        <CustomContainer>
          <TextField
            label="성명"
            value={newManagerInfo.name}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={onChangeNewManagerName}
          />
          <TextField
            label="연락처"
            value={newManagerInfo.cellphone}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={onChangeNewManagerCellphone}
          />
          <TextField
            label="이메일"
            value={newManagerInfo.email}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={onChangeNewManagerEmail}
          />
        </CustomContainer>
        <Container style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button variant="outlined" onClick={onUpdateManagerInfo}>
            변경
          </Button>
        </Container>
      </Container>
    </CommonModal>
  );
}

UpdateManagerModal.propTypes = {
  isVisibleUpdateManagerInfoModal: PropTypes.bool,
  onChangeUpdateManagerInfoModalStatus: PropTypes.func,
};
