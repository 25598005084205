import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'components/common/modal';
import PageTitle from 'components/common/page-title';
import { Button, Container, TextField } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeModifyCompanyAddress,
  changeModifyCompanyName,
  changeModifyCompanyRegisterNum,
  modifyCompany,
} from 'reducers/company/action';
import { companyStateSelector } from 'reducers/company/reducer';

const CustomContainer = styled(Container)`
  padding: 10px;
`;

export default function ModifyCompanyModal({
  isVisibleModifyCompanyModal,
  onChangeModifyCompanyModalStatus,
}) {
  const onClickBackdrop = () => {
    onChangeModifyCompanyModalStatus(false);
  };

  const dispatch = useDispatch();

  const { modifyCompanyInfo } = useSelector(companyStateSelector);

  function onChangeModifyCompanyName(companyName) {
    dispatch(changeModifyCompanyName(companyName));
  }
  function onChangeModifyCompanyAddress(companyAddress) {
    dispatch(changeModifyCompanyAddress(companyAddress));
  }
  function onChangeModifyCompanyPhone(companyPhone) {
    dispatch(changeModifyCompanyAddress(companyPhone));
  }
  function onChangeModifyCompanyRegisterNum(companyRegisterNum) {
    dispatch(changeModifyCompanyRegisterNum(companyRegisterNum));
  }

  function onModifyCompany() {
    dispatch(modifyCompany());
    onClickBackdrop();
  }

  return (
    <CommonModal
      visible={isVisibleModifyCompanyModal}
      onClose={onClickBackdrop}
    >
      <Container
        style={{
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <PageTitle>회사 정보 수정</PageTitle>
        <CustomContainer>
          <TextField
            label="회사명"
            value={modifyCompanyInfo.companyName}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={e => onChangeModifyCompanyName(e.target.value)}
          />
          <TextField
            label="회사 주소"
            value={modifyCompanyInfo.companyAddress}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={e => onChangeModifyCompanyAddress(e.target.value)}
          />
          <TextField
            label="대표 번호"
            value={modifyCompanyInfo.companyPhone}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={e => onChangeModifyCompanyPhone(e.target.value)}
          />
          <TextField
            label="사업자 등록 번호"
            value={modifyCompanyInfo.companyRegisterNum}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={e => onChangeModifyCompanyRegisterNum(e.target.value)}
          />
        </CustomContainer>
        <Container style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button variant="outlined" onClick={onModifyCompany}>
            변경
          </Button>
        </Container>
      </Container>
    </CommonModal>
  );
}

ModifyCompanyModal.propTypes = {
  isVisibleModifyCompanyModal: PropTypes.bool,
  onChangeModifyCompanyModalStatus: PropTypes.func,
};
