import KJUR from 'jsrsasign';
import moment from 'moment';
import { getApiEndpoint } from 'commons/http-request-helper';
import axios from 'axios';
import { USER_ROLE } from 'constants/UserType';
import { toast } from 'react-toastify';

export function getTokenPayloadValue(key) {
  try {
    const token = localStorage.getItem('token');
    const jwtData = KJUR.jws.JWS.parse(token, '', {
      alg: ['HS512'],
    });
    return jwtData.payloadObj.USER[key];
  } catch (e) {
    return '';
  }
}

export function getARMTokenPayloadValue(key) {
  try {
    const token = localStorage.getItem('token');
    const jwtData = KJUR.jws.JWS.parse(token, '', {
      alg: ['HS512'],
    });
    return jwtData.payloadObj[key];
  } catch (e) {
    return '';
  }
}

export function getTokenRole() {
  const token = localStorage.getItem('token');
  const jwtData = KJUR.jws.JWS.parse(token, '', {
    alg: ['HS512'],
  });
  try {
    // ARM Token
    if (jwtData.payloadObj.roles) {
      return jwtData.payloadObj.roles[0];
    }
    return jwtData.payloadObj.USER.userType;
  } catch (ignore) {
    return USER_ROLE.USER;
  }
}

async function getRefreshToken(refreshToken) {
  const endpoint = getApiEndpoint();
  const url = '/oauth2/refresh-token';
  const method = 'get';
  const headers = {};
  const params = { refreshToken };
  const data = {};
  const result = await axios
    .request({
      baseURL: endpoint,
      url,
      method,
      headers: {
        ...headers,
      },
      data,
      params,
      timeout: 1000 * 30,
      validateStatus: () => true,
    })
    .then(response => ({
      statusCode: response.status,
      body: response.data,
    }))
    .catch(error => {
      throw error;
    });

  localStorage.setItem('token', result.body.data.access_token);
  localStorage.setItem('refreshToken', result.body.data.refresh_token);
}

export async function isValidToken() {
  const token = localStorage.getItem('token');
  try {
    if (!token) {
      throw new Error('로그인 정보 만료');
    }

    const jwtData = KJUR.jws.JWS.parse(token, '', {
      alg: ['HS512'],
    });

    const now = parseInt(
      moment()
        .format('X')
        .toString(),
      10,
    );

    if (jwtData.payloadObj.exp < now) {
      const refreshToken = localStorage.getItem('refreshToken');
      try {
        if (!refreshToken) {
          throw new Error('리프레쉬 토큰 없음');
        }
        await getRefreshToken(refreshToken);
      } catch (e) {
        throw new Error('로그인 정보 만료');
      }
    }
    return true;
  } catch (e) {
    localStorage.clear();
    toast.info('로그인 정보가 만료되었습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    window.location.replace('/#/sign-in');
    return false;
  }
}
