import { select, call, takeLatest, put } from 'redux-saga/effects';
import { managerStateSelector } from 'reducers/manager/reducer';
import {
  SIGN_IN,
  LOAD_MY_INFO,
  loadMyInfoSuccess,
  loadMyInfoFailed,
  loadMyInfo,
  UPDATE_MY_INFO,
  UPDATE_PASSWORD,
  changeOldPassword,
  changeNewPassword,
  changeConfirmNewPassword,
  ARM_SIGN_IN,
  changePassword,
  LOGOUT,
  REGISTER_MANAGER,
  LOAD_COMPANY_MANAGER_LIST,
  loadCompanyManagerListSuccess,
  loadCompanyManagerList,
  DELETE_MANAGER_INFO,
  UPDATE_MANAGER_IS_ACTIVE,
  UPDATE_MANAGER_INFO,
} from 'reducers/manager/action';
import {
  requestARMSignIn,
  requestDeleteManager,
  requestGetManagerInfo,
  requestPostManager,
  requestPutManagerInfo,
  requestPutManagerPassword,
  requestSignIn,
} from 'api/manager';
import { isRequestSuccess } from 'commons/http-request-helper';

import {
  getTokenPayloadValue,
  getTokenRole,
  isValidToken,
} from 'commons/token-helper';
import { companyStateSelector } from 'reducers/company/reducer';
import { requestGetCompanyManagers } from 'api/company';
import { differenceWith, isEqual } from 'lodash';
import { USER_ROLE } from 'constants/UserType';
import { toast } from 'react-toastify';

export function* postSignInRequest({ payload: navigate }) {
  const { loginId, password } = yield select(managerStateSelector);
  /**
   * 관리자 로그인
   */
  try {
    const response = yield call(requestSignIn, loginId, password);
    // status Code가 200 OK가 아닐 경우
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    // token 저장
    localStorage.setItem('token', response.body.data.authToken);
  } catch (e) {
    toast.info('로그인에 실패하였습니다. 정보를 다시 확인해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  } finally {
    yield put(changePassword(''));
  }
  const userType = getTokenRole();
  if (userType === USER_ROLE.USER) {
    navigate('/main/modem-map');
    return;
  }
  navigate('/main/dashboard');
}

// eslint-disable-next-line consistent-return
export function* postARMSignInRequest(payload) {
  /**
   * 관리자 ARM 로그인
   */

  const { navigate } = payload.payload;
  const { code } = payload.payload;
  try {
    const response = yield call(requestARMSignIn, code);
    if (response.body.error) {
      throw new Error(response.body.error_reason);
    }
    localStorage.setItem('token', response.body.access_token);
    localStorage.setItem('refreshToken', response.body.refresh_token);
  } catch (e) {
    toast.error('통합 로그인에 실패하였습니다. 정보를 다시 확인해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return navigate('/sign-in');
  }
  if (localStorage.getItem('previousURL') === null) {
    localStorage.setItem('previousURL', '/main/dashboard');
  }
  navigate(localStorage.getItem('previousURL'));
}

/**
 * 관리자 ARM 로그아웃
 */
// eslint-disable-next-line consistent-return
export function* postLogoutRequest({ payload: navigate }) {
  const managerType = getTokenPayloadValue('managerType');
  if (managerType === '') {
    return;
  }
  navigate('/sign-in');
}

/**
 * 내 정보 조회
 */
export function* getMyInfo() {
  if (!isValidToken()) {
    return;
  }

  try {
    const response = yield call(requestGetManagerInfo);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadMyInfoSuccess(response.body.data));
  } catch (e) {
    yield put(loadMyInfoFailed());
    toast.error('내 정보 조회 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 로그인 유저 정보 수정
 */
export function* putMyInfo() {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  const { myInfo, newMyInfo } = yield select(managerStateSelector);

  if (
    myInfo.name === newMyInfo.name &&
    myInfo.cellphone === newMyInfo.cellphone &&
    myInfo.email === newMyInfo.email
  ) {
    toast.info('기존 정보와 같습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  if (newMyInfo.name === '') {
    toast.info('성명은 비어있을 수 없습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (newMyInfo.cellphone === '') {
    toast.info('전화번호는 비어있을 수 없습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (newMyInfo.email === '') {
    toast.info('이메일은 비어있을 수 없습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (!/(^0[0-9]{1,2})([0-9]{3,4})([0-9]{4})/.test(newMyInfo.cellphone)) {
    toast.warning('올바른 전화번호가 아닙니다. 재입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  const updateMyInfo = [
    {
      managerUid: newMyInfo.managerUid,
      name: newMyInfo.name,
      cellphone: newMyInfo.cellphone,
      email: newMyInfo.email,
    },
  ];

  try {
    const response = yield call(
      requestPutManagerInfo,
      selectedCompany.uid,
      updateMyInfo,
    );

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    toast.success('수정되었습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(loadMyInfo());
  } catch (e) {
    toast.error('내 정보 수정을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 관리자 정보 수정 (SA 권한)
 */
export function* putManagerInfo() {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  const { newManagerInfo } = yield select(managerStateSelector);

  if (
    newManagerInfo.selectedManager.name === newManagerInfo.name &&
    newManagerInfo.selectedManager.cellphone === newManagerInfo.cellphone &&
    newManagerInfo.selectedManager.email === newManagerInfo.email
  ) {
    toast.info('기존 정보와 같습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  if (newManagerInfo.name === '') {
    toast.info('성명은 비어있을 수 없습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (newManagerInfo.cellphone === '') {
    toast.info('전화번호는 비어있을 수 없습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (newManagerInfo.email === '') {
    toast.info('이메일은 비어있을 수 없습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (!/(^0[0-9]{1,2})([0-9]{3,4})([0-9]{4})/.test(newManagerInfo.cellphone)) {
    toast.warning('올바른 전화번호가 아닙니다. 재입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  const updateManagerInfo = [
    {
      managerUid: newManagerInfo.selectedManager.managerUid,
      name: newManagerInfo.name,
      cellphone: newManagerInfo.cellphone,
      email: newManagerInfo.email,
    },
  ];

  try {
    const response = yield call(
      requestPutManagerInfo,
      selectedCompany.uid,
      updateManagerInfo,
    );

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadCompanyManagerList());
  } catch (e) {
    toast.error('관리자 정보 수정을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 관리자 활성화 상태 수정
 */
export function* updateManagerIsActive() {
  if (!isValidToken()) {
    return;
  }
  const { managerList, updateManagerList } = yield select(managerStateSelector);
  const { selectedCompany } = yield select(companyStateSelector);

  const updatedManagerList = differenceWith(
    updateManagerList,
    managerList,
    isEqual,
  ).map(item => ({
    managerUid: item.managerUid,
    isActive: item.isActive,
  }));

  try {
    const response = yield call(
      requestPutManagerInfo,
      selectedCompany.uid,
      updatedManagerList,
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadCompanyManagerList());
  } catch (e) {
    toast.error('관리자 활성화 상태 변경을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 관리자 비밀번호 수정
 */
export function* putChangePassword() {
  if (!isValidToken()) {
    return;
  }
  const { oldPassword, newPassword, confirmNewPassword } = yield select(
    managerStateSelector,
  );

  if (oldPassword === '') {
    toast.info('현재 비밀번호를 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (newPassword === '') {
    toast.info('새 비밀번호를 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (confirmNewPassword === '') {
    toast.info('새 비밀번호 확인을 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
  if (newPassword !== confirmNewPassword) {
    toast.info('새 비밀번호 확인에 입력된 비밀번호가 새 비밀번호가 다릅니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  try {
    const response = yield call(
      requestPutManagerPassword,
      oldPassword,
      newPassword,
    );

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    toast.success('비밀번호 변경되었습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } catch (e) {
    toast.error('관리자 비밀번호 변경을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changeOldPassword(''));
    yield put(changeNewPassword(''));
    yield put(changeConfirmNewPassword(''));
  }
}

export function* postRegisterManager() {
  if (!isValidToken()) {
    return;
  }

  const { registerManagerInfo } = yield select(managerStateSelector);
  const { selectedCompany } = yield select(companyStateSelector);

  const {
    name,
    cellphone,
    email,
    loginId,
    password,
    confirmPassword,
  } = registerManagerInfo;

  if (name === '') {
    toast.info('성명을 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (cellphone === '') {
    toast.info('연락처를 입력해주세요..', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (email === '') {
    toast.info('이메일을 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
  if (loginId === '') {
    toast.info('로그인 아이디를 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
  if (password === '') {
    toast.info('비밀번호를 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
  if (confirmPassword === '') {
    toast.info('비밀번호 확인을 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (password !== confirmPassword) {
    toast.info('비밀번호 확인에 입력된 비밀번호가 새 비밀번호가 다릅니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  const managerInfo = {
    name: registerManagerInfo.name,
    cellphone: registerManagerInfo.cellphone,
    email: registerManagerInfo.email,
    userType: 'GA',
    loginId: registerManagerInfo.loginId,
    password: registerManagerInfo.password,
    companyUid: selectedCompany.uid,
  };

  try {
    const response = yield call(requestPostManager, managerInfo);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    toast.success('관리자를 등록하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(loadCompanyManagerList());
  } catch (e) {
    toast.error('관리자 등록 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export function* getCompanyManagerList() {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  try {
    const response = yield call(requestGetCompanyManagers, selectedCompany.uid);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadCompanyManagerListSuccess(response.body.data));
  } catch (e) {
    toast.error('관리자 리스트 조회를 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export function* deleteManagerInfo({ payload: managerUid }) {
  if (!isValidToken()) {
    return;
  }
  try {
    const response = yield call(requestDeleteManager, managerUid);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadCompanyManagerList());
  } catch (e) {
    toast.error('관리자 삭제를 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export default function* managerSaga() {
  yield takeLatest(SIGN_IN, postSignInRequest);
  yield takeLatest(ARM_SIGN_IN, postARMSignInRequest);
  yield takeLatest(LOAD_MY_INFO, getMyInfo);
  yield takeLatest(UPDATE_MY_INFO, putMyInfo);
  yield takeLatest(UPDATE_MANAGER_INFO, putManagerInfo);
  yield takeLatest(UPDATE_PASSWORD, putChangePassword);
  yield takeLatest(LOGOUT, postLogoutRequest);
  yield takeLatest(REGISTER_MANAGER, postRegisterManager);
  yield takeLatest(LOAD_COMPANY_MANAGER_LIST, getCompanyManagerList);
  yield takeLatest(DELETE_MANAGER_INFO, deleteManagerInfo);
  yield takeLatest(UPDATE_MANAGER_IS_ACTIVE, updateManagerIsActive);
}
