import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import CommonModal from 'components/common/modal';
import PageTitle from 'components/common/page-title';
import { Container } from '@mui/material';
import ContainerSubTitle from 'components/common/sub-title';
import moment from 'moment';

export default function MarkerModal({
  isVisibleMarkerModal,
  onChangeMarkerModalStatus,
  selectedMarkerData,
}) {
  const onClickBackdrop = () => {
    onChangeMarkerModalStatus(false);
  };

  return (
    <CommonModal
      visible={isVisibleMarkerModal}
      onClose={onClickBackdrop}
      markerModal
    >
      <Container
        style={{
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <PageTitle>수집 정보</PageTitle>
        <ContainerSubTitle>수집 시각</ContainerSubTitle>
        <p style={{ padding: 0, margin: '0 5px' }}>
          {moment(selectedMarkerData.createdAt, 'yyyy-MM-DD[T]HH:mm:ss').format(
            'YY.MM.DD HH:mm:ss',
          )}
        </p>
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>수집 GPS 리스트</ContainerSubTitle>
        {Object.keys(selectedMarkerData).length !== 0 &&
          selectedMarkerData.gpsList?.map(gpsData => (
            <p
              key={gpsData.LA.toString() + gpsData.LO.toString()}
              style={{ padding: 0, margin: '0 5px' }}
            >
              위도: {gpsData.LA} 경도: {gpsData.LO}
            </p>
          ))}
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>수집 WiFi 리스트</ContainerSubTitle>
        {Object.keys(selectedMarkerData).length !== 0 &&
          selectedMarkerData.wifiList?.map(wifiData => (
            <p
              key={wifiData?.B + wifiData?.I.toString()}
              style={{ padding: 0, margin: '0 5px' }}
            >
              Mac: {wifiData?.B} 이름: {wifiData?.R} 신호 세기: {wifiData?.I}
            </p>
          ))}
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>수집된 기지국 정보</ContainerSubTitle>
        {Object.keys(selectedMarkerData).length !== 0 &&
          selectedMarkerData.cellLocation?.cells?.map(cell => (
            <p key={uuidv4()} style={{ padding: 0, margin: '0 5px' }}>
              MCC: {cell.mcc} MNC: {cell.mnc} TAC: {cell.tac} ID: {cell.id}{' '}
              주소: {selectedMarkerData.cellLocation.address}
            </p>
          ))}
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>수집된 시스템 정보</ContainerSubTitle>
        {Object.keys(selectedMarkerData).length !== 0 &&
          selectedMarkerData.system && (
            <p key={uuidv4()} style={{ padding: 0, margin: '0 5px' }}>
              외부 전원 전압: {selectedMarkerData.system.externalPower} 디바이스
              온도: {selectedMarkerData.system.temperature} 배터리{' '}
              {selectedMarkerData.system.batteryPower}
            </p>
          )}
        <div style={{ height: '10px' }} />
      </Container>
    </CommonModal>
  );
}

MarkerModal.propTypes = {
  isVisibleMarkerModal: PropTypes.bool,
  onChangeMarkerModalStatus: PropTypes.func,
  selectedMarkerData: PropTypes.object,
};
