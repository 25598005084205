/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_GPS_DATA,
  CHANGE_SELECTED_END_DATE,
  CHANGE_SELECTED_MODEM_IMEI,
  CHANGE_SELECTED_START_DATE,
  CHANGE_SIZE,
  CHANGE_CELL_TOWER_DATA,
  CHANGE_LATEST_GPS_DATA,
  CLEAR_MODEM_MAP_DATA,
  CHANGE_LOGS_DATA_TOTAL_COUNT,
  CHANGE_MAP_SIZE,
  CHANGE_DATA_TYPE,
  CHANGE_TXID,
  CHANGE_REMOTE_IP,
  CHANGE_HOST_NAME,
  CHANGE_PAGE,
  CHANGE_SELECTED_MAP_MODEM_IMEI,
  CHANGE_SELECTED_MAP_START_DATE,
  CHANGE_SELECTED_MAP_END_DATE,
  CHANGE_ROW_MODEM_IMEI,
  CHANGE_ROW_MODEM_TXID,
  CHANGE_MAP_LOADING_INDICATOR_STATUS,
  CHANGE_LOGS_DATA_LOADING_INDICATOR_STATUS,
  CHANGE_MARKER_MODAL_STATUS,
  CHANGE_SELECTED_MARKER_DATA,
  CHANGE_MODEM_MAP_LOG_DATA,
  CHANGE_TABLE_LOG_DATA,
} from 'reducers/modem-map/action';
import dayjs from 'dayjs';

const defaultDate = {
  startDate: dayjs(new Date().setDate(new Date().getDate() - 7)).format(
    'YYYY-MM-DD HH:mm:ss',
  ),
  endDate: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
};

const initialState = {
  cellTowerDataList: [],
  gpsDataList: [],
  logsGroup: [],
  rowImei: '',
  rowTxid: '',
  totalCount: 0,
  latestGPSData: {},
  mapSearchFilter: {
    imei: '',
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
    size: 800,
  },
  modemMapLogData: {
    totalCount: 0,
    last: false,
    logGroups: [],
  },
  searchFilter: {
    imei: '',
    txid: '',
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
    dataType: '',
    remoteIp: '',
    hostName: '',
    page: 0,
    size: 20,
  },
  tableLogData: {
    totalCount: 0,
    last: true,
    logGroups: [],
  },
  mapLoading: false,
  logDataLoading: false,
  isVisibleMarkerModal: false,
  selectedMarkerData: {},
};

export const modemMapStateSelector = state => state.modemMapReducer;

export default createReducer(initialState, {
  [CHANGE_SELECTED_MAP_MODEM_IMEI]: (state, { payload: selectedImei }) => {
    state.mapSearchFilter.imei = selectedImei;
  },
  [CHANGE_SELECTED_MAP_START_DATE]: (state, { payload: startDate }) => {
    state.mapSearchFilter.startDate = startDate;
  },
  [CHANGE_SELECTED_MAP_END_DATE]: (state, { payload: endDate }) => {
    state.mapSearchFilter.endDate = endDate;
  },
  [CHANGE_MAP_SIZE]: (state, { payload: size }) => {
    state.mapSearchFilter.size = size;
  },
  [CHANGE_SELECTED_MODEM_IMEI]: (state, { payload: selectedImei }) => {
    state.searchFilter.imei = selectedImei;
  },
  [CHANGE_SELECTED_START_DATE]: (state, { payload: startDate }) => {
    state.searchFilter.startDate = startDate;
  },
  [CHANGE_SELECTED_END_DATE]: (state, { payload: endDate }) => {
    state.searchFilter.endDate = endDate;
  },
  [CHANGE_DATA_TYPE]: (state, { payload: dataType }) => {
    state.searchFilter.dataType = dataType;
  },
  [CHANGE_TXID]: (state, { payload: txid }) => {
    state.searchFilter.txid = txid;
  },
  [CHANGE_REMOTE_IP]: (state, { payload: remoteIp }) => {
    state.searchFilter.remoteIp = remoteIp;
  },
  [CHANGE_HOST_NAME]: (state, { payload: hostName }) => {
    state.searchFilter.hostName = hostName;
  },
  [CHANGE_PAGE]: (state, { payload: page }) => {
    state.searchFilter.page = page;
  },
  [CHANGE_SIZE]: (state, { payload: size }) => {
    state.searchFilter.size = size;
  },
  [CHANGE_GPS_DATA]: (state, { payload: gpsDataList }) => {
    state.gpsDataList = gpsDataList;
  },
  [CHANGE_CELL_TOWER_DATA]: (state, { payload: cellTowerDataList }) => {
    state.cellTowerDataList = cellTowerDataList;
  },
  [CHANGE_LATEST_GPS_DATA]: (state, { payload: gpsData }) => {
    state.latestGPSData = gpsData;
  },
  [CLEAR_MODEM_MAP_DATA]: state => {
    state.modemMapLogData = initialState.modemMapLogData;
    state.latestGPSData = initialState.latestGPSData;
  },
  [CHANGE_TABLE_LOG_DATA]: (state, { payload: tableLogData }) => {
    state.tableLogData = tableLogData;
  },
  [CHANGE_LOGS_DATA_TOTAL_COUNT]: (state, { payload: totalCount }) => {
    state.totalCount = totalCount;
  },
  [CHANGE_ROW_MODEM_IMEI]: (state, { payload: rowImei }) => {
    state.rowImei = rowImei;
  },
  [CHANGE_ROW_MODEM_TXID]: (state, { payload: rowTxid }) => {
    state.rowTxid = rowTxid;
  },
  [CHANGE_MAP_LOADING_INDICATOR_STATUS]: (state, { payload: loading }) => {
    state.mapLoading = loading;
  },
  [CHANGE_LOGS_DATA_LOADING_INDICATOR_STATUS]: (
    state,
    { payload: loading },
  ) => {
    state.logDataLoading = loading;
  },
  [CHANGE_MARKER_MODAL_STATUS]: (state, { payload: modalStatus }) => {
    state.isVisibleMarkerModal = modalStatus;
  },
  [CHANGE_SELECTED_MARKER_DATA]: (state, { payload: selectedMarkerData }) => {
    state.selectedMarkerData = selectedMarkerData;
  },
  [CHANGE_MODEM_MAP_LOG_DATA]: (state, { payload: modemMapLogData }) => {
    state.modemMapLogData = modemMapLogData;
  },
});
