import { USER_ROLE } from 'constants/UserType';

export default function getDrawerRoute(role) {
  /**
   * 슈퍼 어드민 (엘핀 관리자) 카테고리
   */
  if (role === USER_ROLE.SUPER_ADMIN) {
    return [
      {
        type: 'title',
        name: '',
      },
      {
        type: 'item',
        name: '대시보드',
        path: '/main/dashboard',
      },
      {
        type: 'title',
        name: '회사',
      },
      {
        type: 'item',
        name: '회사 관리',
        path: '/main/company',
      },
      {
        type: 'title',
        name: '계정',
      },
      {
        type: 'item',
        name: '관리자 계정 관리',
        path: '/main/manager',
      },
      {
        type: 'item',
        name: '사용자 계정 관리',
        path: '/main/user',
      },
      {
        type: 'title',
        name: '암호화 방식',
      },
      {
        type: 'item',
        name: '암호화 방식 관리',
        path: '/main/encrypt-key',
      },
      {
        type: 'title',
        name: '단말기',
      },
      {
        type: 'item',
        name: '단말기 모델 관리',
        path: '/main/modem-model',
      },
      {
        type: 'item',
        name: '등록 단말기 관리',
        path: '/main/modem',
      },
      {
        type: 'item',
        name: '미등록 단말기 관리',
        path: '/main/no-register-modem',
      },
      {
        type: 'item',
        name: '단말기 지도',
        path: '/main/modem-map',
      },
      {
        type: 'item',
        name: '단말기 지도 V2 (MQTT)',
        path: '/main/modem-map-v2',
      },
    ];
  }
  /**
   * 일반 어드민 카테고리
   */
  if (role === USER_ROLE.GENERAL_ADMIN) {
    return [
      {
        type: 'title',
        name: '',
      },
      {
        type: 'item',
        name: '대시보드',
        path: '/main/dashboard',
      },
      {
        type: 'title',
        name: '계정',
        path: '',
      },
      {
        type: 'item',
        name: '사용자 계정 관리',
        path: '/main/user',
      },
      {
        type: 'title',
        name: '암호화 방식',
      },
      {
        type: 'item',
        name: '암호화 방식 관리',
        path: '/main/encrypt-key',
      },
      {
        type: 'title',
        name: '단말기',
      },
      {
        type: 'item',
        name: '단말기 모델 관리',
        path: '/main/modem-model',
      },
      {
        type: 'item',
        name: '등록 단말기 관리',
        path: '/main/modem',
      },
      {
        type: 'item',
        name: '단말기 지도',
        path: '/main/modem-map',
      },
      {
        type: 'item',
        name: '단말기 지도 V2 (MQTT)',
        path: '/main/modem-map-v2',
      },
    ];
  }
  /**
   * 단말기 사용자 카테고리
   */
  if (role === USER_ROLE.USER) {
    return [
      {
        type: 'title',
        name: '단말기',
      },
      {
        type: 'item',
        name: '단말기 지도',
        path: '/main/modem-map',
      },
      {
        type: 'item',
        name: '단말기 지도 V2 (MQTT)',
        path: '/main/modem-map-v2',
      },
    ];
  }
  return [];
}
