import React, { useEffect } from 'react';
import CenterLayout from 'components/common/center-layout';
import SignInCard from 'components/sign-in/sign-in-card';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  requestSignIn,
  requestARMSignIn,
  changeLoginId,
  changePassword,
} from 'reducers/manager/action';
import { managerStateSelector } from 'reducers/manager/reducer';
import { clearAllState } from 'reducers/global/action';
import { toast } from 'react-toastify';

function SignIn() {
  const dispatch = useDispatch();
  // react-router v6 이후, useHistory -> useNavigate
  const navigate = useNavigate();
  const location = useLocation();
  const getCode = () => new URLSearchParams(location.search).get('code');

  const { loginId, password } = useSelector(managerStateSelector);

  function onChangeLoginId(e) {
    dispatch(changeLoginId(e.target.value));
  }

  function onChangePassword(e) {
    dispatch(changePassword(e.target.value));
  }

  useEffect(() => {
    dispatch(clearAllState());
    const payload = {
      code: getCode(),
      navigate,
    };
    if (payload.code === null) {
      return;
    }
    dispatch(requestARMSignIn(payload));
  }, []);

  function onSignIn(e) {
    e.preventDefault();
    if (loginId === '' || password === '') {
      toast.info('아이디, 패스워드를 입력해주세요.', {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    dispatch(requestSignIn(navigate));
  }

  return (
    <CenterLayout loginPage>
      <SignInCard
        loginId={loginId}
        password={password}
        onChangeLoginId={onChangeLoginId}
        onChangePassword={onChangePassword}
        onSignIn={onSignIn}
      />
    </CenterLayout>
  );
}

export default SignIn;
