import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentType,
  DataContainer,
  DataText,
  SubDataContainer,
} from 'components/common/data-style';

export default function GPSData({ content }) {
  const { latitude, longitude } = content;

  return (
    <DataContainer>
      <SubDataContainer>
        <ContentType>위도</ContentType>
        <DataText>{latitude}</DataText>
        <ContentType>경도</ContentType>
        <DataText>{longitude}</DataText>
      </SubDataContainer>
    </DataContainer>
  );
}

GPSData.propTypes = {
  content: PropTypes.object,
};
