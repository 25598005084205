import { createAction } from '@reduxjs/toolkit';

/**
 * 암호화 방식 리스트 조회 Action
 */
export const LOAD_ENCRYPT_KEY_LIST = 'ENCRYPT_KEY/LOAD_ENCRYPT_KEY_LIST';

export const LOAD_ENCRYPT_KEY_LIST_SUCCESS =
  'ENCRYPT_KEY/LOAD_ENCRYPT_KEY_LIST_SUCCESS';

export const LOAD_ENCRYPT_KEY_LIST_FAILED =
  'ENCRYPT_KEY/LOAD_ENCRYPT_KEY_LIST_FAILED';

export const loadEncryptKeyList = createAction(LOAD_ENCRYPT_KEY_LIST);
export const loadEncryptKeyListSuccess = createAction(
  LOAD_ENCRYPT_KEY_LIST_SUCCESS,
);
export const loadEncryptKeyListFailed = createAction(
  LOAD_ENCRYPT_KEY_LIST_FAILED,
);

/**
 * 암호화 방식 등록 Action
 */
export const CHANGE_ENCRYPT_KEY = 'ENCRYPT_KEY/CHANGE_ENCRYPT_KEY';

export const CHANGE_ENCRYPT_KEY_VERSION =
  'ENCRYPT_KEY/CHANGE_ENCRYPT_KEY_VERSION';

export const REGISTER_ENCRYPT_KEY = 'ENCRYPT_KEY/REGISTER_ENCRYPT_KEY';

export const REGISTER_ENCRYPT_KEY_SUCCESS =
  'ENCRYPT_KEY/REGISTER_ENCRYPT_KEY_SUCCESS';

export const changeEncryptKey = createAction(CHANGE_ENCRYPT_KEY);
export const changeEncryptKeyVersion = createAction(CHANGE_ENCRYPT_KEY_VERSION);
export const registerEncryptKey = createAction(REGISTER_ENCRYPT_KEY);
export const registerEncryptKeySuccess = createAction(
  REGISTER_ENCRYPT_KEY_SUCCESS,
);

/**
 * 암호화 방식 삭제 Action
 */
export const DELETE_ENCRYPT_KEY = 'ENCRYPT_KEY/DELETE_ENCRYPT_KEY';

export const deleteEncryptKey = createAction(DELETE_ENCRYPT_KEY);

/**
 * 암호화 방식 정보 수정 Action
 */
export const MODIFY_ENCRYPT_KEY_LIST = 'ENCRYPT_KEY/MODIFY_ENCRYPT_KEY_LIST';
export const CHANGE_MODIFY_ENCRYPT_KEY_LIST =
  'ENCRYPT_KEY/CHANGE_MODIFY_ENCRYPT_KEY_LIST';

export const modifyEncryptKeyList = createAction(MODIFY_ENCRYPT_KEY_LIST);
export const changeModifyEncryptKeyList = createAction(
  CHANGE_MODIFY_ENCRYPT_KEY_LIST,
);

/**
 * 암호화 방식 정보 수정 Modal Action
 */
export const CHANGE_MODIFY_ENCRYPT_KEY_MODAL_STATUS =
  'ENCRYPT_KEY/CHANGE_MODIFY_ENCRYPT_KEY_MODAL_STATUS';
export const changeModifyEncryptKeyModalStatus = createAction(
  CHANGE_MODIFY_ENCRYPT_KEY_MODAL_STATUS,
);

/**
 * 암호화 방식 검색 조건 Action
 */
export const CHANGE_SEARCH_ENCRYPT_KEY =
  'ENCRYPT_KEY/CHANGE_SEARCH_ENCRYPT_KEY';
export const CHANGE_SEARCH_ENCRYPT_KEY_VERSION =
  'ENCRYPT_KEY/CHANGE_SEARCH_ENCRYPT_KEY_VERSION';
export const CHANGE_SEARCH_ENCRYPT_KEY_IS_ACTIVE =
  'ENCRYPT_KEY/CHANGE_SEARCH_ENCRYPT_KEY_IS_ACTIVE';

export const changeSearchEncryptKey = createAction(CHANGE_SEARCH_ENCRYPT_KEY);
export const changeSearchEncryptKeyVersion = createAction(
  CHANGE_SEARCH_ENCRYPT_KEY_VERSION,
);

export const changeSearchEncryptKeyIsActive = createAction(
  CHANGE_SEARCH_ENCRYPT_KEY_IS_ACTIVE,
);

/**
 * 암호화 방식 수정 Action
 */
export const MODIFY_ENCRYPT_KEY_INFO = 'ENCRYPT_KEY/MODIFY_ENCRYPT_KEY';
export const CHANGE_MODIFY_ENCRYPT_KEY =
  'ENCRYPT_KEY/CHANGE_MODIFY_ENCRYPT_KEY';
export const CHANGE_MODIFY_ENCRYPT_KEY_VERSION =
  'ENCRYPT_KEY/CHANGE_MODIFY_ENCRYPT_KEY_VERSION';
export const CHANGE_SELECTED_MODIFY_ENCRYPT_KEY =
  'ENCRYPT_KEY/CHANGE_SELECTED_MODIFY_ENCRYPT_KEY';

export const modifyEncryptKeyInfo = createAction(MODIFY_ENCRYPT_KEY_INFO);
export const changeModifyEncryptKey = createAction(CHANGE_MODIFY_ENCRYPT_KEY);
export const changeModifyEncryptKeyVersion = createAction(
  CHANGE_MODIFY_ENCRYPT_KEY_VERSION,
);
export const changeSelectedModifyEncryptKey = createAction(
  CHANGE_SELECTED_MODIFY_ENCRYPT_KEY,
);
