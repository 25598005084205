import axios from 'axios';

export function getApiEndpoint() {
  return `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_API_VERSION}`; // eslint-disable-line
}

export function isRequestSuccess(statusCode) {
  if (typeof statusCode !== 'number') {
    throw new Error('invalid parameter');
  }

  return Math.floor(statusCode / 100) < 4;
}

export default async function executeAxios(
  url,
  method,
  headers = {},
  data = {},
  params = {},
  baseUrl = null,
) {
  const endpoint = getApiEndpoint();

  return axios
    .request({
      baseURL: baseUrl === null ? endpoint : `${baseUrl}`,
      url,
      method,
      headers: {
        ...headers,
      },
      data,
      params,
      timeout: 1000 * 30,
      validateStatus: () => true,
    })
    .then(response => ({
      statusCode: response.status,
      body: response.data,
    }))
    .catch(error => {
      throw error;
    });
}
