import React, { useCallback, useEffect, useState, memo } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from 'components/modem-map-v2/marker';
import { changeMapMarkerModalStatus } from 'reducers/modem-map-v2/action';
import { useDispatch, useSelector } from 'react-redux';
import { modemMapV2StateSelector } from 'reducers/modem-map-v2/reducer';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import MarkerModal from 'components/modem-map-v2/marker/maker-modal';
import PropTypes from 'prop-types';

const googleMapDefaultProps = {
  // 광화문 좌표
  center: {
    lat: 37.5760222,
    lng: 126.9769,
  },
  zoom: 10,
};

const googleMapAPIKey = `${process.env.REACT_APP_GOOGLE_MAP_KEY}`;

function ModemV2Map({ modemMapData, onlyFirstGps }) {
  const dispatch = useDispatch();

  const { isVisibleMapMarkerModal } = useSelector(modemMapV2StateSelector);

  const [latestGPSData, setLatestGPSData] = useState({});
  const [newModemMapLogData, setNewModemMapLogData] = useState([]);
  const [logGroupsData, setLogGroupsData] = useState([]);
  const [centerLocation, setCenterLocation] = useState(googleMapDefaultProps);
  const [latestLogGroupData, setLatestLogGroupData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState({});

  useEffect(() => {
    // 만약에 latestGPSData가 {}이 아니라면 centerLocation 변경
    if (
      latestGPSData.constructor === Object &&
      Object.keys(latestGPSData).length !== 0
    ) {
      setCenterLocation({
        center: {
          lat: latestGPSData.latitude,
          lng: latestGPSData.longitude,
        },
        zoom: 10,
      });
    }
  }, [latestGPSData]);

  useEffect(() => {
    /**
     * 대표 GPS가 유효하지 않은 데이터 제외
     */
    // eslint-disable-next-line array-callback-return
    const logDataList = modemMapData.logGroups.filter(
      logGroup => logGroup.posType !== 'I',
    );

    setLatestGPSData({});
    setLatestLogGroupData([]);
    setNewModemMapLogData(logDataList);
  }, [modemMapData]);

  useEffect(() => {
    if (newModemMapLogData.length > 0) {
      const latestLogGroupDataList = newModemMapLogData
        .slice(0, 1)
        .map(logData => {
          const returnList = [];
          const firstGpsData = logData.firstGps;

          if (!isEqual(latestGPSData, firstGpsData) && modemMapData.last) {
            setLatestGPSData(firstGpsData);
          }

          let style = {};

          if (returnList.length === 0) {
            style = {
              backgroundColor: '#fcc419',
              width: '18px',
              height: '18px',
              zIndex: 9998,
            };

            returnList.push({
              uid: uuidv4(),
              logData,
              style,
              type: 'Cell',
              latitude: logData.cellLocation?.lat,
              longitude: logData.cellLocation?.lon,
            });
          }

          // Wifi list가 존재하는 대표 GPS
          if (logData.wifiList?.length > 0) {
            style = {
              backgroundColor: '#33f08b',
              width: '18px',
              height: '18px',
              zIndex: 9998,
            };
          } else {
            style = {
              backgroundColor: '#9c41ff',
              width: '18px',
              height: '18px',
              zIndex: 9997,
            };
          }

          if (logData.posType === 'W') {
            style = {
              backgroundColor: '#ff00da',
              width: '18px',
              height: '18px',
              zIndex: 9997,
            };
          }

          returnList.push({
            uid: uuidv4(),
            logData,
            type: 'GPS',
            style,
            latitude: firstGpsData.latitude,
            longitude: firstGpsData.longitude,
          });

          if (logData.posType === 'G' || logData.posType === 'GR') {
            // eslint-disable-next-line array-callback-return
            logData.gpsList?.slice(1, logData.gpsList.length).map(log => {
              style = {
                width: '20px',
                height: '20px',
                backgroundColor: '#e03131',
                display: onlyFirstGps ? 'none' : '',
                zIndex: 9999,
              };

              returnList.push({
                uid: uuidv4(),
                logData,
                type: 'GPS',
                style,
                latitude: log.LA,
                longitude: log.LO,
              });
            });
          }
          return returnList;
        });
      setLatestLogGroupData(latestLogGroupDataList);
    }

    // 최근 GPS 데이터 제외 나머지 데이터
    const logGroupDataList = newModemMapLogData
      .slice(1, newModemMapLogData.length)
      .map(logData => {
        const firstGpsData = logData.firstGps;
        let style = {};

        const returnList = [];

        if (returnList.length === 0) {
          style = {
            backgroundColor: '#fcc419',
            width: '18px',
            height: '18px',
            zIndex: 9998,
          };

          returnList.push({
            uid: uuidv4(),
            logData,
            style,
            type: 'Cell',
            latitude: logData.cellLocation?.lat,
            longitude: logData.cellLocation?.lon,
          });
        }

        // Wifi list가 존재하는 대표 GPS
        if (logData.wifiList?.length > 0) {
          style = {
            backgroundColor: '#33f08b',
            width: '18px',
            height: '18px',
            zIndex: 9998,
          };
        } else {
          style = {
            backgroundColor: '#9c41ff',
            width: '18px',
            height: '18px',
            zIndex: 9997,
          };
        }

        if (logData.posType === 'W') {
          style = {
            backgroundColor: '#ff00da',
            width: '18px',
            height: '18px',
            zIndex: 9997,
          };
        }

        returnList.push({
          uid: uuidv4(),
          logData,
          type: 'GPS',
          style,
          latitude: firstGpsData.latitude,
          longitude: firstGpsData.longitude,
        });

        if (
          !onlyFirstGps &&
          (logData.posType === 'G' || logData.posType === 'GR')
        ) {
          // eslint-disable-next-line array-callback-return
          logData.gpsList?.slice(1, logData.gpsList.length).map(log => {
            style = {
              width: '15px',
              height: '15px',
              backgroundColor: '#339af0',
              zIndex: 200,
            };

            returnList.push({
              uid: uuidv4(),
              type: 'GPS',
              logData,
              style,
              latitude: log.LA,
              longitude: log.LO,
            });
          });
        }
        return returnList;
      });

    setLogGroupsData(logGroupDataList);
  }, [newModemMapLogData]);

  function onChangeMapMarkerModalStatus(isVisibleStatus) {
    dispatch(changeMapMarkerModalStatus(isVisibleStatus));
  }

  const renderLatestLogGroupData = useCallback(
    () =>
      latestLogGroupData.length > 0 &&
      latestLogGroupData.map(logGroup =>
        logGroup.map(logs => (
          <Marker
            key={logs.uid}
            type={logs.type}
            object={logs.logData}
            lat={logs.latitude}
            lng={logs.longitude}
            style={logs.style}
            onChangeMarkerModalStatus={onChangeMapMarkerModalStatus}
            onChangeSelectedMarkerData={setSelectedMarker}
          />
        )),
      ),
    [latestLogGroupData],
  );

  const renderLogGroupsData = useCallback(
    () =>
      logGroupsData.length > 0 &&
      logGroupsData.map(logGroup =>
        logGroup.map(logs => (
          <Marker
            key={logs.uid}
            type={logs.type}
            object={logs.logData}
            lat={logs.latitude}
            lng={logs.longitude}
            style={logs.style}
            onChangeMarkerModalStatus={onChangeMapMarkerModalStatus}
            onChangeSelectedMarkerData={setSelectedMarker}
          />
        )),
      ),
    [logGroupsData],
  );

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapAPIKey }}
        yesIWantToUseGoogleMapApiInternals
        center={centerLocation.center}
        zoom={centerLocation.zoom}
        onChange={e => {
          setCenterLocation(prevState => ({
            zoom: prevState.zoom,
            center: {
              lat: e.center.lat,
              lng: e.center.lng,
            },
          }));
        }}
      >
        {renderLatestLogGroupData()}
        {renderLogGroupsData()}
      </GoogleMapReact>
      {selectedMarker && (
        <MarkerModal
          isVisibleMarkerModal={isVisibleMapMarkerModal}
          onChangeMarkerModalStatus={onChangeMapMarkerModalStatus}
          selectedMarkerData={selectedMarker}
        />
      )}
    </>
  );
}

ModemV2Map.propTypes = {
  modemMapData: PropTypes.object,
  onlyFirstGps: PropTypes.bool,
};

function areEqualProps(prevProps, nextProps) {
  return isEqual(prevProps.modemMapData, nextProps.modemMapData);
}

export default memo(ModemV2Map, areEqualProps);
