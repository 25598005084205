import React from 'react';
import { Route } from 'react-router-dom';
import MainLayout from 'components/layout/main-layout';
import Dashboard from 'containers/dashboard';
import EncryptKey from 'containers/encrypt-key';
import Modem from 'containers/modem';
import NoRegisterModem from 'containers/no-register-modem';
import MyPage from 'containers/my-page';
import ModemMap from 'containers/modem-map';
import Company from 'containers/company';
import Manager from 'containers/manager';
import User from 'containers/user';
import ModemModel from 'containers/modem-model';
import ModemMapV2 from 'containers/modem-map-v2';

function MainRoute() {
  return (
    <Route path="main/*" element={<MainLayout />}>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="company" element={<Company />} />
      <Route path="manager" element={<Manager />} />
      <Route path="user" element={<User />} />
      <Route path="encrypt-key" element={<EncryptKey />} />
      <Route path="modem-model" element={<ModemModel />} />
      <Route path="modem" element={<Modem />} />
      <Route path="no-register-modem" element={<NoRegisterModem />} />
      <Route path="my-page" element={<MyPage />} />
      <Route path="modem-map" element={<ModemMap />} />
      <Route path="modem-map-v2" element={<ModemMapV2 />} />
    </Route>
  );
}

export default MainRoute;
