import { createAction } from '@reduxjs/toolkit';

/**
 * 회사 단말기 사용자 리스트 조회 Action
 */
export const LOAD_COMPANY_USER_LIST = 'USER/LOAD_COMPANY_USER_LIST';
export const LOAD_COMPANY_USER_LIST_SUCCESS =
  'USER/LOAD_COMPANY_USER_LIST_SUCCESS';

export const loadCompanyUserList = createAction(LOAD_COMPANY_USER_LIST);
export const loadCompanyUserListSuccess = createAction(
  LOAD_COMPANY_USER_LIST_SUCCESS,
);

/**
 * 사용자 정보 수정 Action
 */

export const CHANGE_UPDATE_USER_LIST = 'USER/CHANGE_UPDATE_USER_LIST';
export const UPDATE_USER_IS_ACTIVE = 'USER/UPDATE_USER_IS_ACTIVE';

export const changeUpdateUserList = createAction(CHANGE_UPDATE_USER_LIST);
export const updateUserIsActive = createAction(UPDATE_USER_IS_ACTIVE);

/**
 * 사용자 정보 삭제 Action
 */
export const DELETE_USER_INFO = 'USER/DELETE_USER_INFO';
export const deleteUserInfo = createAction(DELETE_USER_INFO);

/**
 * 사용자 비밀번호 변경
 */
export const UPDATE_USER_PASSWORD = 'USER/UPDATE_USER_PASSWORD';
export const updateUserPassword = createAction(UPDATE_USER_PASSWORD);
