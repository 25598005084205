import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentType,
  DataContainer,
  DataText,
  SubDataContainer,
} from 'components/common/data-style';

export default function MemsData({ content }) {
  const { accelero } = content;

  return (
    <DataContainer>
      <ContentType>가속도</ContentType>
      <SubDataContainer>
        <ContentType>X</ContentType>
        <DataText>{accelero[0]}</DataText>

        <ContentType>Y</ContentType>
        <DataText>{accelero[1]}</DataText>

        <ContentType>Z</ContentType>
        <DataText>{accelero[2]}</DataText>
      </SubDataContainer>
    </DataContainer>
  );
}

MemsData.propTypes = {
  content: PropTypes.object,
};
