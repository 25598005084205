import { isValidToken } from 'commons/token-helper';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { companyStateSelector } from 'reducers/company/reducer';
import { isRequestSuccess } from 'commons/http-request-helper';
import {
  changeModemModelList,
  changePreSignedURL,
  DELETE_MODEM_MODEL,
  LOAD_MODEM_MODEL,
  loadModemModel,
  REGISTER_MODEM_MODEL,
  REQUEST_PRE_SIGNED_URL,
  UPDATE_MODEM_MODEL_INFO,
} from 'reducers/modem-model/action';
import {
  requestDeleteModemModel,
  requestGetModemModel,
  requestPostModemModel,
  requestPostPreSignedURL,
  requestPutAttachments,
  requestPutModemModel,
} from 'api/modem-model';
import { modemModelStateSelector } from 'reducers/modem-model/reducer';
import { pickBy } from 'lodash';
import { toast } from 'react-toastify';

/**
 * 단말기 모델 등록
 */
export function* postModemModel() {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  const { registerModemModelInfo, preSignedURL } = yield select(
    modemModelStateSelector,
  );

  if (registerModemModelInfo.name === '') {
    toast.info('단말기 모델명을 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  if (registerModemModelInfo.producer === '') {
    toast.info('단말기 모델 제조사를 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  const requestBody = {
    ...pickBy(registerModemModelInfo, value => value !== '' && value !== null),
    attachmentsUrl: preSignedURL,
  };

  try {
    const response = yield call(
      requestPostModemModel,
      selectedCompany.uid,
      requestBody,
    );

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    } else if (response.body.code === 'E801') {
      // 이미 등록되어 있는 단말기 모델 에러 코드
      toast.warning(response.body.message, {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    yield put(loadModemModel());
  } catch (e) {
    toast.info('단말기 모델 등록을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changePreSignedURL(null));
  }
}

/**
 * PreSignedURL 요청 후 파일 업로드
 */
export function* postPreSignedURL() {
  if (!isValidToken()) {
    return;
  }
  const { registerAttachments } = yield select(modemModelStateSelector);
  const mimeType = registerAttachments.type;
  try {
    const response = yield call(requestPostPreSignedURL, mimeType);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }

    const preSignedURL = response.body.data.preSignedUrl;

    const uploadS3Response = yield call(
      requestPutAttachments,
      preSignedURL,
      mimeType,
      registerAttachments,
    );

    if (!isRequestSuccess(uploadS3Response.statusCode)) {
      throw new Error();
    }

    yield put(changePreSignedURL(preSignedURL));
  } catch (e) {
    toast.error('첨부파일 업로드 중 오류가 발생하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(changePreSignedURL(null));
  }
}

/**
 * 단말기 모델 리스트 조회
 */
export function* getModemModelList() {
  if (!isValidToken()) {
    return;
  }
  try {
    const { selectedCompany } = yield select(companyStateSelector);
    const { searchFilter } = yield select(modemModelStateSelector);

    if (!selectedCompany.uid) {
      return;
    }

    const filter = pickBy(searchFilter, value => value !== '');

    const response = yield call(
      requestGetModemModel,
      selectedCompany.uid,
      filter,
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(changeModemModelList(response.body.data.modemModelList));
  } catch (e) {
    toast.error('단말기 모델 리스트 조회에 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 단말기 모델 정보 수정
 */
export function* updateModemModelInfo() {
  if (!isValidToken()) {
    return;
  }
  const { updateModemModel, preSignedURL } = yield select(
    modemModelStateSelector,
  );
  const { selectedCompany } = yield select(companyStateSelector);

  const {
    selectedModemModel,
    name,
    producer,
    description,
    productionDate,
    discontinuedDate,
  } = updateModemModel;

  const body = {
    uid: selectedModemModel.uid,
    name,
    producer,
    description,
    attachmentsUrl: preSignedURL,
    productionDate,
    discontinuedDate,
  };

  try {
    const response = yield call(requestPutModemModel, selectedCompany.uid, [
      body,
    ]);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }

    yield put(loadModemModel());
  } catch (e) {
    toast.error('단말기 모델 정보 변경을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changePreSignedURL(null));
  }
}

/**
 * 단말기 모델 정보 삭제
 */
export function* deleteModemModel({ payload: modemModelUid }) {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  try {
    const response = yield call(requestDeleteModemModel, selectedCompany.uid, [
      modemModelUid,
    ]);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadModemModel());
  } catch (e) {
    toast.error('단말기 모델 삭제를 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}
export default function* modemModelSaga() {
  yield takeLatest(REGISTER_MODEM_MODEL, postModemModel);
  yield takeLatest(REQUEST_PRE_SIGNED_URL, postPreSignedURL);
  yield takeLatest(LOAD_MODEM_MODEL, getModemModelList);
  yield takeLatest(UPDATE_MODEM_MODEL_INFO, updateModemModelInfo);
  yield takeLatest(DELETE_MODEM_MODEL, deleteModemModel);
}
