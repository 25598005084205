import React, { useEffect } from 'react';
import { Button, Container } from '@mui/material';
import PageTitle from 'components/common/page-title';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeModemImei,
  changeModemLabel,
  changeModemModel,
  changeModemVersion,
  changeModifyModemInfoModalStatus,
  changeModifyModemStatus,
  changeSearchModemImei,
  changeSearchModemStatus,
  changeSearchModemVersion,
  deleteModem,
  loadModemList,
  modifyModemStatus,
  registerModem,
} from 'reducers/modem/action';
import ContainerSubTitle from 'components/common/sub-title';
import { modemStateSelector } from 'reducers/modem/reducer';
import RegisterModemInput from 'components/modem/register-modem-input';
import ModemTable from 'components/modem/modem-table';
import { companyStateSelector } from 'reducers/company/reducer';
import ModemSearchInput from 'components/modem/modem-search-input';
import { CommonCard } from 'components/common/card';
import { modemModelStateSelector } from 'reducers/modem-model/reducer';
import { loadModemModel } from 'reducers/modem-model/action';

function Modem() {
  const dispatch = useDispatch();

  const {
    modemList,
    registerModemInfo,
    searchFilter,
    isVisibleModifyModalInfoModal,
  } = useSelector(modemStateSelector);

  const { selectedCompany } = useSelector(companyStateSelector);
  const { modemModelList } = useSelector(modemModelStateSelector);

  function onLoadModemList() {
    dispatch(loadModemList());
  }

  function onRegisterModem() {
    dispatch(registerModem());
  }

  function onChangeModemImei(newModemImei) {
    dispatch(changeModemImei(newModemImei));
  }

  function onChangeModemLabel(newModemLabel) {
    dispatch(changeModemLabel(newModemLabel));
  }

  function onChangeModemVersion(newModemVersion) {
    dispatch(changeModemVersion(newModemVersion));
  }

  function onModifyModemStatus() {
    dispatch(modifyModemStatus());
  }

  function onChangeModifyModemStatus(modifyModemList) {
    dispatch(changeModifyModemStatus(modifyModemList));
  }

  function onChangeSearchModemImei(searchImei) {
    dispatch(changeSearchModemImei(searchImei));
  }

  function onChangeSearchModemVersion(searchVersion) {
    dispatch(changeSearchModemVersion(searchVersion));
  }

  function onChangeSearchModemStatus(searchModemStatus) {
    dispatch(changeSearchModemStatus(searchModemStatus));
  }

  function onDeleteModem(uid) {
    const message = `
     해당 단말기 정보를 삭제 하시겠습니까?
    `;

    const result = window.confirm(message);

    if (!result) {
      return;
    }
    dispatch(deleteModem(uid));
  }

  function onChangeModifyModemInfoModalStatus(isVisibleModalStatus) {
    dispatch(changeModifyModemInfoModalStatus(isVisibleModalStatus));
  }

  function onChangeModemModel(modemModel) {
    dispatch(changeModemModel(modemModel));
  }

  useEffect(() => {
    dispatch(loadModemModel());
    onLoadModemList();
  }, [selectedCompany]);

  return (
    <Container
      style={{
        margin: 0,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <PageTitle>등록 단말기 관리</PageTitle>
      <Container style={{ marginBottom: 20, padding: 20 }}>
        <CommonCard>
          <ContainerSubTitle>단말기 등록</ContainerSubTitle>
          <RegisterModemInput
            modemImei={registerModemInfo.imei}
            modemVersion={registerModemInfo.version}
            modemModel={registerModemInfo.modemModel}
            onChangeModemImei={onChangeModemImei}
            onChangeModemVersion={onChangeModemVersion}
            onRegisterModem={onRegisterModem}
            onChangeModemModel={onChangeModemModel}
            modemModelList={modemModelList}
            onChangeModemLabel={onChangeModemLabel}
            modemLabel={registerModemInfo.modemLabel}
          />
        </CommonCard>
      </Container>
      <Container>
        <ContainerSubTitle>등록 단말기 리스트</ContainerSubTitle>
        <div style={{ height: 20 }} />
        <CommonCard>
          <ContainerSubTitle>검색 조건</ContainerSubTitle>
          <ModemSearchInput
            searchModemImei={searchFilter.imei}
            searchModemStatus={searchFilter.modemStatus}
            searchModemVersion={searchFilter.version}
            onChangeSearchModemImei={onChangeSearchModemImei}
            onChangeSearchModemVersion={onChangeSearchModemVersion}
            onChangeSearchModemStatus={onChangeSearchModemStatus}
            onLoadModemList={onLoadModemList}
          />
        </CommonCard>
        <Button
          variant="outlined"
          style={{ float: 'right', marginBottom: '5px' }}
          onClick={onModifyModemStatus}
        >
          변경 적용
        </Button>
        <ModemTable
          data={modemList}
          onChangeModifyModemStatus={onChangeModifyModemStatus}
          onDeleteModem={onDeleteModem}
          onChangeModifyModemInfoModalStatus={
            onChangeModifyModemInfoModalStatus
          }
          isVisibleModifyModalInfoModal={isVisibleModifyModalInfoModal}
        />
      </Container>
    </Container>
  );
}

export default Modem;
