import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'components/common/modal';
import PageTitle from 'components/common/page-title';
import { Container } from '@mui/material';
import ContainerSubTitle from 'components/common/sub-title';

export default function RawLogModal({
  isVisibleSelectedRowRawLogModal,
  onChangeSelectedRowRawLogModalStatus,
  selectedRowRawData,
}) {
  const onClickBackdrop = () => {
    onChangeSelectedRowRawLogModalStatus(false);
  };

  return (
    <CommonModal
      visible={isVisibleSelectedRowRawLogModal}
      onClose={onClickBackdrop}
      markerModal
    >
      <Container
        style={{
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <PageTitle>상세 조회</PageTitle>
        <ContainerSubTitle>imei</ContainerSubTitle>
        <p style={{ padding: 0, margin: '0 5px' }}>{selectedRowRawData.imei}</p>
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>txid</ContainerSubTitle>
        <p style={{ padding: 0, margin: '0 5px' }}>{selectedRowRawData.txid}</p>
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>raw</ContainerSubTitle>
        <p style={{ padding: 0, margin: '0 5px' }}>{selectedRowRawData.raw}</p>
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>parseResult</ContainerSubTitle>
        <p style={{ padding: 0, margin: '0 5px' }}>
          {selectedRowRawData.parseResult}
        </p>
      </Container>
    </CommonModal>
  );
}

RawLogModal.propTypes = {
  isVisibleSelectedRowRawLogModal: PropTypes.bool,
  onChangeSelectedRowRawLogModalStatus: PropTypes.func,
  selectedRowRawData: PropTypes.object,
};
