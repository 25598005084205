import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'components/common/modal';
import PageTitle from 'components/common/page-title';
import { Button, Container, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { encryptKeyStateSelector } from 'reducers/encrypt-key/reducer';
import {
  changeModifyEncryptKey,
  changeModifyEncryptKeyVersion,
  modifyEncryptKeyInfo,
} from 'reducers/encrypt-key/action';

const CustomContainer = styled(Container)`
  padding: 10px;
`;

export default function ModifyEncryptKeyModal({
  isVisibleModifyEncryptKeyModal,
  onChangeModifyEncryptKeyModalStatus,
}) {
  const onClickBackdrop = () => {
    onChangeModifyEncryptKeyModalStatus(false);
  };

  const dispatch = useDispatch();

  function onChangeModifyEncryptKey(event) {
    dispatch(changeModifyEncryptKey(event.target.value));
  }

  function onChangeModifyEncryptKeyVersion(event) {
    dispatch(changeModifyEncryptKeyVersion(event.target.value));
  }

  function onModifyEncryptKeyInfo() {
    dispatch(modifyEncryptKeyInfo());
    onClickBackdrop();
  }

  const { modifyEncryptKey } = useSelector(encryptKeyStateSelector);

  return (
    <CommonModal
      visible={isVisibleModifyEncryptKeyModal}
      onClose={onClickBackdrop}
    >
      <Container
        style={{
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <PageTitle>암호화 방식 정보 수정</PageTitle>
        <CustomContainer>
          <FormControl variant="outlined">
            <InputLabel id="modify-encrypt-key">암호화 종류</InputLabel>
            <Select
              labelId="modify-encrypt-key"
              label="암호화 종류"
              style={{ marginRight: 15, width: 125, zIndex: 9999 }}
              value={modifyEncryptKey.encryptKey}
              onChange={onChangeModifyEncryptKey}
              defaultValue=""
            >
              <MenuItem value="L128" style={{ zIndex: 9999 }}>
                L128
              </MenuItem>
              <MenuItem value="A128" style={{ zIndex: 9999 }}>
                A128
              </MenuItem>
              <MenuItem value="PLAN" style={{ zIndex: 9999 }}>
                PLAN
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="암호화 버전"
            value={modifyEncryptKey.encryptKeyVersion}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={onChangeModifyEncryptKeyVersion}
          />
        </CustomContainer>
        <Container style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button variant="outlined" onClick={onModifyEncryptKeyInfo}>
            변경
          </Button>
        </Container>
      </Container>
    </CommonModal>
  );
}

ModifyEncryptKeyModal.propTypes = {
  isVisibleModifyEncryptKeyModal: PropTypes.bool,
  onChangeModifyEncryptKeyModalStatus: PropTypes.func,
};
