import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { PropTypes } from 'prop-types';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)({
  marginBottom: '10px',
  marginRight: '5px',
});

function LogDataSearchInput({
  modemList,
  onLoadTableLogData,
  onChangeSelectedModemImei,
  onChangeSelectedEndDate,
  onChangeSelectedStartDate,
  selectedModemImei,
  selectedEndDate,
  selectedStartDate,
  searchDataType,
  searchTxid,
  searchHostName,
  searchRemoteIp,
  onChangeTxid,
  onChangeRemoteIp,
  onChangeDataType,
  onChangeHostName,
}) {
  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <FormControl size="small" style={{ marginRight: 10, width: 200 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="시작일"
              value={selectedStartDate}
              inputFormat="yyyy-MM-dd HH:mm:ss"
              mask="____-__-__ __:__"
              onChange={event => onChangeSelectedStartDate(event)}
              renderInput={params => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl style={{ marginRight: 10, width: 200 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="종료일"
              value={selectedEndDate}
              inputFormat="yyyy-MM-dd HH:mm:ss"
              mask="____-__-__ __:__"
              onChange={event => onChangeSelectedEndDate(event)}
              renderInput={params => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
      <FormControl variant="outlined" size="small">
        <InputLabel>IMEI</InputLabel>
        <Select
          label="IMEI"
          displayEmpty
          value={selectedModemImei || ''}
          style={{ width: 200, marginRight: 10 }}
          onChange={event => onChangeSelectedModemImei(event.target.value)}
        >
          {modemList.map(modem => (
            <MenuItem key={modem.uid} value={modem.imei}>
              {modem.imei}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <StyledTextField
        id="outlined-textarea"
        label="Txid"
        placeholder="Txid"
        size="small"
        value={searchTxid}
        onChange={event => onChangeTxid(event.target.value)}
      />
      <StyledTextField
        id="outlined-textarea"
        label="데이터 종류"
        placeholder="데이터 종류"
        size="small"
        value={searchDataType}
        onChange={event => onChangeDataType(event.target.value)}
      />
      <StyledTextField
        id="outlined-textarea"
        label="Remote IP"
        placeholder="Remote IP"
        size="small"
        value={searchRemoteIp}
        onChange={event => onChangeRemoteIp(event.target.value)}
      />
      <StyledTextField
        id="outlined-textarea"
        label="Host Name"
        placeholder="Host Name"
        size="small"
        value={searchHostName}
        onChange={event => onChangeHostName(event.target.value)}
      />

      <FormControl style={{ textAlign: 'center' }}>
        <Button variant="outlined" onClick={onLoadTableLogData} size="small">
          조회
        </Button>
      </FormControl>
    </>
  );
}

LogDataSearchInput.propTypes = {
  modemList: PropTypes.array,
  onLoadTableLogData: PropTypes.func,
  onChangeSelectedModemImei: PropTypes.func,
  onChangeSelectedStartDate: PropTypes.func,
  onChangeSelectedEndDate: PropTypes.func,
  selectedModemImei: PropTypes.string,
  selectedStartDate: PropTypes.string,
  selectedEndDate: PropTypes.string,
  searchTxid: PropTypes.string,
  searchRemoteIp: PropTypes.string,
  searchDataType: PropTypes.string,
  searchHostName: PropTypes.string,
  onChangeTxid: PropTypes.func,
  onChangeRemoteIp: PropTypes.func,
  onChangeDataType: PropTypes.func,
  onChangeHostName: PropTypes.func,
};

export default LogDataSearchInput;
