import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 50px;
  height: 50px;
  position: relative;
  top: -200px;
  z-index: 9999;
  transform: translate(-50%, -50%);
  display: inline-block;
`;

export default Wrapper;
