import { isValidToken } from 'commons/token-helper';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { companyStateSelector } from 'reducers/company/reducer';
import { requestGetCompanyUsers } from 'api/company';
import { isRequestSuccess } from 'commons/http-request-helper';
import {
  DELETE_USER_INFO,
  LOAD_COMPANY_USER_LIST,
  loadCompanyUserList,
  loadCompanyUserListSuccess,
  UPDATE_USER_IS_ACTIVE,
  UPDATE_USER_PASSWORD,
} from 'reducers/user/action';
import { userStateSelector } from 'reducers/user/reducer';
import { differenceWith, isEqual } from 'lodash';
import {
  requestDeleteUserInfo,
  requestPutUserInfo,
  requestPutUserPassword,
} from 'api/users';
import { managerStateSelector } from 'reducers/manager/reducer';
import {
  changeConfirmNewPassword,
  changeNewPassword,
  changeOldPassword,
} from 'reducers/manager/action';
import { toast } from 'react-toastify';

export function* getCompanyUserList() {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  try {
    const response = yield call(requestGetCompanyUsers, selectedCompany.uid);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadCompanyUserListSuccess(response.body.data));
  } catch (e) {
    toast.error('사용자 리스트 조회를 실패하였습니다', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 사용자 활성 상태 변경
 */
export function* updateUserIsActive() {
  if (!isValidToken()) {
    return;
  }
  const { userList, updateUserList } = yield select(userStateSelector);

  const updatedUserList = differenceWith(updateUserList, userList, isEqual).map(
    item => ({
      userUid: item.userUid,
      isActive: item.isActive,
    }),
  );

  try {
    const response = yield call(requestPutUserInfo, updatedUserList);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadCompanyUserList());
  } catch (e) {
    toast(e);
  }
}

/**
 * 사용자 정보 삭제
 */
export function* deleteUserInfo({ payload: userUid }) {
  if (!isValidToken()) {
    return;
  }
  try {
    const response = yield call(requestDeleteUserInfo, userUid);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadCompanyUserList());
  } catch (e) {
    toast.error('사용자 삭제를 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 관리자 비밀번호 수정
 */
export function* putChangePassword() {
  if (!isValidToken()) {
    return;
  }
  const { oldPassword, newPassword, confirmNewPassword } = yield select(
    managerStateSelector,
  );

  if (oldPassword === '') {
    toast.info('현재 비밀번호를 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (newPassword === '') {
    toast.info('새 비밀번호를 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }
  if (confirmNewPassword === '') {
    toast.info('새 비밀번호 확인을 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
  if (newPassword !== confirmNewPassword) {
    toast.info('새 비밀번호 확인에 입력된 비밀번호가 새 비밀번호가 다릅니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  try {
    const response = yield call(
      requestPutUserPassword,
      oldPassword,
      newPassword,
    );

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    toast.success('비밀번호 변경되었습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } catch (e) {
    toast.error('관리자 비밀번호 변경을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changeOldPassword(''));
    yield put(changeNewPassword(''));
    yield put(changeConfirmNewPassword(''));
  }
}

export default function* userSaga() {
  yield takeLatest(LOAD_COMPANY_USER_LIST, getCompanyUserList);
  yield takeLatest(UPDATE_USER_IS_ACTIVE, updateUserIsActive);
  yield takeLatest(DELETE_USER_INFO, deleteUserInfo);
  yield takeLatest(UPDATE_USER_PASSWORD, putChangePassword);
}
