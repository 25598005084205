import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { isValidToken } from 'commons/token-helper';
import {
  requestGetV2LogsData,
  requestGetV2LogsTimeData,
  requestGetV2RawLogsData,
} from 'api/logs';
import { isRequestSuccess } from 'commons/http-request-helper';
import { toast } from 'react-toastify';
import {
  changeMapLoadingIndicatorStatus,
  changeModemMapV2LogsData,
  changeModemMapV2LogsTimeData,
  changeSelectedRowRawLog,
  changeSelectedRowRawLogModalStatus,
  LOAD_MODEM_MAP_V2_LOGS_DATA,
  LOAD_MODEM_MAP_V2_LOGS_TIME_DATA,
  LOAD_SELECTED_ROW_RAW_LOG,
} from 'reducers/modem-map-v2/action';
import { modemMapV2StateSelector } from 'reducers/modem-map-v2/reducer';
import { pickBy } from 'lodash';

/**
 * v2_logs 데이터 조회 (Map Data)
 * @param payload
 */
export function* getModemMapV2LogsData({ payload }) {
  const isValidUserToken = yield call(isValidToken);

  if (!isValidUserToken) {
    return;
  }
  const { queryString } = payload;
  try {
    const { modemMapData } = yield select(modemMapV2StateSelector);

    const filter = {
      ...pickBy(queryString, value => value !== '' && value !== []),
    };

    if (filter.hours) {
      filter.hours = String(filter.hours);
    }

    const response = yield call(requestGetV2LogsData, filter);

    const newData = [
      ...modemMapData.logGroups,
      ...response.body.data.logGroups.map(item => item),
    ];

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error);
    }

    yield put(
      changeModemMapV2LogsData({
        totalCount: modemMapData.totalCount + response.body.data.totalCount,
        last: response.body.data.last,
        logGroups: newData,
      }),
    );
  } catch (e) {
    toast.error('단말기 지도 데이터 조회 실패 (v2_logs)', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changeMapLoadingIndicatorStatus(false));
  }
}

/**
 * v2_logs 데이터 조회 (Map Data)
 * @param queryString
 */
export function* getModemMapV2LogsTimeData({ payload: queryString }) {
  const isValidUserToken = yield call(isValidToken);

  if (!isValidUserToken) {
    return;
  }

  try {
    const filter = {
      ...pickBy(queryString, value => value !== ''),
    };

    const response = yield call(requestGetV2LogsTimeData, filter);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error);
    }
    yield put(changeModemMapV2LogsTimeData(response.body.data));
  } catch (e) {
    toast.error('단말기 지도 데이터 조회 실패 (v2_logs Time Data)', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changeMapLoadingIndicatorStatus(false));
  }
}

/**
 * 선택된 Row Raw 데이터 조회
 * @param queryString
 */
export function* getSelectedRowRawData({ payload: queryString }) {
  const isValidUserToken = yield call(isValidToken);

  if (!isValidUserToken) {
    return;
  }

  try {
    const filter = {
      ...pickBy(queryString, value => value !== ''),
    };

    const response = yield call(requestGetV2RawLogsData, filter);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error);
    }

    if (response.body.data.list.length === 0) {
      toast.info('RAW 데이터가 존재하지 않습니다.', {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    yield put(changeSelectedRowRawLog(response.body.data.list[0]));
    yield put(changeSelectedRowRawLogModalStatus(true));
  } catch (e) {
    toast.error('raw 데이터 조회 실패', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    // eslint-disable-next-line no-empty
  } finally {
  }
}

export default function* modemMapV2Saga() {
  yield takeEvery(LOAD_MODEM_MAP_V2_LOGS_DATA, getModemMapV2LogsData);
  yield takeLatest(LOAD_MODEM_MAP_V2_LOGS_TIME_DATA, getModemMapV2LogsTimeData);
  yield takeLatest(LOAD_SELECTED_ROW_RAW_LOG, getSelectedRowRawData);
}
