import { createAction } from '@reduxjs/toolkit';

/**
 * modem checkBox action
 */
export const CHANGE_SELECTED_NO_REGISTER_MODEM_LIST =
  'NO_REGISTER_MODEM/CHANGE_SELECTED_NO_REGISTER_MODEM_LIST';
export const changeSelectedNoRegisterModemList = createAction(
  CHANGE_SELECTED_NO_REGISTER_MODEM_LIST,
);

/**
 * 미등록 단말기 등록상태로 변경 action
 */
export const MODIFY_MODEM_IS_REGISTER =
  'NO_REGISTER_MODEM/MODIFY_MODEM_IS_REGISTER';
export const modifyModemIsRegister = createAction(MODIFY_MODEM_IS_REGISTER);

/**
 * 미등록 단말기 영구 삭제 action
 */
export const DELETE_NO_REGISTER_MODEM_LIST =
  'NO_REGISTER_MODEM/DELETE_NO_REGISTER_MODEM_LIST';
export const deleteNoRegisterModemList = createAction(
  DELETE_NO_REGISTER_MODEM_LIST,
);

/**
 * 미등록 단말기 조회 action
 */
export const LOAD_NO_REGISTER_MODEM_LIST =
  'NO_REGISTER_MODEM/LOAD_NO_REGISTER_MODEM_LIST';
export const LOAD_NO_REGISTER_MODEM_LIST_SUCCESS =
  'NO_REGISTER_MODEM/LOAD_NO_REGISTER_MODEM_LIST_SUCCESS';
export const LOAD_NO_REGISTER_MODEM_LIST_FAILED =
  'NO_REGISTER_MODEM/LOAD_NO_REGISTER_MODEM_LIST_FAILED';

export const loadNoRegisterModemList = createAction(
  LOAD_NO_REGISTER_MODEM_LIST,
);
export const loadNoRegisterModemListSuccess = createAction(
  LOAD_NO_REGISTER_MODEM_LIST_SUCCESS,
);
export const loadNoRegisterModemListFailed = createAction(
  LOAD_NO_REGISTER_MODEM_LIST_FAILED,
);
