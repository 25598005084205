/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_COMPANY_ADDRESS,
  CHANGE_COMPANY_NAME,
  CHANGE_COMPANY_PHONE,
  CHANGE_COMPANY_REGISTER_NUM,
  CHANGE_MODIFY_COMPANY_ADDRESS,
  CHANGE_MODIFY_COMPANY_MODAL_STATUS,
  CHANGE_MODIFY_COMPANY_NAME,
  CHANGE_MODIFY_COMPANY_PHONE,
  CHANGE_MODIFY_COMPANY_REGISTER_NUM,
  CHANGE_SELECTED_COMPANY,
  CHANGE_SELECTED_MODIFY_COMPANY,
  LOAD_COMPANY_INFO_FAILED,
  LOAD_COMPANY_INFO_SUCCESS,
} from 'reducers/company/action';

const initialState = {
  companyList: [],
  selectedCompany: {},
  registerCompanyInfo: {
    companyName: '',
    companyAddress: '',
    companyPhone: '',
    companyRegisterNum: '',
  },
  isVisibleModifyCompanyModal: false,
  modifyCompanyInfo: {
    selectedCompany: {},
    companyName: '',
    companyAddress: '',
    companyPhone: '',
    companyRegisterNum: '',
  },
};

export const companyStateSelector = state => state.companyReducer;

export default createReducer(initialState, {
  [LOAD_COMPANY_INFO_SUCCESS]: (state, { payload }) => {
    const { companyList, selectedCompany } = payload;
    state.companyList = companyList;
    state.selectedCompany = selectedCompany;
  },
  [LOAD_COMPANY_INFO_FAILED]: state => {
    state.companyList = [];
  },
  [CHANGE_SELECTED_COMPANY]: (state, { payload: selectedCompany }) => {
    state.selectedCompany = selectedCompany;
  },
  [CHANGE_COMPANY_NAME]: (state, { payload: companyName }) => {
    state.registerCompanyInfo.companyName = companyName;
  },
  [CHANGE_COMPANY_ADDRESS]: (state, { payload: companyAddress }) => {
    state.registerCompanyInfo.companyAddress = companyAddress;
  },
  [CHANGE_COMPANY_PHONE]: (state, { payload: companyPhone }) => {
    state.registerCompanyInfo.companyPhone = companyPhone;
  },
  [CHANGE_COMPANY_REGISTER_NUM]: (state, { payload: companyRegisterNum }) => {
    state.registerCompanyInfo.companyRegisterNum = companyRegisterNum;
  },
  [CHANGE_MODIFY_COMPANY_NAME]: (state, { payload: companyName }) => {
    state.modifyCompanyInfo.companyName = companyName;
  },
  [CHANGE_MODIFY_COMPANY_PHONE]: (state, { payload: companyPhone }) => {
    state.modifyCompanyInfo.companyPhone = companyPhone;
  },
  [CHANGE_MODIFY_COMPANY_ADDRESS]: (state, { payload: companyAddress }) => {
    state.modifyCompanyInfo.companyAddress = companyAddress;
  },
  [CHANGE_MODIFY_COMPANY_REGISTER_NUM]: (
    state,
    { payload: companyRegisterNum },
  ) => {
    state.modifyCompanyInfo.companyRegisterNum = companyRegisterNum;
  },
  [CHANGE_MODIFY_COMPANY_MODAL_STATUS]: (
    state,
    { payload: isVisibleModifyCompanyModal },
  ) => {
    state.isVisibleModifyCompanyModal = isVisibleModifyCompanyModal;
  },
  [CHANGE_SELECTED_MODIFY_COMPANY]: (
    state,
    { payload: selectedModifyCompany },
  ) => {
    state.modifyCompanyInfo.selectedCompany = selectedModifyCompany;
    state.modifyCompanyInfo.companyName = selectedModifyCompany.companyName;
    state.modifyCompanyInfo.companyAddress =
      selectedModifyCompany.companyAddress;
    state.modifyCompanyInfo.companyPhone = selectedModifyCompany.companyPhone;
    state.modifyCompanyInfo.companyRegisterNum =
      selectedModifyCompany.companyRegisterNum;
  },
});
