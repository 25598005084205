import { createAction } from '@reduxjs/toolkit';

/**
 * 단말기 조회 action
 */
export const LOAD_MODEM_LIST = 'MODEM/LOAD_MODEM_LIST';

export const LOAD_MODEM_LIST_SUCCESS = 'MODEM/LOAD_MODEM_LIST_SUCCESS';

export const LOAD_MODEM_LIST_FAILED = 'MODEM/LOAD_MODEM_LIST_FAILED';

export const loadModemList = createAction(LOAD_MODEM_LIST);
export const loadModemListSuccess = createAction(LOAD_MODEM_LIST_SUCCESS);
export const loadModemListFailed = createAction(LOAD_MODEM_LIST_FAILED);

/**
 * 단말기 등록 action
 */
export const REGISTER_MODEM = 'MODEM/REGISTER_MODEM';
export const CHANGE_MODEM_IMEI = 'MODEM/CHANGE_MODEM_IMEI';
export const CHANGE_MODEM_VERSION = 'MODEM/CHANGE_MODEM_VERSION';
export const CHANGE_MODEM_MODEL = 'MODEM/CHANGE_MODEM_MODEL';
export const CHANGE_MODEM_LABEL = 'MODEM/CHANGE_MODEM_LABEL';

export const registerModem = createAction(REGISTER_MODEM);
export const changeModemImei = createAction(CHANGE_MODEM_IMEI);
export const changeModemVersion = createAction(CHANGE_MODEM_VERSION);
export const changeModemModel = createAction(CHANGE_MODEM_MODEL);
export const changeModemLabel = createAction(CHANGE_MODEM_LABEL);

/**
 * 단말기 상태 변경 action
 */
export const MODIFY_MODEM_STATUS = 'MODEM/MODIFY_MODEM_STATUS';
export const CHANGE_MODIFY_MODEM_STATUS = 'MODEM/CHANGE_MODIFY_MODEM_STATUS';

export const modifyModemStatus = createAction(MODIFY_MODEM_STATUS);
export const changeModifyModemStatus = createAction(CHANGE_MODIFY_MODEM_STATUS);

/**
 * 단말기 영구 삭제 action
 */
export const DELETE_MODEM = 'MODEM/DELETE_MODEM';

export const deleteModem = createAction(DELETE_MODEM);

/**
 * 단말기 리스트 검색 조건 action
 */
export const CHANGE_SEARCH_MODEM_IMEI = 'MODEM/CHANGE_SEARCH_MODEM_IMEI';
export const CHANGE_SEARCH_MODEM_VERSION = 'MODEM/CHANGE_SEARCH_MODEM_VERSION';
export const CHANGE_SEARCH_MODEM_STATUS = 'MODEM/CHANGE_SEARCH_MODEM_STATUS';

export const changeSearchModemImei = createAction(CHANGE_SEARCH_MODEM_IMEI);
export const changeSearchModemVersion = createAction(
  CHANGE_SEARCH_MODEM_VERSION,
);
export const changeSearchModemStatus = createAction(CHANGE_SEARCH_MODEM_STATUS);

/**
 * 단말기 정보 수정 Modal action
 */
export const CHANGE_MODIFY_MODEM_INFO_MODAL_STATUS =
  'MODEM/CHANGE_MODIFY_MODEM_INFO_MODAL_STATUS';

export const changeModifyModemInfoModalStatus = createAction(
  CHANGE_MODIFY_MODEM_INFO_MODAL_STATUS,
);

/**
 * 단말기 정보 수정 action
 */
export const MODIFY_MODEM_INFO = 'MODEM/MODIFY_MODEM_INFO';
export const CHANGE_MODIFY_MODEM_IMEI = 'MODEM/CHANGE_MODIFY_MODEM_IMEI';
export const CHANGE_MODIFY_MODEM_VERSION = 'MODEM/CHANGE_MODIFY_MODEM_VERSION';
export const CHANGE_MODIFY_MODEM_LABEL = 'MODEM/CHANGE_MODIFY_MODEM_LABEL';
export const CHANGE_SELECTED_MODIFY_MODEM =
  'MODEM/CHANGE_SELECTED_MODIFY_MODEM';
export const CHANGE_MODIFY_MODEM_MODEL = 'MODEM/CHANGE_MODIFY_MODEM_MODEL';

export const modifyModemInfo = createAction(MODIFY_MODEM_INFO);
export const changeModifyModemImei = createAction(CHANGE_MODIFY_MODEM_IMEI);
export const changeModifyModemVersion = createAction(
  CHANGE_MODIFY_MODEM_VERSION,
);
export const changeModifyModemLabel = createAction(CHANGE_MODIFY_MODEM_LABEL);
export const changeSelectedModifyModem = createAction(
  CHANGE_SELECTED_MODIFY_MODEM,
);
export const changeModifyModemModel = createAction(CHANGE_MODIFY_MODEM_MODEL);
