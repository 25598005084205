import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModemModelTableHeader from 'components/modem-model/modem-model-table-header';
import { changeSelectedUpdateModemModel } from 'reducers/modem-model/action';
import { useDispatch } from 'react-redux';
import UpdateModemModelInfoModal from 'components/modem-model/update-modem-model-modal';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    textAlign: 'center !important',
    minWidth: 100,
    maxWidth: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgb(221,243,255)',
    },
    cursor: 'pointer',
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: '100%',
    minWidth: 800,
  },
  tablePagination: {
    zIndex: 9999,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rgb(221,243,255)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function ModemModelTable({
  data,
  isVisibleUpdateModemModelInfoModal,
  onChangeUpdateModemModelInfoModalStatus,
  onDeleteModemModel,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [selectStatus, setSelectStatus] = useState(data);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onClickTableRow = row => {
    dispatch(changeSelectedUpdateModemModel(row));
    onChangeUpdateModemModelInfoModalStatus(true);
  };

  function onClickDownloadButton(url) {
    window.location.href = url;
  }

  useEffect(() => {
    setSelectStatus(data);
  }, [data]);

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <ModemModelTableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {selectStatus.length > 0 &&
              stableSort(selectStatus, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, key) => (
                  <StyledTableRow
                    key={row.uid}
                    onClick={() => onClickTableRow(row)}
                  >
                    <StyledTableCell>
                      {page * rowsPerPage + key + 1}
                    </StyledTableCell>
                    <StyledTableCell>{row.producer}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.description}</StyledTableCell>
                    <StyledTableCell>{row.productionDate}</StyledTableCell>
                    <StyledTableCell>{row.discontinuedDate}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        size="small"
                        style={{ marginLeft: 15 }}
                        onClick={e => {
                          e.stopPropagation();
                          if (row.attachmentsUrl != null) {
                            onClickDownloadButton(row.attachmentsUrl);
                          }
                        }}
                      >
                        {row.attachmentsUrl != null ? (
                          <FileDownloadIcon />
                        ) : (
                          <></>
                        )}
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.createdAt).format('YYYY.MM.DD HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        style={{ marginLeft: 15 }}
                        onClick={e => {
                          e.stopPropagation();
                          onDeleteModemModel(row.uid);
                        }}
                      >
                        삭제
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={selectStatus.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
        onPageChange={handleChangePage}
      />
      <UpdateModemModelInfoModal
        isVisibleModifyModemInfoModal={isVisibleUpdateModemModelInfoModal}
        onChangeModifyModemInfoModalStatus={
          onChangeUpdateModemModelInfoModalStatus
        }
      />
    </>
  );
}

ModemModelTable.propTypes = {
  data: PropTypes.array.isRequired,
  onChangeUpdateModemModelInfoModalStatus: PropTypes.func,
  isVisibleUpdateModemModelInfoModal: PropTypes.bool,
  onDeleteModemModel: PropTypes.func,
};

export default ModemModelTable;
