import React from 'react';
import styled from 'styled-components';
import { Button, Container, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';

const CustomContainer = styled(Container)`
  padding: 10px;
  display: flex;
`;

function RegisterCompanyInput({
  companyName,
  companyAddress,
  companyPhone,
  companyRegisterNum,
  onChangeCompanyName,
  onChangeCompanyAddress,
  onChangeCompanyPhone,
  onChangeCompanyRegisterNum,
  onRegisterCompany,
}) {
  return (
    <CustomContainer>
      <TextField
        label="회사명"
        value={companyName}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={event => onChangeCompanyName(event.target.value)}
      />
      <TextField
        label="회사 주소"
        value={companyAddress}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={event => onChangeCompanyAddress(event.target.value)}
      />
      <TextField
        label="대표 번호"
        value={companyPhone}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={event => onChangeCompanyPhone(event.target.value)}
      />
      <TextField
        label="사업자 등록 번호"
        value={companyRegisterNum}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={event => onChangeCompanyRegisterNum(event.target.value)}
      />
      <Button variant="outlined" onClick={onRegisterCompany}>
        등록
      </Button>
    </CustomContainer>
  );
}

RegisterCompanyInput.propTypes = {
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
  companyPhone: PropTypes.string,
  companyRegisterNum: PropTypes.string,
  onChangeCompanyName: PropTypes.func,
  onChangeCompanyAddress: PropTypes.func,
  onChangeCompanyPhone: PropTypes.func,
  onChangeCompanyRegisterNum: PropTypes.func,
  onRegisterCompany: PropTypes.func,
};

export default RegisterCompanyInput;
