import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modemModelStateSelector } from 'reducers/modem-model/reducer';
import {
  changeAttachments,
  changeModemModelDescription,
  changeModemModelDiscontinuedDate,
  changeModemModelName,
  changeModemModelProducer,
  changeModemModelProductionDate,
  changeSearchModemModelName,
  changeSearchModemModelProducer,
  changeUpdateModemModelModalStatus,
  deleteModemModel,
  loadModemModel,
  registerModemModel,
  requestPreSignedURL,
} from 'reducers/modem-model/action';
import { Container } from '@mui/material';
import PageTitle from 'components/common/page-title';
import { CommonCard } from 'components/common/card';
import ContainerSubTitle from 'components/common/sub-title';
import RegisterModemModelInput from 'components/modem-model/registerModemModelInput';
import dayjs from 'dayjs';
import ModemModelSearchInput from 'components/modem-model/modem-model-search-input';
import ModemModelTable from 'components/modem-model/modem-model-table';
import { companyStateSelector } from 'reducers/company/reducer';

function ModemModel() {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector(companyStateSelector);
  const {
    registerModemModelInfo,
    registerAttachments,
    searchFilter,
    modemModelList,
    isVisibleUpdateModemModelInfoModal,
  } = useSelector(modemModelStateSelector);

  function onRegisterModemModel() {
    dispatch(registerModemModel());
  }

  function onChangeModemModelName(name) {
    dispatch(changeModemModelName(name));
  }

  function onChangeModemModelProducer(producer) {
    dispatch(changeModemModelProducer(producer));
  }

  function onChangeModemModelDescription(description) {
    dispatch(changeModemModelDescription(description));
  }

  function onChangeModemModelProductionDate(productionDate) {
    dispatch(
      changeModemModelProductionDate(
        dayjs(productionDate).format('YYYY-MM-DD'),
      ),
    );
  }

  function onChangeModemModelDiscontinuedDate(discontinuedDate) {
    dispatch(
      changeModemModelDiscontinuedDate(
        dayjs(discontinuedDate).format('YYYY-MM-DD'),
      ),
    );
  }

  function onChangeRegisterAttachments(attachments) {
    dispatch(changeAttachments(attachments));
    dispatch(requestPreSignedURL());
  }

  function onChangeSearchModemModelName(name) {
    dispatch(changeSearchModemModelName(name));
  }

  function onChangeSearchModemModelProducer(producer) {
    dispatch(changeSearchModemModelProducer(producer));
  }

  function onLoadModemModelList() {
    dispatch(loadModemModel());
  }

  function onChangeUpdateModemModelInfoModalStatus(isVisibleModalStatus) {
    dispatch(changeUpdateModemModelModalStatus(isVisibleModalStatus));
  }

  function onDeleteModemModel(uid) {
    const message = `
     해당 모델 정보를 삭제 하시겠습니까?
    `;

    const result = window.confirm(message);

    if (!result) {
      return;
    }
    dispatch(deleteModemModel(uid));
  }

  useEffect(() => {
    onLoadModemModelList();
  }, [selectedCompany]);

  return (
    <Container
      style={{
        margin: 0,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <PageTitle>단말기 모델 관리</PageTitle>
      <Container style={{ marginBottom: 20, padding: 20 }}>
        <CommonCard>
          <ContainerSubTitle>단말기 모델 등록</ContainerSubTitle>
          <RegisterModemModelInput
            name={registerModemModelInfo.name}
            producer={registerModemModelInfo.producer}
            description={registerModemModelInfo.description}
            productionDate={registerModemModelInfo.productionDate}
            discontinuedDate={registerModemModelInfo.discontinuedDate}
            registerAttachments={registerAttachments}
            onChangeModemModelName={onChangeModemModelName}
            onChangeModemModelProducer={onChangeModemModelProducer}
            onChangeModemModelDescription={onChangeModemModelDescription}
            onChangeModemModelProductionDate={onChangeModemModelProductionDate}
            onChangeModemModelDiscontinuedDate={
              onChangeModemModelDiscontinuedDate
            }
            onChangeRegisterAttachments={onChangeRegisterAttachments}
            onRegisterModemModel={onRegisterModemModel}
          />
        </CommonCard>
      </Container>
      <PageTitle>등록 단말기 모델</PageTitle>
      <CommonCard>
        <ContainerSubTitle>검색 조건</ContainerSubTitle>
        <ModemModelSearchInput
          name={searchFilter.name}
          producer={searchFilter.producer}
          onChangeSearchModemModelName={onChangeSearchModemModelName}
          onChangeSearchModemModelProducer={onChangeSearchModemModelProducer}
          onLoadModemModelList={onLoadModemModelList}
        />
      </CommonCard>
      <ModemModelTable
        data={modemModelList}
        isVisibleUpdateModemModelInfoModal={isVisibleUpdateModemModelInfoModal}
        onChangeUpdateModemModelInfoModalStatus={
          onChangeUpdateModemModelInfoModalStatus
        }
        onDeleteModemModel={onDeleteModemModel}
      />
    </Container>
  );
}

export default ModemModel;
