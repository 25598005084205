import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import COLORS from 'constants/colors';
import {
  getARMTokenPayloadValue,
  getTokenPayloadValue,
  getTokenRole,
} from 'commons/token-helper';
import { useDispatch } from 'react-redux';
import { clearAllState } from 'reducers/global/action';
import { ARM_LOGOUT_ENDPOINT } from 'constants/DataSource';
import { USER_ROLE } from 'constants/UserType';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { PropTypes } from 'prop-types';

const drawerWidth = 240;

CommonAppBar.propTypes = {
  isOpenDrawer: PropTypes.bool,
  handleOpenDrawer: PropTypes.func,
};

function CommonAppBar({ handleOpenDrawer, isOpenDrawer }) {
  const useStyles = makeStyles(() => ({
    appBar: {
      backgroundColor: `${COLORS.background} !important`,
      borderBottom: `1px solid ${COLORS.border}`,
      borderColor: 'grey',
      boxShadow: 'none !important',
      paddingLeft: props =>
        props.isOpenDrawer ? `${drawerWidth}px !important` : '0px',
    },
    welcomeText: {
      flexGrow: 1,
      fontSize: '14px',
      color: COLORS.primary,
      textAlign: 'left',
    },
    defaultText: {
      flexGrow: 1,
      fontSize: '14px',
      color: COLORS.primary,
      textAlign: 'right',
    },
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const AppBarClasses = useStyles({ isOpenDrawer });
  const TypoClasses = useStyles();

  const userType = getTokenRole();

  async function handleLogout(e) {
    e.preventDefault();

    dispatch(clearAllState());

    localStorage.clear();

    if (userType === 'SA') {
      const requestUrl = ARM_LOGOUT_ENDPOINT;
      await (window.location.href = requestUrl);
    } else {
      navigate('/sign-in');
    }
  }

  const handleMyPage = () => {
    navigate('/main/my-page');
  };

  function getUserName() {
    if (userType === USER_ROLE.SUPER_ADMIN) {
      return getARMTokenPayloadValue('email');
    }
    if (userType === USER_ROLE.GENERAL_ADMIN) {
      return getTokenPayloadValue('name');
    }
    return '사용자';
  }

  return (
    <AppBar className={AppBarClasses.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleOpenDrawer}
        >
          <MenuIcon color="primary" />
        </IconButton>
        <Typography className={TypoClasses.welcomeText}>
          안녕하세요 {getUserName()}님
        </Typography>
        <Typography className={TypoClasses.defaultText}>
          <Button onClick={handleMyPage}>마이 페이지</Button>
          <Button onClick={handleLogout}>로그아웃</Button>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default memo(CommonAppBar);
