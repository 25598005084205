import { call, put, select, takeLatest } from 'redux-saga/effects';
import { isRequestSuccess } from 'commons/http-request-helper';
import { find } from 'lodash';
import {
  LOAD_COMPANY_INFO,
  loadCompanyInfoFailed,
  loadCompanyInfoSuccess,
  MODIFY_COMPANY,
  REGISTER_COMPANY,
} from 'reducers/company/action';
import {
  requestGetCompanyInfo,
  requestPostCompanyInfo,
  requestPutCompanyInfo,
} from 'api/company';
import {
  getARMTokenPayloadValue,
  getTokenPayloadValue,
  isValidToken,
} from 'commons/token-helper';
import { companyStateSelector } from 'reducers/company/reducer';
import { loadEncryptKeyList } from 'reducers/encrypt-key/action';
import { toast } from 'react-toastify';

/**
 * 회사정보 조회
 */
export function* getCompanyInfo() {
  if (!isValidToken()) {
    return;
  }
  try {
    const response = yield call(requestGetCompanyInfo);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }

    let companyUid = getTokenPayloadValue('companyUid');

    if (companyUid === '' && getARMTokenPayloadValue('email') !== '') {
      const regex = /@lfin.kr/;

      if (regex.test(getARMTokenPayloadValue('email'))) {
        companyUid = '66ef0a23-4e20-4a25-aa8b-f5436b17addf';
      }
    }

    const company = find(response.body.data, { uid: companyUid }) || {};

    yield put(
      loadCompanyInfoSuccess({
        companyList: response.body.data,
        selectedCompany: company,
      }),
    );
  } catch (e) {
    yield put(loadCompanyInfoFailed());
    toast.error('회사 정보 조회 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 회사 정보 등록
 */
export function* registerCompany() {
  if (!isValidToken()) {
    return;
  }
  const { registerCompanyInfo } = yield select(companyStateSelector);

  try {
    const response = yield call(requestPostCompanyInfo, registerCompanyInfo);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
  } catch (e) {
    yield put(loadCompanyInfoFailed());
    toast.error('회사 정보 등록을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 회사 정보 수정
 */
export function* updateCompanyInfo() {
  if (!isValidToken()) {
    return;
  }

  const { modifyCompanyInfo } = yield select(companyStateSelector);

  const {
    selectedCompany,
    companyName,
    companyAddress,
    companyPhone,
    companyRegisterNum,
  } = modifyCompanyInfo;

  const companyInfo = {
    companyUid: selectedCompany.uid,
    companyName,
    address: companyAddress,
    phone: companyPhone,
    companyRegisterNum,
  };

  try {
    const response = yield call(requestPutCompanyInfo, companyInfo);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error);
    }
    yield put(loadEncryptKeyList());
  } catch (e) {
    toast.error('회사 정보 수정을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export default function* companySaga() {
  yield takeLatest(LOAD_COMPANY_INFO, getCompanyInfo);
  yield takeLatest(REGISTER_COMPANY, registerCompany);
  yield takeLatest(MODIFY_COMPANY, updateCompanyInfo);
}
