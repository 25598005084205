import { createAction } from '@reduxjs/toolkit';

/**
 * 회사 정보 조회 Action
 */
export const LOAD_COMPANY_INFO = 'COMPANY/LOAD_COMPANY_INFO';
export const LOAD_COMPANY_INFO_SUCCESS = 'COMPANY/LOAD_COMPANY_INFO_SUCCESS';
export const LOAD_COMPANY_INFO_FAILED = 'COMPANY/LOAD_COMPANY_INFO_FAILED';

export const loadCompanyInfo = createAction(LOAD_COMPANY_INFO);
export const loadCompanyInfoSuccess = createAction(LOAD_COMPANY_INFO_SUCCESS);
export const loadCompanyInfoFailed = createAction(LOAD_COMPANY_INFO_FAILED);

/**
 * 회사 선택 Action
 */
export const CHANGE_SELECTED_COMPANY = 'COMPANY/CHANGE_SELECTED_COMPANY';
export const changeSelectedCompany = createAction(CHANGE_SELECTED_COMPANY);

/**
 * 회사 정보 등록 Action
 */
export const CHANGE_COMPANY_NAME = 'COMPANY/CHANGE_COMPANY_NAME';
export const CHANGE_COMPANY_ADDRESS = 'COMPANY/CHANGE_COMPANY_ADDRESS';
export const CHANGE_COMPANY_PHONE = 'COMPANY/CHANGE_COMPANY_PHONE';
export const CHANGE_COMPANY_REGISTER_NUM =
  'COMPANY/CHANGE_COMPANY_REGISTER_NUM';
export const REGISTER_COMPANY = 'COMPANY/REGISTER_COMPANY';

export const changeCompanyName = createAction(CHANGE_COMPANY_NAME);
export const changeCompanyAddress = createAction(CHANGE_COMPANY_ADDRESS);
export const changeCompanyPhone = createAction(CHANGE_COMPANY_PHONE);
export const changeCompanyRegisterNum = createAction(
  CHANGE_COMPANY_REGISTER_NUM,
);
export const registerCompany = createAction(REGISTER_COMPANY);

/**
 * 회사 정보 수정 Modal Action
 */
export const CHANGE_MODIFY_COMPANY_MODAL_STATUS =
  'COMPANY/CHANGE_MODIFY_COMPANY_MODAL_STATUS';
export const changeModifyCompanyModalStatus = createAction(
  CHANGE_MODIFY_COMPANY_MODAL_STATUS,
);

/**
 * 회사 정보 수정 Action
 */
export const CHANGE_MODIFY_COMPANY_NAME = 'COMPANY/CHANGE_MODIFY_COMPANY_NAME';
export const CHANGE_MODIFY_COMPANY_PHONE =
  'COMPANY/CHANGE_MODIFY_COMPANY_PHONE';
export const CHANGE_MODIFY_COMPANY_ADDRESS =
  'COMPANY/CHANGE_MODIFY_COMPANY_ADDRESS';
export const CHANGE_MODIFY_COMPANY_REGISTER_NUM =
  'COMPANY/CHANGE_MODIFY_COMPANY_REGISTER_NUM';
export const MODIFY_COMPANY = 'COMPANY/MODIFY_COMPANY';
export const CHANGE_SELECTED_MODIFY_COMPANY =
  'COMPANY/CHANGE_SELECTED_MODIFY_COMPANY';

export const changeModifyCompanyName = createAction(CHANGE_MODIFY_COMPANY_NAME);
export const changeModifyCompanyPhone = createAction(
  CHANGE_MODIFY_COMPANY_PHONE,
);
export const changeModifyCompanyAddress = createAction(
  CHANGE_MODIFY_COMPANY_ADDRESS,
);
export const changeModifyCompanyRegisterNum = createAction(
  CHANGE_MODIFY_COMPANY_REGISTER_NUM,
);
export const modifyCompany = createAction(MODIFY_COMPANY);
export const changeSelectedModifyCompany = createAction(
  CHANGE_SELECTED_MODIFY_COMPANY,
);
