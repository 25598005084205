import { createAction } from '@reduxjs/toolkit';

/**
 * 지도 로그 데이터 조회 검색 조건 Action
 */
export const CHANGE_SELECTED_MAP_MODEM_IMEI =
  'MODEM_MAP/CHANGE_SELECTED_MAP_MODEM_IMEI';
export const CHANGE_SELECTED_MAP_START_DATE =
  'MODEM_MAP/CHANGE_SELECTED_MAP_START_DATE';
export const CHANGE_SELECTED_MAP_END_DATE =
  'MODEM_MAP/CHANGE_SELECTED_MAP_END_DATE';
export const CHANGE_MAP_SIZE = 'MODEM_MAP/CHANGE_MAP_SIZE';

export const changeSelectedMapModemImei = createAction(
  CHANGE_SELECTED_MAP_MODEM_IMEI,
);
export const changeSelectedMapStartDate = createAction(
  CHANGE_SELECTED_MAP_START_DATE,
);
export const changeSelectedMapEndDate = createAction(
  CHANGE_SELECTED_MAP_END_DATE,
);
export const changeMapSize = createAction(CHANGE_MAP_SIZE);

/**
 * 로그 데이터 조회 검색 조건 Action
 */
export const CHANGE_SELECTED_MODEM_IMEI =
  'MODEM_MAP/CHANGE_SELECTED_MODEM_IMEI';
export const CHANGE_SELECTED_START_DATE =
  'MODEM_MAP/CHANGE_SELECTED_START_DATE';
export const CHANGE_SELECTED_END_DATE = 'MODEM_MAP/CHANGE_SELECTED_END_DATE';
export const CHANGE_REMOTE_IP = 'MODEM_MAP/CHANGE_REMOTE_IP';
export const CHANGE_HOST_NAME = 'MODEM_MAP/CHANGE_HOST_NAME';
export const CHANGE_DATA_TYPE = 'MODEM_MAP/CHANGE_DATA_TYPE';
export const CHANGE_TXID = 'MODEM_MAP/CHANGE_TXID';
export const CHANGE_PAGE = 'MODEM_MAP/CHANGE_PAGE';
export const CHANGE_SIZE = 'MODEM_MAP/CHANGE_SIZE';

export const changeSelectedModemImei = createAction(CHANGE_SELECTED_MODEM_IMEI);
export const changeSelectedStartDate = createAction(CHANGE_SELECTED_START_DATE);
export const changeSelectedEndDate = createAction(CHANGE_SELECTED_END_DATE);
export const changeRemoteIp = createAction(CHANGE_REMOTE_IP);
export const changeHostName = createAction(CHANGE_HOST_NAME);
export const changeDataType = createAction(CHANGE_DATA_TYPE);
export const changeTxid = createAction(CHANGE_TXID);

export const changePage = createAction(CHANGE_PAGE);
export const changeSize = createAction(CHANGE_SIZE);

/**
 * 선택 row txid, imei 상태 Action
 */
export const CHANGE_ROW_MODEM_IMEI = 'MODEM_MAP/CHANGE_ROW_MODEM_IMEI';
export const CHANGE_ROW_MODEM_TXID = 'MODEM_MAP/CHANGE_ROW_MODEM_TXID';

export const changeRowModemImei = createAction(CHANGE_ROW_MODEM_IMEI);
export const changeRowModemTxid = createAction(CHANGE_ROW_MODEM_TXID);

/**
 * 로그 데이터 조회 Action
 */
export const LOAD_TABLE_LOG_DATA = 'MODEM_MAP/LOAD_TABLE_LOG_DATA';
export const CHANGE_TABLE_LOG_DATA = 'MODEM_MAP/CHANGE_TABLE_LOG_DATA';

export const loadTableLogData = createAction(LOAD_TABLE_LOG_DATA);
export const changeTableLogData = createAction(CHANGE_TABLE_LOG_DATA);

/**
 * 기지국 로그 데이터 조회 Action
 */
export const LOAD_CELL_TOWER_DATA = 'MODEM_MAP/LOAD_CELL_TOWER_DATA';
export const CHANGE_CELL_TOWER_DATA = 'MODEM_MAP/CHANGE_CELL_TOWER_DATA';
export const CHANGE_CELL_TOWER_DATA_PAGE =
  'MODEM_MAP/CHANGE_CELL_TOWER_DATA_PAGE';

export const loadCellTowerData = createAction(LOAD_CELL_TOWER_DATA);
export const changeCellTowerData = createAction(CHANGE_CELL_TOWER_DATA);
export const changeCellTowerDataPage = createAction(
  CHANGE_CELL_TOWER_DATA_PAGE,
);

/**
 * GPS 로그 데이터 조회 Action
 */
export const LOAD_GPS_DATA = 'MODEM_MAP/LOAD_GPS_DATA';
export const CHANGE_GPS_DATA = 'MODEM_MAP/CHANGE_GPS_DATA';
export const CHANGE_LATEST_GPS_DATA = 'MODEM_MAP/CHANGE_LATEST_GPS_DATA';

export const loadGPSData = createAction(LOAD_GPS_DATA);
export const changeGPSData = createAction(CHANGE_GPS_DATA);
export const changeLatestGPSData = createAction(CHANGE_LATEST_GPS_DATA);

/**
 * Marker Modal Action
 */
export const CHANGE_MARKER_MODAL_STATUS =
  'MODEM_MAP/CHANGE_MARKER_MODAL_STATUS';
export const changeMarkerModalStatus = createAction(CHANGE_MARKER_MODAL_STATUS);

export const CHANGE_SELECTED_MARKER_DATA =
  'MODEM_MAP/CHANGE_SELECTED_MARKER_DATA';
export const changeSelectedMarkerData = createAction(
  CHANGE_SELECTED_MARKER_DATA,
);

/**
 * 데이터 clear Action
 */
export const CLEAR_MODEM_MAP_DATA = 'MODEM_MAP/CLEAR_MODEM_MAP_DATA';
export const clearModemMapData = createAction(CLEAR_MODEM_MAP_DATA);

export const CHANGE_LOGS_DATA_TOTAL_COUNT =
  'MODEM_MAP/CHANGE_LOGS_DATA_TOTAL_COUNT';
export const changeLogsDataTotalCount = createAction(
  CHANGE_LOGS_DATA_TOTAL_COUNT,
);

/**
 * 지도 조회 로딩 상태
 */
export const CHANGE_MAP_LOADING_INDICATOR_STATUS =
  'MODEM_MAP/CHANGE_MAP_LOADING_INDICATOR_STATUS';
export const changeMapLoadingIndicatorStatus = createAction(
  CHANGE_MAP_LOADING_INDICATOR_STATUS,
);

/**
 * 단말기 데이터 조회 로딩 상태
 */
export const CHANGE_LOGS_DATA_LOADING_INDICATOR_STATUS =
  'MODEM_MAP/CHANGE_LOGS_DATA_LOADING_INDICATOR_STATUS';
export const changeLogsDataLoadingIndicatorStatus = createAction(
  CHANGE_LOGS_DATA_LOADING_INDICATOR_STATUS,
);

/**
 * 단말기 지도 조회
 */
export const LOAD_MODEM_MAP_LOG_DATA = 'MODEM_MAP/LOAD_MODEM_MAP_LOG_DATA';
export const loadModemMapLogData = createAction(LOAD_MODEM_MAP_LOG_DATA);

/**
 * 단말기 지도 데이터 변경
 */
export const CHANGE_MODEM_MAP_LOG_DATA = 'MODEM_MAP/CHANGE_MODEM_MAP_LOG_DATA';
export const changeModemMapLogData = createAction(CHANGE_MODEM_MAP_LOG_DATA);
