/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_UPDATE_USER_LIST,
  LOAD_COMPANY_USER_LIST_SUCCESS,
} from 'reducers/user/action';

const initialState = {
  userList: [],
  updateUserList: [],
};

export const userStateSelector = state => state.userReducer;

export default createReducer(initialState, {
  [LOAD_COMPANY_USER_LIST_SUCCESS]: (state, { payload: userList }) => {
    state.userList = userList;
  },
  [CHANGE_UPDATE_USER_LIST]: (state, { payload: updateUserList }) => {
    state.updateUserList = updateUserList;
  },
});
