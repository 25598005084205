import executeAxios from 'commons/http-request-helper';

export async function requestGetDashboardData(companyUid) {
  const url = '/dashboard';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  const params = { companyUid };
  return executeAxios(url, method, headers, requestBody, params);
}
