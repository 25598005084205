import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'components/common/modal';
import PageTitle from 'components/common/page-title';
import { Button, Container, TextField } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeModifyModemImei,
  changeModifyModemLabel,
  changeModifyModemModel,
  changeModifyModemVersion,
  modifyModemInfo,
} from 'reducers/modem/action';
import { modemStateSelector } from 'reducers/modem/reducer';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { modemModelStateSelector } from 'reducers/modem-model/reducer';

const CustomContainer = styled(Container)`
  padding: 10px;
`;

export default function ModifyModemInfoModal({
  isVisibleModifyModemInfoModal,
  onChangeModifyModemInfoModalStatus,
}) {
  const onClickBackdrop = () => {
    onChangeModifyModemInfoModalStatus(false);
  };

  const dispatch = useDispatch();

  function onChangeModifyModemImei(event) {
    dispatch(changeModifyModemImei(event.target.value));
  }

  function onChangeModifyModemVersion(event) {
    dispatch(changeModifyModemVersion(event.target.value));
  }

  function onChangeModifyModemModel(modemModel) {
    dispatch(changeModifyModemModel(modemModel));
  }

  function onChangeModifyModemLabel(event) {
    dispatch(changeModifyModemLabel(event.target.value));
  }

  function onModifyModemInfo() {
    dispatch(modifyModemInfo());
    onClickBackdrop();
  }

  const { modemModelList } = useSelector(modemModelStateSelector);
  const { modifyModem } = useSelector(modemStateSelector);

  return (
    <CommonModal
      visible={isVisibleModifyModemInfoModal}
      onClose={onClickBackdrop}
    >
      <Container
        style={{
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <PageTitle>단말기 정보 수정</PageTitle>
        <CustomContainer>
          <FormControl variant="outlined">
            <InputLabel>모델</InputLabel>
            <Select
              label="모델"
              value={modifyModem.modemModel || ''}
              style={{ width: 180, marginRight: 10 }}
              onChange={event => onChangeModifyModemModel(event.target.value)}
            >
              {modemModelList.map(item => (
                <MenuItem key={item.uid} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="라벨"
            value={modifyModem.modemLabel}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={onChangeModifyModemLabel}
          />
          <TextField
            label="IMEI"
            value={modifyModem.imei}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={onChangeModifyModemImei}
          />
          <TextField
            label="version"
            value={modifyModem.version}
            variant="outlined"
            style={{ marginRight: 15 }}
            onChange={onChangeModifyModemVersion}
          />
        </CustomContainer>
        <Container style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button variant="outlined" onClick={onModifyModemInfo}>
            변경
          </Button>
        </Container>
      </Container>
    </CommonModal>
  );
}

ModifyModemInfoModal.propTypes = {
  isVisibleModifyModemInfoModal: PropTypes.bool,
  onChangeModifyModemInfoModalStatus: PropTypes.func,
};
