import { pickBy, differenceWith, isEqual } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { companyStateSelector } from 'reducers/company/reducer';
import { isRequestSuccess } from 'commons/http-request-helper';
import {
  requestDeleteModem,
  requestGetModemList,
  requestPostModem,
  requestPutModifyModemStatus,
} from 'api/modem';
import {
  DELETE_MODEM,
  LOAD_MODEM_LIST,
  loadModemList,
  loadModemListFailed,
  loadModemListSuccess,
  MODIFY_MODEM_INFO,
  MODIFY_MODEM_STATUS,
  REGISTER_MODEM,
} from 'reducers/modem/action';
import { modemStateSelector } from 'reducers/modem/reducer';

import { isValidToken } from 'commons/token-helper';
import { toast } from 'react-toastify';

/**
 * 등록 단말기 조회
 */
export function* getModemList() {
  if (!isValidToken()) {
    return;
  }
  try {
    const { selectedCompany } = yield select(companyStateSelector);
    const { searchFilter } = yield select(modemStateSelector);

    if (!selectedCompany.uid) {
      return;
    }

    const queryString = pickBy(searchFilter, value => value !== '');

    const filter = {
      ...queryString,
      isRegister: 'Y',
    };

    const response = yield call(
      requestGetModemList,
      selectedCompany.uid,
      filter,
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadModemListSuccess(response.body.data.modemList));
  } catch (e) {
    yield put(loadModemListFailed());
    toast.error('단말기 리스트 조회에 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 단말기 등록
 */
export function* postModem() {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  const { registerModemInfo } = yield select(modemStateSelector);

  if (registerModemInfo.imei === '') {
    toast.info('단말기 imei 정보를 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  if (registerModemInfo.version === '') {
    toast.info('단말기 버전을 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  if (!/[0-9]{15}$/.test(registerModemInfo.imei)) {
    toast.info('단말기 imei는 숫자 15자리로 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  if (!/^V[1-9]$/.test(registerModemInfo.version)) {
    toast.info('단말기 버전은 V+버전번호로 입력해주세요. ex) V1 V2', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  try {
    const response = yield call(
      requestPostModem,
      selectedCompany.uid,
      registerModemInfo.imei,
      registerModemInfo.version,
      registerModemInfo.modemModel.uid,
      registerModemInfo.modemLabel,
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    } else if (response.body.code === 'E301') {
      toast.warning('현재 비밀번호를 입력해주세요.', {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
      // 이미 등록되어 있는 단말기 에러 코드
      toast.info(response.body.message, {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
  } catch (e) {
    toast.error('단말기 등록을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
  yield put(loadModemList());
}

/**
 * 단말기 상태 변경
 */
export function* updateModemStatus() {
  if (!isValidToken()) {
    return;
  }
  const { modemList, modifyModemList } = yield select(modemStateSelector);
  const { selectedCompany } = yield select(companyStateSelector);

  const modifiedModemList = differenceWith(
    modifyModemList,
    modemList,
    isEqual,
  ).map(item => ({
    uid: item.uid,
    modemStatus: item.modemStatus,
  }));

  try {
    const response = yield call(
      requestPutModifyModemStatus,
      selectedCompany.uid,
      modifiedModemList,
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadModemList());
  } catch (e) {
    toast.error('단말기 상태 변경을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 단말기 정보 삭제
 */
export function* deleteModem({ payload: modemUid }) {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  try {
    const response = yield call(requestDeleteModem, selectedCompany.uid, [
      modemUid,
    ]);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadModemList());
  } catch (e) {
    toast.info('단말기 영구 삭제를 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 단말기 정보 수정 (imei, version)
 */
export function* updateModemInfo() {
  if (!isValidToken()) {
    return;
  }
  const { modifyModem } = yield select(modemStateSelector);
  const { selectedCompany } = yield select(companyStateSelector);

  const { selectedModem, imei, version, modemModel, modemLabel } = modifyModem;

  const body = {
    uid: selectedModem.uid,
    imei,
    version,
    modelUid: modemModel.uid,
    label: modemLabel,
  };

  try {
    const response = yield call(
      requestPutModifyModemStatus,
      selectedCompany.uid,
      [body],
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadModemList());
  } catch (e) {
    toast.error('단말기 정보 변경을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}
export default function* modemSaga() {
  yield takeLatest(LOAD_MODEM_LIST, getModemList);
  yield takeLatest(REGISTER_MODEM, postModem);
  yield takeLatest(MODIFY_MODEM_STATUS, updateModemStatus);
  yield takeLatest(DELETE_MODEM, deleteModem);
  yield takeLatest(MODIFY_MODEM_INFO, updateModemInfo);
}
