import React, { useEffect, useState } from 'react';
import PageTitle from 'components/common/page-title';
import { useDispatch, useSelector } from 'react-redux';
import { modemStateSelector } from 'reducers/modem/reducer';
import { modemMapStateSelector } from 'reducers/modem-map/reducer';
import { loadModemList } from 'reducers/modem/action';
import dayjs from 'dayjs';
import GoogleMapReact from 'google-map-react';

import {
  clearModemMapData,
  changeSelectedMapModemImei,
  changeSelectedMapStartDate,
  changeSelectedMapEndDate,
  changeSelectedModemImei,
  changeSelectedStartDate,
  changeSelectedEndDate,
  changeTxid,
  changeRemoteIp,
  changeDataType,
  changeHostName,
  changeMapLoadingIndicatorStatus,
  changeLogsDataLoadingIndicatorStatus,
  changeMarkerModalStatus,
  loadTableLogData,
  loadModemMapLogData,
  changeSelectedMarkerData,
} from 'reducers/modem-map/action';

import Marker from 'components/modem-map/marker';
import ModemDataTable from 'components/modem-map/modem-data-table';
import ModemMapSearchInput from 'components/modem-map/modem-map-search-input';
import LogDataSearchInput from 'components/modem-map/log-data-search-input';
import { CommonCard } from 'components/common/card';
import ContainerSubTitle from 'components/common/sub-title';
import { companyStateSelector } from 'reducers/company/reducer';
import styled from 'styled-components';
import LoadingIndicator from 'components/common/loading-indicator';
import MarkerModal from 'components/modem-map/marker/marker-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEqual } from 'lodash';

const Circle = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin-right: 3px;
`;

const CircleInfo = styled.div`
  margin-right: 3px;
`;
const googleMapDefaultProps = {
  // 광화문 좌표
  center: {
    lat: 37.5760222,
    lng: 126.9769,
  },
  zoom: 10,
};

const googleMapAPIKey = `${process.env.REACT_APP_GOOGLE_MAP_KEY}`;

export default function ModemMap() {
  const dispatch = useDispatch();

  const { selectedCompany } = useSelector(companyStateSelector);
  const { modemList } = useSelector(modemStateSelector);
  const [newModemMapLogData, setNewModemMapLogData] = useState([]);
  const [latestGPSData, setLatestGPSData] = useState({});
  const [latestLogGroupData, setLatestLogGroupData] = useState([]);
  const [logGroupsData, setLogGroupsData] = useState([]);

  const {
    mapSearchFilter,
    searchFilter,
    tableLogData,
    modemMapLogData,
    mapLoading,
    logDataLoading,
    isVisibleMarkerModal,
  } = useSelector(modemMapStateSelector);

  const [centerLocation, setCenterLocation] = useState(googleMapDefaultProps);

  function onChangeMarkerModalStatus(isVisibleModalStatus) {
    dispatch(changeMarkerModalStatus(isVisibleModalStatus));
  }

  function onChangeSelectedMarkerData(selectedMarkerData) {
    dispatch(changeSelectedMarkerData(selectedMarkerData));
  }

  function onChangeSelectedMapModemImei(modemImei) {
    dispatch(changeSelectedMapModemImei(modemImei));
  }

  function onChangeSelectedMapStartDate(startDate) {
    dispatch(
      changeSelectedMapStartDate(
        dayjs(startDate).format('YYYY-MM-DD HH:mm:ss'),
      ),
    );
  }

  function onChangeSelectedMapEndDate(endDate) {
    dispatch(
      changeSelectedMapEndDate(dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')),
    );
  }

  function onChangeSelectedModemImei(modemImei) {
    dispatch(changeSelectedModemImei(modemImei));
  }

  function onChangeSelectedStartDate(startDate) {
    dispatch(
      changeSelectedStartDate(dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')),
    );
  }

  function onChangeSelectedEndDate(endDate) {
    dispatch(
      changeSelectedEndDate(dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')),
    );
  }

  function onChangeTxid(txid) {
    dispatch(changeTxid(txid));
  }

  function onChangeRemoteIp(remoteIp) {
    dispatch(changeRemoteIp(remoteIp));
  }

  function onChangeDataType(dataType) {
    dispatch(changeDataType(dataType));
  }

  function onChangeHostName(hostName) {
    dispatch(changeHostName(hostName));
  }

  function onLoadMapData() {
    dispatch(changeMapLoadingIndicatorStatus(true));
    dispatch(clearModemMapData());
    setLogGroupsData([]);
    setNewModemMapLogData([]);
    setLatestLogGroupData([]);
    setLatestGPSData({});
    dispatch(loadModemMapLogData());
  }

  function onLoadTableLogData() {
    dispatch(changeLogsDataLoadingIndicatorStatus(true));
    dispatch(loadTableLogData());
  }

  useEffect(() => {
    dispatch(loadModemList());
    dispatch(clearModemMapData());
    onLoadTableLogData();
  }, [selectedCompany]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (modemMapLogData.last) {
      if (newModemMapLogData.length === 0) {
        return toast.info('기간 내 데이터가 없습니다.', {
          autoClose: 2000,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    // eslint-disable-next-line array-callback-return
    const logDataList = modemMapLogData.logGroups.filter(
      logGroup =>
        logGroup.logs.filter(log => log.dataType === 'G' && log.isParsed)
          .length > 0,
    );

    setNewModemMapLogData(logDataList);
  }, [modemMapLogData]);

  useEffect(() => {
    if (newModemMapLogData.length > 0) {
      const latestLogGroupDataList = newModemMapLogData
        .slice(0, 1)
        .map(logData => {
          let firstGPSData = false;
          let style = {};
          if (logData.logs.find(row => row.dataType === 'W')) {
            style = {
              backgroundColor: '#33f08b',
              width: '18px',
              height: '18px',
              zIndex: 9998,
            };
          } else {
            style = {
              backgroundColor: '#9c41ff',
              width: '18px',
              height: '18px',
              zIndex: 9997,
            };
          }

          return logData.logs.map(log => {
            if (log.dataType === 'G' && !firstGPSData) {
              firstGPSData = true;

              if (!isEqual(latestGPSData, log) && modemMapLogData.last) {
                setLatestGPSData(log);
              }

              if (log.content?.flag === 'GeolocationData') {
                style = {
                  backgroundColor: '#ff00da',
                  width: '18px',
                  height: '18px',
                  zIndex: 9999,
                };
              }

              return {
                logData,
                style,
                log,
              };
            }
            if (log.dataType === 'G') {
              style = {
                width: '20px',
                height: '20px',
                backgroundColor: '#e03131',
                zIndex: 9999,
              };
              return {
                logData,
                style,
                log,
              };
            }
            return undefined;
          });
        });

      // undefined 값 제거
      setLatestLogGroupData(
        latestLogGroupDataList.map(item => item.filter(el => el)),
      );

      // 최근 GPS 데이터 제외 나머지 데이터
      const logGroupDataList = newModemMapLogData
        .slice(1, newModemMapLogData.length)
        .map(logData => {
          let firstGPSData = false;
          let style = {};
          if (logData.logs.find(row => row.dataType === 'W')) {
            style = {
              backgroundColor: '#33f08b',
              width: '20px',
              height: '20px',
              zIndex: 9999,
            };
          } else {
            style = {
              backgroundColor: '#9c41ff',
              width: '18px',
              height: '18px',
              zIndex: 9998,
            };
          }
          return logData.logs.map(log => {
            if (log.dataType === 'G' && !firstGPSData) {
              firstGPSData = true;

              if (log.content?.flag === 'GeolocationData') {
                style = {
                  backgroundColor: '#ff00da',
                  width: '18px',
                  height: '18px',
                  zIndex: 9999,
                };
              }
              return {
                logData,
                style,
                log,
              };
            }
            if (log.dataType === 'G') {
              style = {
                width: '15px',
                height: '15px',
                backgroundColor: '#339af0',
                zIndex: 200,
              };
              return {
                logData,
                style,
                log,
              };
            }
            return undefined;
          });
        });
      setLogGroupsData(logGroupDataList.map(item => item.filter(el => el)));
    }
  }, [newModemMapLogData]);

  useEffect(() => {
    // 만약에 latestGPSData가 {}이 아니라면 centerLocation 변경
    if (
      latestGPSData.constructor === Object &&
      Object.keys(latestGPSData).length !== 0
    ) {
      setCenterLocation({
        center: {
          lat: latestGPSData.content?.latitude,
          lng: latestGPSData.content?.longitude,
        },
        zoom: 20,
      });
    }
  }, [latestGPSData]);

  return (
    <>
      <PageTitle>단말기 지도</PageTitle>
      <CommonCard>
        <ContainerSubTitle>검색 조건</ContainerSubTitle>
        <ModemMapSearchInput
          modemList={modemList}
          onLoadMapData={onLoadMapData}
          onChangeSelectedMapModemImei={onChangeSelectedMapModemImei}
          onChangeSelectedMapEndDate={onChangeSelectedMapEndDate}
          onChangeSelectedMapStartDate={onChangeSelectedMapStartDate}
          selectedMapModemImei={mapSearchFilter.imei}
          selectedMapStartDate={mapSearchFilter.startDate}
          selectedMapEndDate={mapSearchFilter.endDate}
        />
      </CommonCard>
      <div style={{ display: 'flex' }}>
        <Circle style={{ backgroundColor: '#fcc419' }} />
        <CircleInfo>기지국 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#33f08b' }} />
        <CircleInfo>WiFi 리스트가 있는 대표 GPS 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#9c41ff' }} />
        <CircleInfo>WiFi 리스트가 없는 대표 GPS 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#ff00da' }} />
        <CircleInfo>WiFi 데이터로 얻은 대표 GPS 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#339af0' }} />
        <CircleInfo>GPS 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#e03131' }} />
        <CircleInfo>마지막 GPS 데이터</CircleInfo>
      </div>
      <div
        style={{
          marginTop: 10,
          height: 450,
          width: '100%',
          textAlign: 'center',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapAPIKey }}
          yesIWantToUseGoogleMapApiInternals
          center={centerLocation.center}
          zoom={centerLocation.zoom}
          onBoundsChange={coord =>
            setCenterLocation(prevState => {
              const prevZoom = prevState.zoom;
              const newDragCoord = {
                center: {
                  lat: coord.lat,
                  lng: coord.lng,
                },
                zoom: prevZoom,
              };

              return newDragCoord;
            })
          }
        >
          {latestLogGroupData.length > 0 &&
            latestLogGroupData.map(logGroup =>
              logGroup.map(logs => (
                <Marker
                  key={logs.log.uid}
                  type="GPS"
                  object={logs.logData}
                  lat={logs.log.content?.latitude}
                  lng={logs.log.content?.longitude}
                  style={logs.style}
                  onChangeMarkerModalStatus={onChangeMarkerModalStatus}
                  onChangeSelectedMarkerData={onChangeSelectedMarkerData}
                />
              )),
            )}
          {logGroupsData.length > 0 &&
            logGroupsData.map(logGroup =>
              logGroup.map(logs => (
                <Marker
                  key={logs.log.uid}
                  type="GPS"
                  object={logs.logData}
                  lat={logs.log.content?.latitude}
                  lng={logs.log.content?.longitude}
                  style={logs.style}
                  onChangeMarkerModalStatus={onChangeMarkerModalStatus}
                  onChangeSelectedMarkerData={onChangeSelectedMarkerData}
                />
              )),
            )}
        </GoogleMapReact>
        <LoadingIndicator loading={mapLoading} top={-200} />
      </div>
      <PageTitle>단말기 데이터</PageTitle>
      <CommonCard>
        <ContainerSubTitle>검색 조건</ContainerSubTitle>
        <LogDataSearchInput
          modemList={modemList}
          selectedModemImei={searchFilter.imei}
          selectedStartDate={searchFilter.startDate}
          selectedEndDate={searchFilter.endDate}
          onLoadTableLogData={onLoadTableLogData}
          onChangeSelectedEndDate={onChangeSelectedEndDate}
          onChangeSelectedModemImei={onChangeSelectedModemImei}
          onChangeSelectedStartDate={onChangeSelectedStartDate}
          onChangeDataType={onChangeDataType}
          onChangeHostName={onChangeHostName}
          onChangeTxid={onChangeTxid}
          onChangeRemoteIp={onChangeRemoteIp}
          searchTxid={searchFilter.txid}
          searchRemoteIp={searchFilter.remoteIp}
          searchDataType={searchFilter.dataType}
          searchHostName={searchFilter.hostName}
        />
      </CommonCard>
      <div style={{ textAlign: 'center' }}>
        <LoadingIndicator loading={logDataLoading} top={20} />
        <ModemDataTable tableLogData={tableLogData} />
      </div>
      <MarkerModal
        isVisibleMarkerModal={isVisibleMarkerModal}
        onChangeMarkerModalStatus={onChangeMarkerModalStatus}
      />
    </>
  );
}
