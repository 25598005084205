import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { PropTypes } from 'prop-types';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)({
  marginBottom: '10px',
  marginRight: '5px',
});

function ModemSearchInput({
  searchModemImei,
  searchModemVersion,
  searchModemStatus,
  onChangeSearchModemImei,
  onChangeSearchModemVersion,
  onChangeSearchModemStatus,
  onLoadModemList,
}) {
  const [selectModemStatus, setSelectModemStatus] = useState(searchModemStatus);

  const SelectHandler = event => {
    setSelectModemStatus(event.target.value);
  };

  useEffect(() => {
    onChangeSearchModemStatus(selectModemStatus);
  }, [selectModemStatus]);

  return (
    <>
      <StyledTextField
        id="outlined-textarea"
        label="IMEI 검색"
        placeholder="IMEI"
        size="small"
        value={searchModemImei}
        onChange={event => onChangeSearchModemImei(event.target.value)}
      />
      <StyledTextField
        id="outlined-textarea"
        label="버전 검색"
        placeholder="버전 검색"
        size="small"
        value={searchModemVersion}
        onChange={event => onChangeSearchModemVersion(event.target.value)}
      />
      <FormControl sx={{ minWidth: 120, height: 34 }} size="small">
        <InputLabel>상태 검색</InputLabel>
        <Select
          onChange={event => SelectHandler(event)}
          value={selectModemStatus === undefined ? '' : selectModemStatus}
          label="상태 검색"
        >
          <MenuItem value="ACTIVATE">활성</MenuItem>
          <MenuItem value="DEACTIVATE">비활성</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        style={{ marginLeft: '10px', marginBottom: '5px' }}
        onClick={onLoadModemList}
      >
        조회
      </Button>
    </>
  );
}

ModemSearchInput.propTypes = {
  searchModemImei: PropTypes.string,
  searchModemVersion: PropTypes.string,
  searchModemStatus: PropTypes.string,
  onChangeSearchModemImei: PropTypes.func,
  onChangeSearchModemVersion: PropTypes.func,
  onChangeSearchModemStatus: PropTypes.func,
  onLoadModemList: PropTypes.func,
};
export default ModemSearchInput;
