import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import NoRegisterModemTableHeader from 'components/no-register-modem/no-register-modem-table-header';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    textAlign: 'center !important',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgb(221,243,255)',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: '100%',
    minWidth: 800,
  },
  tablePagination: {
    zIndex: 9999,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rgb(221,243,255)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function NoRegisterModemTable({ data, onChangeSelectedNoRegisterModemList }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [selectedModemList, setSelectedModemList] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function onClickCheckboxHandler(isChecked, modem) {
    if (
      !isChecked &&
      selectedModemList.findIndex(item => item.uid === modem.uid) !== -1
    ) {
      setSelectedModemList(prevState =>
        prevState.filter(item => item.uid !== modem.uid),
      );
      return;
    }
    setSelectedModemList(prevState =>
      prevState.concat({
        ...modem,
        modemStatus: 'ACTIVATE',
        isRegister: 'Y',
      }),
    );
  }

  useEffect(() => {
    setSelectedModemList([]);
  }, [data]);

  useEffect(() => {
    onChangeSelectedNoRegisterModemList(selectedModemList);
  }, [selectedModemList]);

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <NoRegisterModemTableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {data.length > 0 &&
              stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, key) => (
                  <StyledTableRow key={row.uid}>
                    <StyledTableCell>
                      {page * rowsPerPage + key + 1}
                    </StyledTableCell>
                    <StyledTableCell>{row.imei}</StyledTableCell>
                    <StyledTableCell>{row.version}</StyledTableCell>
                    <StyledTableCell>
                      {moment(row.createdAt).format('YYYY.MM.DD HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data.find(item => item.uid === row.uid).modemStatus ===
                      'ACTIVATE'
                        ? '활성'
                        : '비활성'}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Checkbox
                        checked={
                          selectedModemList.findIndex(
                            item => item.uid === row.uid,
                          ) !== -1
                        }
                        onChange={e =>
                          onClickCheckboxHandler(e.target.checked, row)
                        }
                        size="small"
                        color="default"
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
        onPageChange={handleChangePage}
      />
    </>
  );
}

NoRegisterModemTable.defaultProps = {
  data: [],
};

NoRegisterModemTable.propTypes = {
  data: PropTypes.array,
  onChangeSelectedNoRegisterModemList: PropTypes.func,
};

export default NoRegisterModemTable;
