import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;

function Marker({
  type,
  object,
  style,
  onChangeMarkerModalStatus,
  onChangeSelectedMarkerData,
}) {
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <Wrapper
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onClick={() => {
          onChangeMarkerModalStatus(true);
          onChangeSelectedMarkerData(object);
        }}
        style={style}
      >
        {isHover && (
          <div
            style={{
              padding: '2px 2px',
              width: 'max-content',
              backgroundColor: '#ffffff',
            }}
          >
            <p style={{ padding: 0, margin: '0 4px' }}>
              [{object.receivedAt}] {type}
            </p>
          </div>
        )}
      </Wrapper>
    </>
  );
}

Marker.propTypes = {
  type: PropTypes.string,
  object: PropTypes.object,
  style: PropTypes.object,
  onChangeMarkerModalStatus: PropTypes.func,
  onChangeSelectedMarkerData: PropTypes.func,
};

export default Marker;
