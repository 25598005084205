import React from 'react';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import HttpsRedirect from 'react-https-redirect';
import rootReducer, { rootSaga } from 'reducers/RootReducer';
import RootRouter from 'routes/RootRouter';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

function App() {
  return (
    <HttpsRedirect>
      <Provider store={store}>
        <RootRouter />
      </Provider>
    </HttpsRedirect>
  );
}

export default App;
