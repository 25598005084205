import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { PropTypes } from 'prop-types';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)({
  marginBottom: '10px',
  marginRight: '5px',
});

function EncryptKeySearchInput({
  searchEncryptKey,
  searchEncryptKeyVersion,
  searchEncryptKeyIsActive,
  onChangeSearchEncryptKey,
  onChangeSearchEncryptKeyVersion,
  onChangeSearchEncryptKeyIsActive,
  onLoadEncryptKeyList,
}) {
  const [selectIsActive, setSelectIsActive] = useState(
    searchEncryptKeyIsActive,
  );

  const SelectHandler = event => {
    setSelectIsActive(event.target.value);
  };

  useEffect(() => {
    onChangeSearchEncryptKeyIsActive(selectIsActive);
  }, [selectIsActive]);

  return (
    <>
      <StyledTextField
        id="outlined-textarea"
        label="암호화 종류 검색"
        placeholder="IMEI"
        size="small"
        value={searchEncryptKey}
        onChange={event => onChangeSearchEncryptKey(event.target.value)}
      />
      <StyledTextField
        id="outlined-textarea"
        label="버전 검색"
        placeholder="버전 검색"
        size="small"
        value={searchEncryptKeyVersion}
        onChange={event => onChangeSearchEncryptKeyVersion(event.target.value)}
      />
      <FormControl sx={{ minWidth: 120, height: 34 }} size="small">
        <InputLabel>상태 검색</InputLabel>
        <Select
          onChange={event => SelectHandler(event)}
          value={selectIsActive === undefined ? '' : selectIsActive}
          label="상태 검색"
        >
          <MenuItem value="Y">활성</MenuItem>
          <MenuItem value="N">비활성</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        style={{ marginLeft: '10px', marginBottom: '5px' }}
        onClick={onLoadEncryptKeyList}
      >
        조회
      </Button>
    </>
  );
}

EncryptKeySearchInput.propTypes = {
  searchEncryptKey: PropTypes.string,
  searchEncryptKeyVersion: PropTypes.string,
  searchEncryptKeyIsActive: PropTypes.string,
  onChangeSearchEncryptKey: PropTypes.func,
  onChangeSearchEncryptKeyVersion: PropTypes.func,
  onChangeSearchEncryptKeyIsActive: PropTypes.func,
  onLoadEncryptKeyList: PropTypes.func,
};
export default EncryptKeySearchInput;
