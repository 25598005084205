import executeAxios from 'commons/http-request-helper';

/**
 * 로그인 API
 * @param loginId
 * @param password
 */
export async function requestSignIn(loginId, password) {
  const url = '/sign-in';
  const method = 'post';
  const headers = {};
  const requestBody = {
    loginId,
    password,
  };
  return executeAxios(url, method, headers, requestBody);
}

/**
 * ARM 로그인 API
 * @param code
 */
export async function requestARMSignIn(code) {
  const baseUrl = `${process.env.REACT_APP_ARM_ENDPOINT}`;
  const url = '/oauth2/token';
  const method = 'post';
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const requestBody = {
    code,
    client_id: `${process.env.REACT_APP_ARM_CLIENT_ID}`,
    client_secret: `${process.env.REACT_APP_ARM_CLIENT_SECRET}`,
    grant_type: 'authorization_code',
    redirect_uri: `${process.env.REACT_APP_ARM_REDIRECT_URI}`,
  };
  return executeAxios(
    url,
    method,
    headers,
    new URLSearchParams(requestBody),
    {},
    baseUrl,
  );
}

/**
 * 관리자 정보 조회 API
 */
export async function requestGetManagerInfo() {
  const url = '/manager';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  return executeAxios(url, method, headers, requestBody);
}

/**
 * 관리자 정보 수정 API
 */
export async function requestPutManagerInfo(companyUid, updateManagerList) {
  const url = '/manager';
  const method = 'put';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = { companyUid, managerList: updateManagerList };

  return executeAxios(url, method, headers, requestBody);
}

/**
 * 관리자 비밀번호 변경 API
 */
export async function requestPutManagerPassword(password, newPassword) {
  const url = '/manager/password';
  const method = 'put';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = { password, newPassword };

  return executeAxios(url, method, headers, requestBody);
}

/**
 * 관리자 등록 API
 */
export async function requestPostManager(managerInfo) {
  const url = '/sign-up';
  const method = 'post';
  const headers = {};
  const requestBody = { ...managerInfo };

  return executeAxios(url, method, headers, requestBody);
}

/**
 * 관리자 삭제 API
 */
export async function requestDeleteManager(managerUid) {
  const url = `/manager/${managerUid}`;
  const method = 'delete';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};

  return executeAxios(url, method, headers, requestBody);
}
