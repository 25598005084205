import { createAction } from '@reduxjs/toolkit';

/**
 * 단말기 지도 데이터 조회 Action
 */
export const LOAD_MODEM_MAP_V2_LOGS_DATA =
  'MODEM_MAP_V2/LOAD_MODEM_MAP_V2_LOGS_DATA';
export const CHANGE_MODEM_MAP_V2_LOGS_DATA =
  'MODEM_MAP_V2/CHANGE_MODEM_MAP_V2_LOGS_DATA';

export const loadModemMapV2LogsData = createAction(LOAD_MODEM_MAP_V2_LOGS_DATA);
export const changeModemMapV2LogsData = createAction(
  CHANGE_MODEM_MAP_V2_LOGS_DATA,
);

/**
 * 단말기 지도 마커 상세조회 모달 Action
 */
export const CHANGE_MAP_MARKER_MODAL_STATUS =
  'MODEM_MAP_V2/CHANGE_MAP_MARKER_MODAL_STATUS';
export const changeMapMarkerModalStatus = createAction(
  CHANGE_MAP_MARKER_MODAL_STATUS,
);

/**
 * 지도 조회 로딩 상태
 */
export const CHANGE_MAP_LOADING_INDICATOR_STATUS =
  'MODEM_MAP_V2/CHANGE_MAP_LOADING_INDICATOR_STATUS';
export const changeMapLoadingIndicatorStatus = createAction(
  CHANGE_MAP_LOADING_INDICATOR_STATUS,
);

/**
 * 지도 데이터 초기화
 * @type {string}
 */
export const CLEAR_MODEM_MAP_DATA = 'MODEM_MAP_V2/CLEAR_MODEM_MAP_DATA';
export const clearModemMapData = createAction(CLEAR_MODEM_MAP_DATA);

export const CLEAR_MODEM_MAP_TIME_DATA =
  'MODEM_MAP_V2/CLEAR_MODEM_MAP_TIME_DATA';
export const clearModemMapTimeData = createAction(CLEAR_MODEM_MAP_TIME_DATA);

export const CHANGE_PAGE = 'MODEM_MAP_V2/CHANGE_PAGE';
export const CHANGE_SIZE = 'MODEM_MAP_V2/CHANGE_SIZE';

export const changePage = createAction(CHANGE_PAGE);
export const changeSize = createAction(CHANGE_SIZE);

/**
 * 지도에서 조회 클릭시
 */
export const LOAD_MODEM_MAP_V2_LOGS_TIME_DATA =
  'MODEM_MAP_V2/LOAD_MODEM_MAP_V2_LOGS_TIME_DATA';
export const loadModemMapV2LogsTimeData = createAction(
  LOAD_MODEM_MAP_V2_LOGS_TIME_DATA,
);

export const CHANGE_MODEM_MAP_V2_LOGS_TIME_DATA =
  'MODEM_MAP_V2/CHANGE_MODEM_MAP_V2_LOGS_TIME_DATA';
export const changeModemMapV2LogsTimeData = createAction(
  CHANGE_MODEM_MAP_V2_LOGS_TIME_DATA,
);

/**
 * 선택된 row 데이터의 raw 데이터 조회
 * @type {string}
 */
export const LOAD_SELECTED_ROW_RAW_LOG =
  'MODEM_MAP_V2/LOAD_SELECTED_ROW_RAW_LOG';
export const loadSelectedRowRawLog = createAction(LOAD_SELECTED_ROW_RAW_LOG);

export const CHANGE_SELECTED_ROW_RAW_LOG =
  'MODEM_MAP_V2/CHANGE_SELECTED_ROW_RAW_LOG';
export const changeSelectedRowRawLog = createAction(
  CHANGE_SELECTED_ROW_RAW_LOG,
);

/**
 * 선택된 row 데이터의 raw 데이터 모달 Action
 */
export const CHANGE_SELECTED_ROW_RAW_LOG_MODAL_STATUS =
  'MODEM_MAP_V2/CHANGE_SELECTED_ROW_RAW_LOG_MODAL_STATUS';
export const changeSelectedRowRawLogModalStatus = createAction(
  CHANGE_SELECTED_ROW_RAW_LOG_MODAL_STATUS,
);
