/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_MODEM_IMEI,
  CHANGE_MODEM_LABEL,
  CHANGE_MODEM_MODEL,
  CHANGE_MODEM_VERSION,
  CHANGE_MODIFY_MODEM_IMEI,
  CHANGE_MODIFY_MODEM_INFO_MODAL_STATUS,
  CHANGE_MODIFY_MODEM_LABEL,
  CHANGE_MODIFY_MODEM_MODEL,
  CHANGE_MODIFY_MODEM_STATUS,
  CHANGE_MODIFY_MODEM_VERSION,
  CHANGE_SEARCH_MODEM_IMEI,
  CHANGE_SEARCH_MODEM_STATUS,
  CHANGE_SEARCH_MODEM_VERSION,
  CHANGE_SELECTED_MODIFY_MODEM,
  LOAD_MODEM_LIST_FAILED,
  LOAD_MODEM_LIST_SUCCESS,
} from 'reducers/modem/action';

const initialState = {
  modemList: [],
  registerModemInfo: {
    modemModel: {},
    modemLabel: '',
    imei: '',
    version: '',
  },
  modifyModemList: [],
  searchFilter: {
    status: '',
    version: '',
    imei: '',
  },
  isVisibleModifyModalInfoModal: false,
  modifyModem: {
    selectedModem: {},
    modemModel: {},
    imei: '',
    version: '',
    modemLabel: '',
  },
};

export const modemStateSelector = state => state.modemReducer;

export default createReducer(initialState, {
  [LOAD_MODEM_LIST_SUCCESS]: (state, { payload: modemList }) => {
    state.modemList = modemList;
  },
  [LOAD_MODEM_LIST_FAILED]: state => {
    state.modemList = [];
  },
  [CHANGE_MODEM_IMEI]: (state, { payload: imei }) => {
    state.registerModemInfo.imei = imei;
  },
  [CHANGE_MODEM_VERSION]: (state, { payload: version }) => {
    state.registerModemInfo.version = version;
  },
  [CHANGE_MODEM_MODEL]: (state, { payload: modemModel }) => {
    state.registerModemInfo.modemModel = modemModel;
  },
  [CHANGE_MODEM_LABEL]: (state, { payload: modemLabel }) => {
    state.registerModemInfo.modemLabel = modemLabel;
  },
  [CHANGE_MODIFY_MODEM_STATUS]: (state, { payload: modifyModemList }) => {
    state.modifyModemList = modifyModemList;
  },
  [CHANGE_SEARCH_MODEM_IMEI]: (state, { payload: searchImei }) => {
    state.searchFilter.imei = searchImei;
  },
  [CHANGE_SEARCH_MODEM_VERSION]: (state, { payload: searchVersion }) => {
    state.searchFilter.version = searchVersion;
  },
  [CHANGE_SEARCH_MODEM_STATUS]: (state, { payload: searchModemStatus }) => {
    state.searchFilter.status = searchModemStatus;
  },
  [CHANGE_MODIFY_MODEM_INFO_MODAL_STATUS]: (
    state,
    { payload: isVisibleModifyModalInfoModal },
  ) => {
    state.isVisibleModifyModalInfoModal = isVisibleModifyModalInfoModal;
  },
  [CHANGE_SELECTED_MODIFY_MODEM]: (state, { payload: selectedModifyModem }) => {
    state.modifyModem.selectedModem = selectedModifyModem;
    state.modifyModem.imei = selectedModifyModem.imei;
    state.modifyModem.version = selectedModifyModem.version;
    state.modifyModem.modemModel = selectedModifyModem.modemModel;
    state.modifyModem.modemLabel = selectedModifyModem.label;
  },
  [CHANGE_MODIFY_MODEM_IMEI]: (state, { payload: modifyModemImei }) => {
    state.modifyModem.imei = modifyModemImei;
  },
  [CHANGE_MODIFY_MODEM_VERSION]: (state, { payload: modifyModemVersion }) => {
    state.modifyModem.version = modifyModemVersion;
  },
  [CHANGE_MODIFY_MODEM_MODEL]: (state, { payload: modifyModemModel }) => {
    state.modifyModem.modemModel = modifyModemModel;
  },
  [CHANGE_MODIFY_MODEM_LABEL]: (state, { payload: modifyModemLabel }) => {
    state.modifyModem.modemLabel = modifyModemLabel;
  },
});
