import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentType,
  DataContainer,
  DataText,
  SubDataContainer,
} from 'components/common/data-style';

export default function SystemData({ content }) {
  const { externalPowerInfo, temperature, batteryInfo } = content;

  return (
    <DataContainer>
      <SubDataContainer>
        <ContentType>외부 전원 전압</ContentType>
        <DataText>{externalPowerInfo}</DataText>
        <ContentType>디바이스 온도</ContentType>
        <DataText>{temperature}</DataText>
        <ContentType>배터리 정보</ContentType>
        <DataText>{batteryInfo}</DataText>
      </SubDataContainer>
    </DataContainer>
  );
}

SystemData.propTypes = {
  content: PropTypes.object,
};
