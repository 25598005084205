import styled from 'styled-components';

const ContainerSubTitle = styled.div`
  margin-bottom: 10px;
  padding-left: 5px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
`;

export default ContainerSubTitle;
