import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  padding: 3px;
  align-items: center;
  justify-content: center;
`;

export const SubDataContainer = styled.div`
  border-color: rgb(191, 192, 194);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  text-align: left;
  padding: 5px;
  margin-right: 15px;
  display: flex;
  min-width: 180px;
`;

export const ContentType = styled.span`
  font-weight: bold;
  margin-right: 3px;
`;

export const DataText = styled.span`
  margin-right: 4px;
`;
