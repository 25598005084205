import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';

function ModemMapSearchInput({
  modemList,
  selectedMapModemImei,
  selectedMapStartDate,
  selectedMapEndDate,
  onChangeSelectedMapModemImei,
  onChangeSelectedMapStartDate,
  onChangeSelectedMapEndDate,
  onLoadMapData,
}) {
  return (
    <>
      <FormControl variant="outlined">
        <InputLabel>IMEI</InputLabel>
        <Select
          label="IMEI"
          displayEmpty
          value={selectedMapModemImei || ''}
          style={{ width: 225, marginRight: 10 }}
          onChange={event => onChangeSelectedMapModemImei(event.target.value)}
        >
          {modemList.map(modem => (
            <MenuItem key={modem.uid} value={modem.imei}>
              {modem.imei}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ marginRight: 10, width: 220 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="시작일"
            value={selectedMapStartDate}
            inputFormat="yyyy-MM-dd HH:mm:ss"
            mask="____-__-__ __:__:__"
            onChange={event => onChangeSelectedMapStartDate(event)}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl style={{ marginRight: 10, width: 220 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="종료일"
            value={selectedMapEndDate}
            inputFormat="yyyy-MM-dd HH:mm:ss"
            mask="____-__-__ __:__:__"
            onChange={event => onChangeSelectedMapEndDate(event)}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl>
        <Button variant="outlined" onClick={onLoadMapData} size="large">
          조회
        </Button>
      </FormControl>
    </>
  );
}

ModemMapSearchInput.propTypes = {
  modemList: PropTypes.array,
  selectedMapModemImei: PropTypes.string,
  selectedMapStartDate: PropTypes.string,
  selectedMapEndDate: PropTypes.string,
  onChangeSelectedMapModemImei: PropTypes.func,
  onChangeSelectedMapStartDate: PropTypes.func,
  onChangeSelectedMapEndDate: PropTypes.func,
  onLoadMapData: PropTypes.func,
};

export default ModemMapSearchInput;
