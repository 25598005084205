import React from 'react';
import styled from 'styled-components';
import { Button, Container, Stack, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import FormControl from '@mui/material/FormControl';

const CustomContainer = styled(Container)`
  padding: 10px;
`;

function RegisterModemModelInput({
  name,
  producer,
  description,
  productionDate,
  discontinuedDate,
  registerAttachments,
  onChangeModemModelName,
  onChangeModemModelProducer,
  onChangeModemModelDescription,
  onChangeModemModelProductionDate,
  onChangeModemModelDiscontinuedDate,
  onChangeRegisterAttachments,
  onRegisterModemModel,
}) {
  return (
    <CustomContainer>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row">
          <TextField
            label="모델명"
            value={name}
            variant="standard"
            style={{ width: 300 }}
            onChange={e => onChangeModemModelName(e.target.value)}
          />
          <TextField
            label="제조사"
            value={producer}
            variant="standard"
            style={{ width: 300 }}
            onChange={e => onChangeModemModelProducer(e.target.value)}
          />
        </Stack>
        <div>
          <TextField
            label="상세 스펙"
            value={description}
            aria-placeholder="상세 스펙을 입력하세요."
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            onChange={e => onChangeModemModelDescription(e.target.value)}
          />
        </div>
        <FormControl style={{ width: 600 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction="row" spacing={1}>
              <DesktopDatePicker
                defaultValue={null}
                label="생산 일자"
                mask="____/__/__"
                inputFormat="yyyy/MM/dd"
                value={productionDate}
                onChange={onChangeModemModelProductionDate}
                renderInput={params => <TextField {...params} />}
              />
              <DesktopDatePicker
                defaultValue={null}
                label="단종 일자"
                mask="____/__/__"
                inputFormat="yyyy/MM/dd"
                value={discontinuedDate}
                onChange={onChangeModemModelDiscontinuedDate}
                renderInput={params => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </FormControl>
        <div>
          <Button
            variant="contained"
            component="label"
            style={{ width: 150, marginRight: 5 }}
          >
            첨부파일 업로드
            <input
              type="file"
              hidden
              onChange={event =>
                onChangeRegisterAttachments(event.target.files[0])
              }
            />
          </Button>
          {registerAttachments.name}
        </div>
        <div>
          <Button variant="outlined" onClick={onRegisterModemModel}>
            등록
          </Button>
        </div>
      </Stack>
    </CustomContainer>
  );
}

RegisterModemModelInput.propTypes = {
  name: PropTypes.string,
  producer: PropTypes.string,
  description: PropTypes.string,
  productionDate: PropTypes.string,
  discontinuedDate: PropTypes.string,
  registerAttachments: PropTypes.object,
  onChangeModemModelName: PropTypes.func,
  onChangeModemModelProducer: PropTypes.func,
  onChangeModemModelDescription: PropTypes.func,
  onChangeModemModelProductionDate: PropTypes.func,
  onChangeModemModelDiscontinuedDate: PropTypes.func,
  onChangeRegisterAttachments: PropTypes.func,
  onRegisterModemModel: PropTypes.func,
};

export default RegisterModemModelInput;
