import React, { useEffect } from 'react';
import { Button, Container } from '@mui/material';
import PageTitle from 'components/common/page-title';
import { useDispatch, useSelector } from 'react-redux';
import ContainerSubTitle from 'components/common/sub-title';
import NoRegisterModemTable from 'components/no-register-modem/no-register-modem-table';
import {
  changeSelectedNoRegisterModemList,
  deleteNoRegisterModemList,
  loadNoRegisterModemList,
  modifyModemIsRegister,
} from 'reducers/no-register-modem/action';
import { noRegisterModemStateSelector } from 'reducers/no-register-modem/reducer';

function NoRegisterModem() {
  const dispatch = useDispatch();

  const { noRegisterModemList } = useSelector(noRegisterModemStateSelector);

  function onLoadNoRegisterModemList() {
    dispatch(loadNoRegisterModemList());
  }

  function onChangeSelectedNoRegisterModemList(selectedNoRegisterModemList) {
    dispatch(changeSelectedNoRegisterModemList(selectedNoRegisterModemList));
  }

  function onModifyModemIsRegister() {
    dispatch(modifyModemIsRegister());
  }

  function onDeleteNoRegisterModemList(selectedNoRegisterModemList) {
    dispatch(deleteNoRegisterModemList(selectedNoRegisterModemList));
  }

  useEffect(() => {
    onLoadNoRegisterModemList();
  }, []);

  return (
    <Container
      style={{
        margin: 0,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <PageTitle>미등록 단말기 관리</PageTitle>
      <Container>
        <ContainerSubTitle>
          미등록 단말기 리스트
          <div style={{ float: 'right', marginBottom: '5px' }}>
            <Button
              variant="outlined"
              color="info"
              size="small"
              style={{ marginLeft: 15 }}
              onClick={onModifyModemIsRegister}
            >
              등록
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              style={{ marginLeft: 15 }}
              onClick={onDeleteNoRegisterModemList}
            >
              삭제
            </Button>
          </div>
        </ContainerSubTitle>
        <NoRegisterModemTable
          data={noRegisterModemList}
          onChangeSelectedNoRegisterModemList={
            onChangeSelectedNoRegisterModemList
          }
          onModifyModemIsRegister={{
            onModifyModemIsRegister,
          }}
        />
      </Container>
    </Container>
  );
}

export default NoRegisterModem;
