/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_MAP_LOADING_INDICATOR_STATUS,
  CHANGE_MAP_MARKER_MODAL_STATUS,
  CHANGE_MODEM_MAP_V2_LOGS_DATA,
  CHANGE_MODEM_MAP_V2_LOGS_TIME_DATA,
  CHANGE_PAGE,
  CHANGE_SELECTED_ROW_RAW_LOG,
  CHANGE_SELECTED_ROW_RAW_LOG_MODAL_STATUS,
  CHANGE_SIZE,
  CLEAR_MODEM_MAP_DATA,
  CLEAR_MODEM_MAP_TIME_DATA,
} from 'reducers/modem-map-v2/action';

export const modemMapV2StateSelector = state => state.modemMapV2Reducer;

const initialState = {
  modemMapData: {
    totalCount: 0,
    last: false,
    logGroups: [],
  },
  selectedRowRawData: {},
  timeData: [],
  isVisibleMapMarkerModal: false,
  isVisibleSelectedRowRawModal: false,
  mapLoading: false,
  page: 1,
  size: 20,
};

export default createReducer(initialState, {
  [CHANGE_MODEM_MAP_V2_LOGS_DATA]: (state, { payload: modemMapData }) => {
    state.modemMapData = modemMapData;
  },
  [CHANGE_MAP_MARKER_MODAL_STATUS]: (
    state,
    { payload: isVisibleMapMarkerModal },
  ) => {
    state.isVisibleMapMarkerModal = isVisibleMapMarkerModal;
  },
  [CHANGE_MAP_LOADING_INDICATOR_STATUS]: (state, { payload: loading }) => {
    state.mapLoading = loading;
  },
  [CLEAR_MODEM_MAP_DATA]: state => {
    state.modemMapData = initialState.modemMapData;
  },
  [CLEAR_MODEM_MAP_TIME_DATA]: state => {
    state.timeData = initialState.timeData;
  },
  [CHANGE_PAGE]: (state, { payload: page }) => {
    state.page = page;
  },
  [CHANGE_SIZE]: (state, { payload: size }) => {
    state.size = size;
  },
  [CHANGE_MODEM_MAP_V2_LOGS_TIME_DATA]: (state, { payload: timeData }) => {
    state.timeData = timeData;
  },
  [CHANGE_SELECTED_ROW_RAW_LOG]: (state, { payload: selectedRowRawData }) => {
    state.selectedRowRawData = selectedRowRawData;
  },
  [CHANGE_SELECTED_ROW_RAW_LOG_MODAL_STATUS]: (
    state,
    { payload: isVisibleSelectedRowRawModal },
  ) => {
    state.isVisibleSelectedRowRawModal = isVisibleSelectedRowRawModal;
  },
});
