import React from 'react';
import { withStyles } from '@mui/styles';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import PropTypes from 'prop-types';

const StyledTableCell = withStyles({
  head: {
    backgroundColor: 'rgb(30, 35, 47)',
    textAlign: 'center !important',
    color: '#ffffff !important',
  },
})(TableCell);

const StyledTableSortLabel = withStyles({
  root: {
    color: '#ffffff',
    '&:hover': {
      color: '#ffffff',
    },
  },
  active: {
    color: '#ffffff !important',
  },
  icon: {
    color: '#ffffff !important',
  },
})(TableSortLabel);

function ModemTableHeader({ classes, order, orderBy, onRequestSort }) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <StyledTableCell>번호</StyledTableCell>
          <StyledTableCell>라벨</StyledTableCell>
          <StyledTableCell>IMEI</StyledTableCell>
          <StyledTableCell>버전</StyledTableCell>
          <StyledTableCell
            key="createdAt"
            align="center"
            sortDirection={orderBy === 'createdAt' ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === 'createdAt'}
              direction={orderBy === 'createdAt' ? order : 'asc'}
              onClick={createSortHandler('createdAt')}
            >
              등록일
              {orderBy === 'createdAt' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </StyledTableSortLabel>
          </StyledTableCell>
          <StyledTableCell>활성 상태</StyledTableCell>
          <StyledTableCell>상태 변경</StyledTableCell>
          <StyledTableCell />
        </TableRow>
      </TableHead>
    </>
  );
}

ModemTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default ModemTableHeader;
