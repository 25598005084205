import React, { useState, memo } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  ContentType,
  DataContainer,
  DataText,
  SubDataContainer,
} from 'components/common/data-style';
import DataTableHeader from 'components/modem-map-v2/data-table-header';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeSelectedRowRawLogModalStatus,
  loadSelectedRowRawLog,
} from 'reducers/modem-map-v2/action';
import { modemMapV2StateSelector } from 'reducers/modem-map-v2/reducer';
import RawLogModal from 'components/modem-map-v2/data-table/raw-log-modal';

const StyledSubTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    maxWidth: 800,
  },
}))(TableCell);

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    textAlign: 'center !important',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgb(221,243,255)',
    },
    cursor: 'pointer',
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: '100%',
    minWidth: 1000,
  },
  tablePagination: {
    zIndex: 9999,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rgb(221,243,255)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function DataTableRow({
  logGroup,
  index,
  page,
  rowsPerPage,
  onLoadSelectedRowRaw,
}) {
  const [visibleData, setVisibleData] = useState(false);

  return (
    <>
      <StyledTableRow onClick={() => setVisibleData(prevState => !prevState)}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small">
            {visibleData ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{page * rowsPerPage + index + 1}</StyledTableCell>
        <StyledTableCell>{logGroup.imei}</StyledTableCell>
        <StyledTableCell>
          {moment(logGroup.createdAt, 'yyyy-MM-DD[T]HH:mm:ss').format(
            'YY.MM.DD HH:mm:ss',
          )}
        </StyledTableCell>
        <StyledTableCell>{`위도: ${logGroup.firstGps.latitude} 경도: ${
          logGroup.firstGps.longitude
        }`}</StyledTableCell>
        <StyledTableCell>{`Id: ${logGroup.cell.id} TAC: ${
          logGroup.cell.tac
        }`}</StyledTableCell>
        <StyledTableCell>
          <Button
            variant="outlined"
            onClick={e => {
              e.stopPropagation();
              onLoadSelectedRowRaw(logGroup.id);
            }}
            size="small"
          >
            조회
          </Button>
        </StyledTableCell>
      </StyledTableRow>
      {visibleData && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in>
              <Box sx={{ margin: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledSubTableCell align="center">
                        데이터 타입
                      </StyledSubTableCell>
                      <StyledSubTableCell align="center">
                        내용
                      </StyledSubTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="system">
                      <StyledSubTableCell align="center">
                        시스템 정보
                      </StyledSubTableCell>
                      <StyledSubTableCell align="center">
                        <DataContainer>
                          <SubDataContainer>
                            <ContentType>외부 전원 전압</ContentType>
                            <DataText>{logGroup.system.externalPower}</DataText>
                            <ContentType>디바이스 온도</ContentType>
                            <DataText>{logGroup.system.temperature}</DataText>
                            <ContentType>배터리</ContentType>
                            <DataText>{logGroup.system.batteryPower}</DataText>
                          </SubDataContainer>
                        </DataContainer>
                      </StyledSubTableCell>
                    </TableRow>
                    <TableRow key="gpsType">
                      <StyledSubTableCell align="center">
                        GPS 타입
                      </StyledSubTableCell>
                      <StyledSubTableCell align="center">
                        <DataContainer>
                          <SubDataContainer>
                            <ContentType>pos type</ContentType>
                            <DataText>{logGroup.posType}</DataText>
                          </SubDataContainer>
                        </DataContainer>
                      </StyledSubTableCell>
                    </TableRow>
                    <TableRow key="accel">
                      <StyledSubTableCell align="center">
                        가속도
                      </StyledSubTableCell>
                      <StyledSubTableCell align="center">
                        <DataContainer>
                          <SubDataContainer>
                            <ContentType>X</ContentType>
                            <DataText>{logGroup.mems.x}</DataText>
                            <ContentType>Y</ContentType>
                            <DataText>{logGroup.mems.y}</DataText>
                            <ContentType>Z</ContentType>
                            <DataText>{logGroup.mems.z}</DataText>
                          </SubDataContainer>
                        </DataContainer>
                      </StyledSubTableCell>
                    </TableRow>
                    <TableRow key="cell">
                      <StyledSubTableCell align="center">
                        기지국
                      </StyledSubTableCell>
                      <StyledSubTableCell align="center">
                        <DataContainer>
                          <SubDataContainer>
                            <ContentType>cellId</ContentType>
                            <DataText>{logGroup.cell.id}</DataText>
                            <ContentType>신호세기</ContentType>
                            <DataText>{logGroup.cell.rssi}</DataText>
                            <ContentType>mcc</ContentType>
                            <DataText>{logGroup.cell.mcc}</DataText>
                            <ContentType>mnc</ContentType>
                            <DataText>{logGroup.cell.mnc}</DataText>
                            <ContentType>tac</ContentType>
                            <DataText>{logGroup.cell.tac}</DataText>
                            <ContentType>주소</ContentType>
                            <DataText>
                              {logGroup.cellLocation?.address}
                            </DataText>
                          </SubDataContainer>
                        </DataContainer>
                      </StyledSubTableCell>
                    </TableRow>
                    {logGroup.gpsList?.map(gps => (
                      <TableRow key={gps.LA.toString() + gps.LO.toString()}>
                        <StyledSubTableCell align="center">
                          GPS
                        </StyledSubTableCell>
                        <StyledSubTableCell align="center">
                          <DataContainer>
                            <SubDataContainer>
                              <ContentType>위도</ContentType>
                              <DataText>{gps.LA}</DataText>
                              <ContentType>경도</ContentType>
                              <DataText>{gps.LO}</DataText>
                              <ContentType>고도</ContentType>
                              <DataText>{gps.A}</DataText>
                              <ContentType>신뢰성</ContentType>
                              <DataText>{gps.R}</DataText>
                              <ContentType>북(N)/남(S)</ContentType>
                              <DataText>{gps.NS}</DataText>
                              <ContentType>서(W)/동(E)</ContentType>
                              <DataText>{gps.EW}</DataText>
                            </SubDataContainer>
                          </DataContainer>
                        </StyledSubTableCell>
                      </TableRow>
                    ))}
                    {logGroup.wifiList?.map(wifi => (
                      <TableRow key={wifi.B + wifi.R.toString()}>
                        <StyledSubTableCell align="center">
                          WiFi
                        </StyledSubTableCell>
                        <StyledSubTableCell align="center">
                          <DataContainer>
                            <SubDataContainer>
                              <ContentType>MacAddress</ContentType>
                              <DataText>{wifi.B}</DataText>
                              <ContentType>이름</ContentType>
                              <DataText>{wifi.I}</DataText>
                              <ContentType>신호세기</ContentType>
                              <DataText>{wifi.R}</DataText>
                            </SubDataContainer>
                          </DataContainer>
                        </StyledSubTableCell>
                      </TableRow>
                    ))}
                    {logGroup.bleList?.map(ble => (
                      <TableRow key={ble.B + ble.R.toString()}>
                        <StyledSubTableCell align="center">
                          BLE
                        </StyledSubTableCell>
                        <StyledSubTableCell align="center">
                          <DataContainer>
                            <SubDataContainer>
                              <ContentType>MacAddress</ContentType>
                              <DataText>{ble.B}</DataText>
                              <ContentType>신호세기</ContentType>
                              <DataText>{ble.R}</DataText>
                              <ContentType>TxPower</ContentType>
                              <DataText>{ble.T}</DataText>
                              <ContentType>Major/Minor</ContentType>
                              <DataText>{ble.M}</DataText>
                            </SubDataContainer>
                          </DataContainer>
                        </StyledSubTableCell>
                      </TableRow>
                    ))}
                    <TableRow key="address">
                      <StyledSubTableCell align="center">
                        주소
                      </StyledSubTableCell>
                      <StyledSubTableCell align="center">
                        <DataContainer>
                          <SubDataContainer>
                            <ContentType>지번 주소</ContentType>
                            <DataText>{logGroup.address}</DataText>
                          </SubDataContainer>
                        </DataContainer>
                      </StyledSubTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

DataTableRow.propTypes = {
  logGroup: PropTypes.object,
  index: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onLoadSelectedRowRaw: PropTypes.func,
};

function DataTable({ isVisible, tableLogData }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const { selectedRowRawData, isVisibleSelectedRowRawModal } = useSelector(
    modemMapV2StateSelector,
  );

  function onChangeSelectedRowRawLogModalStatus(isVisibleStatus) {
    dispatch(changeSelectedRowRawLogModalStatus(isVisibleStatus));
  }

  function onLoadSelectedRowRaw(logId) {
    dispatch(loadSelectedRowRawLog({ logId }));
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return isVisible ? (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <DataTableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {tableLogData.logGroups &&
              (rowsPerPage > 0
                ? tableLogData.logGroups.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : tableLogData.logGroups.length > 0 &&
                  stableSort(
                    tableLogData.logGroups,
                    getComparator(order, orderBy),
                  )
              ).map((logGroup, index) => (
                <DataTableRow
                  key={logGroup.id}
                  logGroup={logGroup}
                  index={index}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onLoadSelectedRowRaw={onLoadSelectedRowRaw}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[
            20,
            50,
            100,
            300,
            500,
            1000,
            { label: 'All', value: -1 },
          ]}
          component="div"
          count={tableLogData.logGroups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.tablePagination}
          onPageChange={handleChangePage}
        />
      </TableContainer>
      {selectedRowRawData && (
        <RawLogModal
          isVisibleSelectedRowRawLogModal={isVisibleSelectedRowRawModal}
          onChangeSelectedRowRawLogModalStatus={
            onChangeSelectedRowRawLogModalStatus
          }
          selectedRowRawData={selectedRowRawData}
        />
      )}
    </>
  ) : (
    <></>
  );
}

DataTable.propTypes = {
  isVisible: PropTypes.bool,
  tableLogData: PropTypes.object,
};

export default memo(DataTable);
