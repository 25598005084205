import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import managerReducer from 'reducers/manager/reducer';
import encryptKeyReducer from 'reducers/encrypt-key/reducer';
import companyReducer from 'reducers/company/reducer';
import modemReducer from 'reducers/modem/reducer';
import noRegisterModemReducer from 'reducers/no-register-modem/reducer';
import modemMapReducer from 'reducers/modem-map/reducer';
import userReducer from 'reducers/user/reducer';
import managerSaga from 'reducers/manager/saga';
import encryptKeySaga from 'reducers/encrypt-key/saga';
import companySaga from 'reducers/company/saga';
import modemSaga from 'reducers/modem/saga';
import noRegisterModemSaga from 'reducers/no-register-modem/saga';
import { CLEAR_ALL_STATE } from 'reducers/global/action';
import modemMapSaga from 'reducers/modem-map/saga';
import dashboardSaga from 'reducers/dashboard/saga';
import dashboardReducer from 'reducers/dashboard/reducer';
import userSaga from 'reducers/user/saga';
import modemModelSaga from 'reducers/modem-model/saga';
import modemModelReducer from 'reducers/modem-model/reducer';
import modemMapV2Reducer from 'reducers/modem-map-v2/reducer';
import globalReducer from 'reducers/global/reducer';
import modemMapV2Saga from 'reducers/modem-map-v2/saga';

const rootReducer = combineReducers({
  companyReducer,
  managerReducer,
  encryptKeyReducer,
  modemReducer,
  noRegisterModemReducer,
  modemMapReducer,
  dashboardReducer,
  userReducer,
  modemModelReducer,
  modemMapV2Reducer,
  globalReducer,
});

export function* rootSaga() {
  yield all([
    managerSaga(),
    encryptKeySaga(),
    companySaga(),
    modemSaga(),
    noRegisterModemSaga(),
    modemMapSaga(),
    dashboardSaga(),
    userSaga(),
    modemModelSaga(),
    modemMapV2Saga(),
  ]);
}

export default (state, action) =>
  rootReducer(action.type === CLEAR_ALL_STATE ? undefined : state, action);
