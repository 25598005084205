import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useLocation } from 'react-router-dom';
import getDrawerRoute from 'routes/drawer-route/data';
import COLORS from 'constants/colors';
import { changeSelectedCompany } from 'reducers/company/action';
import { useDispatch, useSelector } from 'react-redux';
import CompanySelectInput from 'components/company/company-select-input';
import { companyStateSelector } from 'reducers/company/reducer';
import { getTokenRole } from 'commons/token-helper';
import { PropTypes } from 'prop-types';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: `${COLORS.primary} !important`,
  },
  drawerTitle: {
    color: 'white',
    fontSize: '16px !important',
    fontWeight: 'bold !important',
  },
  drawerGroupTitle: {
    color: 'white !important',
    fontWeight: 'bold !important',
    backgroundColor: `${COLORS.primary} !important`,
  },
  drawerItemTitle: {
    color: 'white',
    fontWeight: 'bold !important',
    paddingLeft: '16px',
  },
  drawerSelectedItem: {
    backgroundColor: 'rgb(44,50,65) !important',
  },
}));

CommonDrawer.propTypes = {
  isOpenDrawer: PropTypes.bool,
};

function CommonDrawer({ isOpenDrawer }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const onClickItem = (e, uri) => {
    e.preventDefault();
    setCurrentPath(uri);
    navigate(uri);
  };

  function onChangeSelectedCompany(value) {
    dispatch(changeSelectedCompany(value));
  }

  const { companyList } = useSelector(companyStateSelector);

  const userType = getTokenRole();

  const renderDrawerItems = () => {
    // eslint-disable-next-line array-callback-return, consistent-return
    const items = getDrawerRoute(userType).map(item => {
      if (item.type === 'title') {
        return (
          <ListSubheader className={classes.drawerGroupTitle} key={item.name}>
            {item.name}
          </ListSubheader>
        );
      }
      if (item.type === 'item') {
        const itemStyleClass =
          item.path === currentPath ? classes.drawerSelectedItem : null;
        return (
          <ListItem
            className={itemStyleClass}
            button
            key={item.name}
            onClick={e => {
              onClickItem(e, item.path);
            }}
          >
            <ListItemText className={classes.drawerItemTitle}>
              {item.name}
            </ListItemText>
          </ListItem>
        );
      }
    });

    return items;
  };

  return (
    <Drawer
      open={isOpenDrawer}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <Toolbar>
          <Typography className={classes.drawerTitle} noWrap>
            위치수집 단말기 CMS
          </Typography>
        </Toolbar>
      </div>
      <CompanySelectInput
        onChangeSelectedCompany={onChangeSelectedCompany}
        companyList={companyList}
      />
      <List>{renderDrawerItems()}</List>
    </Drawer>
  );
}

export default CommonDrawer;
