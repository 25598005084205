import React from 'react';
import styled from 'styled-components';
import { Button, Container, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { PropTypes } from 'prop-types';

const CustomContainer = styled(Container)`
  padding: 10px;
`;

function RegisterEncryptKeyInput({
  encryptKey,
  encryptKeyVersion,
  onChangeEncryptKey,
  onChangeEncryptKeyVersion,
  onRegisterEncryptKey,
}) {
  return (
    <CustomContainer>
      <FormControl variant="standard">
        <InputLabel id="register-encrypt-key">암호화 종류</InputLabel>
        <Select
          labelId="register-encrypt-key"
          label="암호화 종류"
          style={{ marginRight: 15, width: 125 }}
          value={encryptKey}
          onChange={event => onChangeEncryptKey(event.target.value)}
          defaultValue=""
        >
          <MenuItem value="L128">L128</MenuItem>
          <MenuItem value="A128">A128</MenuItem>
          <MenuItem value="PLAN">PLAN</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="암호화 버전"
        value={encryptKeyVersion}
        variant="standard"
        style={{ marginRight: 15 }}
        onChange={event => onChangeEncryptKeyVersion(event.target.value)}
      />
      <Button variant="outlined" onClick={onRegisterEncryptKey}>
        등록
      </Button>
    </CustomContainer>
  );
}

RegisterEncryptKeyInput.propTypes = {
  encryptKey: PropTypes.string,
  encryptKeyVersion: PropTypes.string,
  onChangeEncryptKey: PropTypes.func,
  onChangeEncryptKeyVersion: PropTypes.func,
  onRegisterEncryptKey: PropTypes.func,
};

export default RegisterEncryptKeyInput;
