import { createAction } from '@reduxjs/toolkit';

/**
 * 단말기 모델 등록 Action
 */
export const REGISTER_MODEM_MODEL = 'MODEM_MODEL/REGISTER_MODEM_MODEL';
export const CHANGE_MODEM_MODEL_NAME = 'MODEM_MODEL/CHANGE_MODEM_MODEL_NAME';
export const CHANGE_MODEM_MODEL_PRODUCER =
  'MODEM_MODEL/CHANGE_MODEM_MODEL_PRODUCER';
export const CHANGE_MODEM_MODEL_DESCRIPTION =
  'MODEM_MODEL/CHANGE_MODEM_MODEL_DESCRIPTION';
export const CHANGE_MODEM_MODEL_PRODUCTION_DATE =
  'MODEM_MODEL/CHANGE_MODEM_MODEL_PRODUCTION_DATE';
export const CHANGE_MODEM_MODEL_DISCONTINUED_DATE =
  'MODEM_MODEL/CHANGE_MODEM_MODEL_DISCONTINUED_DATE';

export const registerModemModel = createAction(REGISTER_MODEM_MODEL);
export const changeModemModelName = createAction(CHANGE_MODEM_MODEL_NAME);
export const changeModemModelProducer = createAction(
  CHANGE_MODEM_MODEL_PRODUCER,
);
export const changeModemModelDescription = createAction(
  CHANGE_MODEM_MODEL_DESCRIPTION,
);
export const changeModemModelProductionDate = createAction(
  CHANGE_MODEM_MODEL_PRODUCTION_DATE,
);
export const changeModemModelDiscontinuedDate = createAction(
  CHANGE_MODEM_MODEL_DISCONTINUED_DATE,
);

/**
 * 첨부파일 업로드 Action
 */
export const REQUEST_PRE_SIGNED_URL = 'MODEM_MODEL/REQUEST_PRE_SIGNED_URL';
export const CHANGE_ATTACHMENTS = 'MODEM_MODEL/CHANGE_ATTACHMENTS';
export const CHANGE_PRE_SIGNED_URL = 'MODEM_MODEL/CHANGE_PRE_SIGNED_URL';

export const requestPreSignedURL = createAction(REQUEST_PRE_SIGNED_URL);
export const changeAttachments = createAction(CHANGE_ATTACHMENTS);
export const changePreSignedURL = createAction(CHANGE_PRE_SIGNED_URL);

/**
 * 등록 단말기 모델 조회 Action
 */
export const LOAD_MODEM_MODEL = 'MODEM_MODEL/LOAD_MODEM_MODEL';
export const CHANGE_MODEM_MODEL_LIST = 'MODEM_MODEL/CHANGE_MODEM_MODEL_LIST';

export const CHANGE_SEARCH_MODEM_MODEL_NAME =
  'MODEM_MODEL/CHANGE_SEARCH_MODEM_MODEL_NAME';
export const CHANGE_SEARCH_MODEM_MODEL_PRODUCER =
  'MODEM_MODEL/CHANGE_SEARCH_MODEM_MODEL_PRODUCER';

export const loadModemModel = createAction(LOAD_MODEM_MODEL);
export const changeModemModelList = createAction(CHANGE_MODEM_MODEL_LIST);
export const changeSearchModemModelName = createAction(
  CHANGE_SEARCH_MODEM_MODEL_NAME,
);
export const changeSearchModemModelProducer = createAction(
  CHANGE_SEARCH_MODEM_MODEL_PRODUCER,
);

/**
 * 단말기 모델 정보 수정 Modal Action
 */
export const CHANGE_UPDATE_MODEM_MODEL_MODAL_STATUS =
  'MODEM_MODEL/CHANGE_UPDATE_MODEM_MODEL_MODAL_STATUS';
export const changeUpdateModemModelModalStatus = createAction(
  CHANGE_UPDATE_MODEM_MODEL_MODAL_STATUS,
);

/**
 * 단말기 모델 정보 수정 Action
 */
export const UPDATE_MODEM_MODEL_INFO = 'MODEM_MODEL/UPDATE_MODEM_MODEL_INFO';
export const CHANGE_UPDATE_MODEM_MODEL_NAME =
  'MODEM_MODEL/CHANGE_UPDATE_MODEM_MODEL_NAME';
export const CHANGE_UPDATE_MODEM_MODEL_PRODUCER =
  'MODEM_MODEL/CHANGE_UPDATE_MODEM_MODEL_PRODUCER';
export const CHANGE_UPDATE_MODEM_MODEL_DESCRIPTION =
  'MODEM_MODEL/CHANGE_UPDATE_MODEM_MODEL_DESCRIPTION';
export const CHANGE_UPDATE_MODEM_MODEL_PRODUCTION_DATE =
  'MODEM_MODEL/CHANGE_UPDATE_MODEM_MODEL_PRODUCTION_DATE';
export const CHANGE_UPDATE_MODEM_MODEL_DISCONTINUED_DATE =
  'MODEM_MODEL/CHANGE_UPDATE_MODEM_MODEL_DISCONTINUED_DATE';
export const CHANGE_SELECTED_UPDATE_MODEM_MODEL =
  'MODEM_MODEL/CHANGE_SELECTED_UPDATE_MODEM_MODEL';

export const updateModemModelInfo = createAction(UPDATE_MODEM_MODEL_INFO);
export const changeUpdateModemModelName = createAction(
  CHANGE_UPDATE_MODEM_MODEL_NAME,
);
export const changeUpdateModemModelProducer = createAction(
  CHANGE_UPDATE_MODEM_MODEL_PRODUCER,
);
export const changeUpdateModemModelDescription = createAction(
  CHANGE_UPDATE_MODEM_MODEL_DESCRIPTION,
);
export const changeUpdateModemModelProductionDate = createAction(
  CHANGE_UPDATE_MODEM_MODEL_PRODUCTION_DATE,
);
export const changeUpdateModemModelDiscontinuedDate = createAction(
  CHANGE_UPDATE_MODEM_MODEL_DISCONTINUED_DATE,
);
export const changeSelectedUpdateModemModel = createAction(
  CHANGE_SELECTED_UPDATE_MODEM_MODEL,
);

/**
 * 단말기 모델 정보 삭제 Action
 */
export const DELETE_MODEM_MODEL = 'MODEM_MODEL/DELETE_MODEM_MODEL';
export const deleteModemModel = createAction(DELETE_MODEM_MODEL);
