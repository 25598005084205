/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_PASSWORD,
  CHANGE_LOGIN_ID,
  LOAD_MY_INFO_SUCCESS,
  LOAD_MY_INFO_FAILED,
  CHANGE_NEW_MY_NAME,
  CHANGE_NEW_MY_CELLPHONE,
  CHANGE_NEW_MY_EMAIL,
  CHANGE_OLD_PASSWORD,
  CHANGE_NEW_PASSWORD,
  CHANGE_CONFIRM_NEW_PASSWORD,
  CLEAR_MY_INFO,
  CHANGE_REGISTER_MANAGER_NAME,
  CHANGE_REGISTER_MANAGER_EMAIL,
  CHANGE_REGISTER_LOGIN_ID,
  CHANGE_REGISTER_LOGIN_PASSWORD,
  CHANGE_REGISTER_CONFIRM_LOGIN_PASSWORD,
  LOAD_COMPANY_MANAGER_LIST_SUCCESS,
  CHANGE_REGISTER_MANAGER_CELLPHONE,
  CHANGE_UPDATE_MANAGER_LIST,
  CHANGE_UPDATE_MANAGER_INFO_MODAL_STATUS,
  CHANGE_NEW_MANAGER_NAME,
  CHANGE_NEW_MANAGER_CELLPHONE,
  CHANGE_NEW_MANAGER_EMAIL,
  CHANGE_SELECTED_UPDATE_MANAGER,
} from 'reducers/manager/action';

const initialState = {
  loginId: '',
  password: '',
  myInfo: {},
  newMyInfo: {
    name: '',
    cellphone: '',
    email: '',
  },
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  registerManagerInfo: {
    name: '',
    cellphone: '',
    email: '',
    loginId: '',
    password: '',
    confirmPassword: '',
  },
  managerList: [],
  updateManagerList: [],
  managerInfo: {},
  newManagerInfo: {
    selectedManager: {},
    name: '',
    cellphone: '',
    email: '',
  },
  isVisibleUpdateManagerInfoModal: false,
};

export const managerStateSelector = state => state.managerReducer;

export default createReducer(initialState, {
  [CHANGE_LOGIN_ID]: (state, { payload: loginId }) => {
    state.loginId = loginId;
  },
  [CHANGE_PASSWORD]: (state, { payload: password }) => {
    state.password = password;
  },
  [LOAD_MY_INFO_SUCCESS]: (state, { payload: myInfo }) => {
    state.myInfo = myInfo;
    state.newMyInfo = myInfo;
  },
  [LOAD_MY_INFO_FAILED]: state => {
    state.myInfo = {};
  },
  [CHANGE_NEW_MY_NAME]: (state, { payload: newName }) => {
    state.newMyInfo.name = newName;
  },
  [CHANGE_NEW_MY_CELLPHONE]: (state, { payload: newCellphone }) => {
    state.newMyInfo.cellphone = newCellphone;
  },
  [CHANGE_NEW_MY_EMAIL]: (state, { payload: newEmail }) => {
    state.newMyInfo.email = newEmail;
  },
  [CHANGE_OLD_PASSWORD]: (state, { payload: oldPassword }) => {
    state.oldPassword = oldPassword;
  },
  [CHANGE_NEW_PASSWORD]: (state, { payload: newPassword }) => {
    state.newPassword = newPassword;
  },
  [CHANGE_CONFIRM_NEW_PASSWORD]: (state, { payload: confirmNewPassword }) => {
    state.confirmNewPassword = confirmNewPassword;
  },
  [CLEAR_MY_INFO]: state => {
    state.newMyInfo.name = state.myInfo.name;
    state.newMyInfo.cellphone = state.myInfo.cellphone;
    state.newMyInfo.email = state.myInfo.email;
  },
  [CHANGE_REGISTER_MANAGER_NAME]: (state, { payload: managerName }) => {
    state.registerManagerInfo.name = managerName;
  },
  [CHANGE_REGISTER_MANAGER_CELLPHONE]: (
    state,
    { payload: managerCellphone },
  ) => {
    state.registerManagerInfo.cellphone = managerCellphone;
  },
  [CHANGE_REGISTER_MANAGER_EMAIL]: (state, { payload: managerEmail }) => {
    state.registerManagerInfo.email = managerEmail;
  },
  [CHANGE_REGISTER_LOGIN_ID]: (state, { payload: loginId }) => {
    state.registerManagerInfo.loginId = loginId;
  },
  [CHANGE_REGISTER_LOGIN_PASSWORD]: (state, { payload: password }) => {
    state.registerManagerInfo.password = password;
  },
  [CHANGE_REGISTER_CONFIRM_LOGIN_PASSWORD]: (
    state,
    { payload: confirmPassword },
  ) => {
    state.registerManagerInfo.confirmPassword = confirmPassword;
  },
  [LOAD_COMPANY_MANAGER_LIST_SUCCESS]: (state, { payload: managerList }) => {
    state.managerList = managerList;
  },
  [CHANGE_UPDATE_MANAGER_LIST]: (state, { payload: updateManagerList }) => {
    state.updateManagerList = updateManagerList;
  },
  [CHANGE_UPDATE_MANAGER_INFO_MODAL_STATUS]: (
    state,
    { payload: isVisibleUpdateManagerInfoModal },
  ) => {
    state.isVisibleUpdateManagerInfoModal = isVisibleUpdateManagerInfoModal;
  },
  [CHANGE_NEW_MANAGER_NAME]: (state, { payload: managerName }) => {
    state.newManagerInfo.name = managerName;
  },
  [CHANGE_NEW_MANAGER_CELLPHONE]: (state, { payload: managerCellphone }) => {
    state.newManagerInfo.cellphone = managerCellphone;
  },
  [CHANGE_NEW_MANAGER_EMAIL]: (state, { payload: managerEmail }) => {
    state.newManagerInfo.email = managerEmail;
  },
  [CHANGE_SELECTED_UPDATE_MANAGER]: (state, { payload: selectedManager }) => {
    state.newManagerInfo.selectedManager = selectedManager;
    state.newManagerInfo.name = selectedManager.name;
    state.newManagerInfo.cellphone = selectedManager.cellphone;
    state.newManagerInfo.email = selectedManager.email;
  },
});
