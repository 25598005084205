import React from 'react';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import { PropTypes } from 'prop-types';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)({
  marginBottom: '10px',
  marginRight: '5px',
});

function ModemModelSearchInput({
  name,
  producer,
  onChangeSearchModemModelName,
  onChangeSearchModemModelProducer,
  onLoadModemModelList,
}) {
  return (
    <>
      <StyledTextField
        label="모델명"
        placeholder="모델명"
        size="small"
        value={name}
        onChange={event => onChangeSearchModemModelName(event.target.value)}
      />
      <StyledTextField
        label="제조사"
        placeholder="제조사"
        size="small"
        value={producer}
        onChange={event => onChangeSearchModemModelProducer(event.target.value)}
      />
      <FormControl style={{ textAlign: 'center' }}>
        <Button variant="outlined" onClick={onLoadModemModelList} size="small">
          조회
        </Button>
      </FormControl>
    </>
  );
}

ModemModelSearchInput.propTypes = {
  name: PropTypes.string,
  producer: PropTypes.string,
  onChangeSearchModemModelName: PropTypes.func,
  onChangeSearchModemModelProducer: PropTypes.func,
  onLoadModemModelList: PropTypes.func,
};

export default ModemModelSearchInput;
