import { call, put, select, takeLatest } from 'redux-saga/effects';
import { isRequestSuccess } from 'commons/http-request-helper';

import { isValidToken } from 'commons/token-helper';
import {
  changeDashboardData,
  LOAD_DASHBOARD_DATA,
} from 'reducers/dashboard/action';
import { requestGetDashboardData } from 'api/dashboard';
import { companyStateSelector } from 'reducers/company/reducer';
import { toast } from 'react-toastify';

/**
 * 대시보드 데이터 조회
 */
export function* getDashboardData() {
  if (!isValidToken()) {
    return;
  }
  try {
    const { selectedCompany } = yield select(companyStateSelector);
    let companyUid;
    if (selectedCompany === {}) {
      companyUid = null;
    } else {
      companyUid = selectedCompany.uid;
    }
    const response = yield call(requestGetDashboardData, companyUid);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }

    yield put(changeDashboardData(response.body.data));
  } catch (e) {
    toast.error('대시보드 조회 실패', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export default function* dashboardSaga() {
  yield takeLatest(LOAD_DASHBOARD_DATA, getDashboardData);
}
