import React, { useEffect } from 'react';
import { Button, Container } from '@mui/material';
import PageTitle from 'components/common/page-title';
import { useDispatch, useSelector } from 'react-redux';
import { userStateSelector } from 'reducers/user/reducer';
import {
  changeUpdateUserList,
  deleteUserInfo,
  loadCompanyUserList,
  updateUserIsActive,
} from 'reducers/user/action';
import { companyStateSelector } from 'reducers/company/reducer';
import UserTable from 'components/user/user-table';
import ContainerSubTitle from 'components/common/sub-title';

export default function User() {
  const dispatch = useDispatch();
  const { userList } = useSelector(userStateSelector);
  const { selectedCompany } = useSelector(companyStateSelector);

  function onLoadUserList() {
    dispatch(loadCompanyUserList());
  }

  function onChangeUpdateUserList(updateUserList) {
    dispatch(changeUpdateUserList(updateUserList));
  }

  function onClickUpdateUserIsActive() {
    dispatch(updateUserIsActive());
  }

  function onDeleteUserInfo(uid) {
    const message = `
     해당 사용자 계정을 삭제 하시겠습니까?
    `;

    const result = window.confirm(message);

    if (!result) {
      return;
    }
    dispatch(deleteUserInfo(uid));
  }

  useEffect(() => {
    onLoadUserList();
  }, [selectedCompany]);

  return (
    <Container
      style={{
        margin: 0,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <PageTitle>사용자 계정 관리</PageTitle>
      <Container>
        <ContainerSubTitle>
          사용자 리스트
          <Button
            variant="outlined"
            style={{ float: 'right', marginBottom: '5px' }}
            onClick={onClickUpdateUserIsActive}
          >
            변경 적용
          </Button>
          <UserTable
            data={userList}
            onChangeUpdateUserList={onChangeUpdateUserList}
            onDeleteUserInfo={onDeleteUserInfo}
          />
        </ContainerSubTitle>
      </Container>
    </Container>
  );
}
