import executeAxios from 'commons/http-request-helper';

/**
 * 회사 정보 조회 API
 * @returns {Promise<*|{body: any, statusCode: number}>}
 */
export async function requestGetCompanyInfo() {
  const url = '/company';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  return executeAxios(url, method, headers, requestBody);
}

/**
 * 회사 정보 등록 API
 */
export async function requestPostCompanyInfo(companyInfo) {
  const url = '/company';
  const method = 'post';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = { companyInfo };
  return executeAxios(url, method, headers, requestBody);
}

/**
 * 회사 정보 수정 API
 */
export async function requestPutCompanyInfo(companyInfo) {
  const url = '/company';
  const method = 'put';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = { ...companyInfo };
  return executeAxios(url, method, headers, requestBody);
}

/**
 * 회사 관리자 리스트 조회 API
 */
export async function requestGetCompanyManagers(companyUid) {
  const url = `/company/${companyUid}/managers`;
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  return executeAxios(url, method, headers, requestBody);
}

/**
 * 회사 단말기 사용자 리스트 조회 API
 */
export async function requestGetCompanyUsers(companyUid) {
  const url = `/company/${companyUid}/users`;
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  return executeAxios(url, method, headers, requestBody);
}
