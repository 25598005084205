import React, { memo } from 'react';
import { HashRouter, Routes } from 'react-router-dom';
import MainRoute from 'routes/main-route';
import IndexRoute from 'routes/index-route';

function RootRouter() {
  return (
    <HashRouter>
      <Routes>
        {MainRoute()}
        {IndexRoute()}
      </Routes>
    </HashRouter>
  );
}

export default memo(RootRouter);
