import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from '@mui/material';
import PageTitle from 'components/common/page-title';
import styled from 'styled-components';
import SignInInput from 'components/sign-in/sign-in-input';
import ContainerSubTitle from 'components/common/sub-title';
import { ARM_LOGIN_ENDPOINT } from 'constants/DataSource';

const MyCard = styled(Card)`
  margin: 0 auto;
  padding: 16px 16px;
  min-width: 350px;
  max-width: 350px;
  width: 350px;
  height: 400px;
  border-radius: 8px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(8, 6, 6, 0.64) !important;
  color: white !important;
`;

function SignInCard({
  loginId,
  password,
  onChangeLoginId,
  onChangePassword,
  onSignIn,
}) {
  const onARMSignIn = async e => {
    e.preventDefault();
    const requestUrl = ARM_LOGIN_ENDPOINT;
    await (window.location.href = requestUrl);
  };

  return (
    <MyCard>
      <PageTitle>위치수집 단말기 CMS</PageTitle>
      <ContainerSubTitle style={{ color: 'white' }}>
        CMS를 이용하시려면
      </ContainerSubTitle>
      <ContainerSubTitle style={{ color: 'white' }}>
        발급받은 계정으로 접속해주세요.
      </ContainerSubTitle>
      <div style={{ height: 40 }} />
      <SignInInput
        label="아이디"
        value={loginId}
        onChange={onChangeLoginId}
        type="text"
      />
      <SignInInput
        label="패스워드"
        value={password}
        onChange={onChangePassword}
        type="password"
      />
      <div id="sign-in-card-devider" />
      <Button style={{ width: '80%' }} variant="contained" onClick={onSignIn}>
        일반 로그인
      </Button>
      <div style={{ height: 15 }} />
      <Button
        style={{ width: '80%' }}
        variant="contained"
        onClick={e => onARMSignIn(e)}
      >
        ARM(통합 계정)으로 로그인
      </Button>
    </MyCard>
  );
}

SignInCard.propTypes = {
  loginId: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onChangeLoginId: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onSignIn: PropTypes.func.isRequired,
};

export default SignInCard;
