import React, { useEffect } from 'react';
import CommonPortal from 'components/common/portal';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function CommonModal({
  centered,
  visible,
  onClose,
  maskClosable,
  closable,
  children,
  markerModal,
}) {
  useEffect(() => {
    // 모달 뒤 스크롤 방지
    if (visible) {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = 'no';
    } else {
      document.documentElement.style.overflow = '';
      document.body.scroll = '';
    }
  }, [visible]);

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = e => {
    if (onClose) {
      onClose(e);
    }
  };

  return (
    <CommonPortal elementId="modal-root">
      <ModalOverlay visible={visible}>
        <ModalWrapper
          tabIndex="-1"
          visible={visible}
          onClick={maskClosable ? onMaskClick : null}
        >
          <ModalInner
            centered={centered}
            tabIndex="0"
            markerModal={markerModal}
          >
            {closable && (
              <button type="button" onClick={close}>
                닫기
              </button>
            )}
            {children}
          </ModalInner>
        </ModalWrapper>
      </ModalOverlay>
    </CommonPortal>
  );
}

CommonModal.defaultProps = {
  centered: true,
  closable: false,
  maskClosable: true,
  visible: false,
  markerModal: false,
  onClose: () => {},
};

CommonModal.propTypes = {
  centered: PropTypes.bool,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool,
  visible: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClose: PropTypes.func,
  markerModal: PropTypes.bool,
};

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1250;
  overflow: auto;
  outline: 0;
  padding: 144px;
  width: 100%;
  min-width: 1300px;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1240;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 4px;
  top: ${props => (props.centered ? '50%' : '50%')};
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 16px;
  max-width: ${props => (props.markerModal ? '40vw' : '80vw')};
  min-width: 500px;
  overflow-y: scroll;
  overflow-x: scroll;
  max-height: 60vh;
`;
