import Card from '@mui/material/Card';
import styled from 'styled-components';

export const CommonCard = styled(Card)`
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 10px;
`;

export const DashboardCard = styled(Card)`
  align-items: center !important;
  width: 250px;
  margin: 20px;
  float: ${props => (props.reverse ? 'right' : 'left')};
`;
