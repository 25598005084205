import { createAction } from '@reduxjs/toolkit';

/**
 * 로그인 관련 Action (일반 로그인)
 */
export const CHANGE_LOGIN_ID = 'MANAGER/CHANGE_LOGIN_ID';
export const CHANGE_PASSWORD = 'MANAGER/CHANGE_PASSWORD';

export const SIGN_IN = 'MANAGER/SIGN_IN';

export const changeLoginId = createAction(CHANGE_LOGIN_ID);
export const changePassword = createAction(CHANGE_PASSWORD);

export const requestSignIn = createAction(SIGN_IN);

/**
 * ARM 로그인 관련 Action
 */
export const ARM_SIGN_IN = 'MANAGER/ARM_SIGN_IN';
export const requestARMSignIn = createAction(ARM_SIGN_IN);

/**
 * ARM 로그아웃 관련 Action
 */
export const LOGOUT = 'MANAGER/LOGOUT';
export const logout = createAction(LOGOUT);

/**
 * 로그인 유저 정보 조회 Action (내 정보 조회)
 */
export const LOAD_MY_INFO = 'MANAGER/LOAD_MY_INFO';
export const LOAD_MY_INFO_SUCCESS = 'MANAGER/LOAD_MY_INFO_SUCCESS';
export const LOAD_MY_INFO_FAILED = 'MANAGER/LOAD_MY_INFO_FAILED';

export const loadMyInfo = createAction(LOAD_MY_INFO);
export const loadMyInfoSuccess = createAction(LOAD_MY_INFO_SUCCESS);
export const loadMyInfoFailed = createAction(LOAD_MY_INFO_FAILED);

/**
 * 로그인 유저 정보 수정 Action
 */
export const UPDATE_MY_INFO = 'MANAGER/UPDATE_MY_INFO';
export const CHANGE_NEW_MY_NAME = 'MANAGER/CHANGE_NEW_MY_NAME';
export const CHANGE_NEW_MY_CELLPHONE = 'MANAGER/CHANGE_NEW_MY_CELLPHONE';
export const CHANGE_NEW_MY_EMAIL = 'MANAGER/CHANGE_NEW_MY_EMAIL';

// 로그인 유저 정보 수정 초기화용
export const CLEAR_MY_INFO = 'MANAGER/CLEAR_MY_INFO';

export const updateMyInfo = createAction(UPDATE_MY_INFO);
export const changeNewMyName = createAction(CHANGE_NEW_MY_NAME);
export const changeNewMyCellphone = createAction(CHANGE_NEW_MY_CELLPHONE);
export const changeNewMyEmail = createAction(CHANGE_NEW_MY_EMAIL);
export const clearMyInfo = createAction(CLEAR_MY_INFO);

/**
 * 비밀번호 변경 Action
 */
export const CHANGE_OLD_PASSWORD = 'MANAGER/CHANGE_OLD_PASSWORD';
export const CHANGE_NEW_PASSWORD = 'MANAGER/CHANGE_NEW_PASSWORD';
export const CHANGE_CONFIRM_NEW_PASSWORD =
  'MANAGER/CHANGE_CONFIRM_NEW_PASSWORD';
export const UPDATE_PASSWORD = 'MANAGER/UPDATE_PASSWORD';

export const changeOldPassword = createAction(CHANGE_OLD_PASSWORD);
export const changeNewPassword = createAction(CHANGE_NEW_PASSWORD);
export const changeConfirmNewPassword = createAction(
  CHANGE_CONFIRM_NEW_PASSWORD,
);
export const updatePassword = createAction(UPDATE_PASSWORD);

/**
 * 관리자 등록 Action
 */
export const CHANGE_REGISTER_MANAGER_NAME =
  'MANAGER/CHANGE_REGISTER_MANAGER_NAME';
export const CHANGE_REGISTER_MANAGER_CELLPHONE =
  'MANAGER/CHANGE_REGISTER_MANAGER_CELLPHONE';
export const CHANGE_REGISTER_MANAGER_EMAIL =
  'MANAGER/CHANGE_REGISTER_MANAGER_EMAIL';
export const CHANGE_REGISTER_LOGIN_ID = 'MANAGER/CHANGE_REGISTER_LOGIN_ID';
export const CHANGE_REGISTER_LOGIN_PASSWORD =
  'MANAGER/CHANGE_REGISTER_LOGIN_PASSWORD';
export const CHANGE_REGISTER_CONFIRM_LOGIN_PASSWORD =
  'MANAGER/CHANGE_REGISTER_CONFIRM_LOGIN_PASSWORD';

export const REGISTER_MANAGER = 'MANAGER/REGISTER_MANAGER';

export const changeRegisterManagerName = createAction(
  CHANGE_REGISTER_MANAGER_NAME,
);
export const changeRegisterManagerCellphone = createAction(
  CHANGE_REGISTER_MANAGER_CELLPHONE,
);
export const changeRegisterManagerEmail = createAction(
  CHANGE_REGISTER_MANAGER_EMAIL,
);
export const changeRegisterLoginId = createAction(CHANGE_REGISTER_LOGIN_ID);
export const changeRegisterLoginPassword = createAction(
  CHANGE_REGISTER_LOGIN_PASSWORD,
);
export const changeRegisterConfirmLoginPassword = createAction(
  CHANGE_REGISTER_CONFIRM_LOGIN_PASSWORD,
);

export const registerManager = createAction(REGISTER_MANAGER);

/**
 * 회사 관리자 리스트 조회 Action
 */
export const LOAD_COMPANY_MANAGER_LIST = 'MANAGER/LOAD_COMPANY_MANAGER_LIST';
export const LOAD_COMPANY_MANAGER_LIST_SUCCESS =
  'MANAGER/LOAD_COMPANY_MANAGER_LIST_SUCCESS';

export const loadCompanyManagerList = createAction(LOAD_COMPANY_MANAGER_LIST);
export const loadCompanyManagerListSuccess = createAction(
  LOAD_COMPANY_MANAGER_LIST_SUCCESS,
);

/**
 * 관리자 삭제 Action
 */
export const DELETE_MANAGER_INFO = 'MANAGER/DELETE_MANAGER_INFO';
export const deleteManagerInfo = createAction(DELETE_MANAGER_INFO);

/**
 * 관리자 계정 비활성화 Action
 */
export const CHANGE_UPDATE_MANAGER_LIST = 'MANAGER/CHANGE_UPDATE_MANAGER_LIST';
export const UPDATE_MANAGER_IS_ACTIVE = 'MANAGER/UPDATE_MANAGER_IS_ACTIVE';

export const updateManagerIsActive = createAction(UPDATE_MANAGER_IS_ACTIVE);
export const changeUpdateManagerList = createAction(CHANGE_UPDATE_MANAGER_LIST);

/**
 * 관리자 정보 수정 Modal Action
 */
export const CHANGE_UPDATE_MANAGER_INFO_MODAL_STATUS =
  'MANAGER/CHANGE_UPDATE_MANAGER_INFO_MODAL_STATUS';
export const changeUpdateManagerInfoModalStatus = createAction(
  CHANGE_UPDATE_MANAGER_INFO_MODAL_STATUS,
);

/**
 * 관리자 정보 수정 Action
 */
export const UPDATE_MANAGER_INFO = 'MANAGER/UPDATE_MANAGER_INFO';

export const CHANGE_NEW_MANAGER_NAME = 'MANAGER/CHANGE_NEW_MANAGER_NAME';
export const CHANGE_NEW_MANAGER_CELLPHONE =
  'MANAGER/CHANGE_NEW_MANAGER_CELLPHONE';
export const CHANGE_NEW_MANAGER_EMAIL = 'MANAGER/CHANGE_NEW_MANAGER_EMAIL';
export const CHANGE_SELECTED_UPDATE_MANAGER =
  'MANAGER/CHANGE_SELECTED_UPDATE_MANAGER';

export const updateManagerInfo = createAction(UPDATE_MANAGER_INFO);

export const changeNewManagerName = createAction(CHANGE_NEW_MANAGER_NAME);
export const changeNewManagerCellphone = createAction(
  CHANGE_NEW_MANAGER_CELLPHONE,
);
export const changeNewManagerEmail = createAction(CHANGE_NEW_MANAGER_EMAIL);
export const changeSelectedUpdateManager = createAction(
  CHANGE_SELECTED_UPDATE_MANAGER,
);
