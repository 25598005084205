/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_SELECTED_NO_REGISTER_MODEM_LIST,
  LOAD_NO_REGISTER_MODEM_LIST_FAILED,
  LOAD_NO_REGISTER_MODEM_LIST_SUCCESS,
} from 'reducers/no-register-modem/action';

const initialState = {
  noRegisterModemList: [],
  selectedNoRegisterModemList: [],
};

export const noRegisterModemStateSelector = state =>
  state.noRegisterModemReducer;

export default createReducer(initialState, {
  [CHANGE_SELECTED_NO_REGISTER_MODEM_LIST]: (
    state,
    { payload: selectedNoRegisterModemList },
  ) => {
    state.selectedNoRegisterModemList = selectedNoRegisterModemList;
  },
  [LOAD_NO_REGISTER_MODEM_LIST_SUCCESS]: (
    state,
    { payload: noRegisterModemList },
  ) => {
    state.noRegisterModemList = noRegisterModemList;
  },
  [LOAD_NO_REGISTER_MODEM_LIST_FAILED]: state => {
    state.noRegisterModemList = [];
  },
});
