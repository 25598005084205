import executeAxios from 'commons/http-request-helper';

/**
 * 단말기 모델 등록 API
 * @param companyUid
 * @param registerModemModelInfo
 * @returns {Promise<*|{body: *, statusCode: *}>}
 */
export async function requestPostModemModel(
  companyUid,
  registerModemModelInfo,
) {
  const url = '/modem-model';
  const method = 'post';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    modemModelList: [{ ...registerModemModelInfo }],
  };

  return executeAxios(url, method, headers, requestBody);
}

/**
 * PreSignedURL 요청 API
 * @param mimeType
 * @returns {Promise<*|{body: *, statusCode: *}>}
 */
export async function requestPostPreSignedURL(mimeType) {
  const url = '/modem-model/pre-signed-url';
  const method = 'post';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    mimeType,
  };

  return executeAxios(url, method, headers, requestBody);
}

/**
 * S3 upload
 */
export async function requestPutAttachments(
  preSignedURL,
  mimeType,
  attachments,
) {
  const url = preSignedURL;
  const method = 'put';
  const headers = {
    'Content-Disposition': 'attachment',
    'Content-Type': mimeType,
  };
  const requestBody = attachments;
  const baseURL = '';

  return executeAxios(url, method, headers, requestBody, baseURL);
}

/**
 * 등록 단말기 모델 리스트 조회
 */
export async function requestGetModemModel(companyUid, filter) {
  const url = '/modem-model';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  const params = { companyUid, ...filter };
  return executeAxios(url, method, headers, requestBody, params);
}

/**
 * 단말기 모델 정보 수정
 */
export async function requestPutModemModel(companyUid, modemModelList) {
  const url = '/modem-model';
  const method = 'put';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    modemModelList,
  };

  return executeAxios(url, method, headers, requestBody);
}

/**
 * 단말기 모델 정보 삭제
 */
export async function requestDeleteModemModel(companyUid, modemModelUidList) {
  const url = '/modem-model';
  const method = 'delete';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    modemModelUidList,
  };
  return executeAxios(url, method, headers, requestBody);
}
