import executeAxios from 'commons/http-request-helper';

export async function requestGetEncryptKeyList(companyUid, filter) {
  const url = '/encrypt-key';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  const params = { companyUid, ...filter };
  return executeAxios(url, method, headers, requestBody, params);
}

export async function requestEncryptKey(
  companyUid,
  encryptKey,
  encryptKeyVersion,
) {
  const url = '/encrypt-key';
  const method = 'post';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    encryptKey,
    encryptKeyVersion,
  };

  return executeAxios(url, method, headers, requestBody);
}

export async function requestDeleteEncryptKey(companyUid, uid) {
  const url = '/encrypt-key';
  const method = 'delete';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    encryptKeyList: [uid],
  };

  return executeAxios(url, method, headers, requestBody);
}

export async function requestPutEncryptKey(companyUid, modifyEncryptKeys) {
  const url = '/encrypt-key';
  const method = 'put';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    encryptKeyList: modifyEncryptKeys,
  };

  return executeAxios(url, method, headers, requestBody);
}
