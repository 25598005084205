import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  DELETE_NO_REGISTER_MODEM_LIST,
  LOAD_NO_REGISTER_MODEM_LIST,
  loadNoRegisterModemList,
  loadNoRegisterModemListFailed,
  loadNoRegisterModemListSuccess,
  MODIFY_MODEM_IS_REGISTER,
} from 'reducers/no-register-modem/action';
import { companyStateSelector } from 'reducers/company/reducer';
import {
  requestDeleteModem,
  requestGetModemList,
  requestPutModifyModemStatus,
} from 'api/modem';
import { isRequestSuccess } from 'commons/http-request-helper';
import { noRegisterModemStateSelector } from 'reducers/no-register-modem/reducer';
import { isValidToken } from 'commons/token-helper';
import { toast } from 'react-toastify';

/**
 * 미등록 단말기 조회
 */
export function* getNoRegisterModemList() {
  if (!isValidToken()) {
    return;
  }
  const companyUid = '';
  const queryString = { isRegister: 'N' };

  try {
    const response = yield call(requestGetModemList, companyUid, queryString);
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadNoRegisterModemListSuccess(response.body.data.modemList));
  } catch (e) {
    yield put(loadNoRegisterModemListFailed());
    toast.error('미등록 단말기 조회 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 미등록 단말기 등록 상태로 변경
 */
export function* updateModemIsRegister() {
  if (!isValidToken()) {
    return;
  }

  const { selectedCompany } = yield select(companyStateSelector);
  const { selectedNoRegisterModemList } = yield select(
    noRegisterModemStateSelector,
  );

  try {
    const response = yield call(
      requestPutModifyModemStatus,
      selectedCompany.uid,
      selectedNoRegisterModemList,
    );

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadNoRegisterModemList());
  } catch (e) {
    toast.error('미등록 단말기 등록상태 변경을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 미등록 단말기 삭제 기능
 */
export function* deleteNoRegisterModemList() {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  const { selectedNoRegisterModemList } = yield select(
    noRegisterModemStateSelector,
  );

  const modemUidList = selectedNoRegisterModemList.map(item => item.uid);

  try {
    const response = yield call(
      requestDeleteModem,
      selectedCompany.uid,
      modemUidList,
    );

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadNoRegisterModemList());
  } catch (e) {
    toast.error('미등록 단말기 삭제를 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}
export default function* noRegisterModemSaga() {
  yield takeLatest(MODIFY_MODEM_IS_REGISTER, updateModemIsRegister);
  yield takeLatest(LOAD_NO_REGISTER_MODEM_LIST, getNoRegisterModemList);
  yield takeLatest(DELETE_NO_REGISTER_MODEM_LIST, deleteNoRegisterModemList);
}
