import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from 'components/common/page-title';
import { Button } from '@mui/material';
import { CommonCard } from 'components/common/card';
import MapSearchInput from 'components/modem-map-v2/map-search-input';
import { useDispatch, useSelector } from 'react-redux';
import { modemStateSelector } from 'reducers/modem/reducer';
import styled from 'styled-components';
import LoadingIndicator from 'components/common/loading-indicator';
import {
  changeMapLoadingIndicatorStatus,
  clearModemMapData,
  loadModemMapV2LogsData,
  loadModemMapV2LogsTimeData,
} from 'reducers/modem-map-v2/action';
import { modemMapV2StateSelector } from 'reducers/modem-map-v2/reducer';
import { toast } from 'react-toastify';
import DataTable from 'components/modem-map-v2/data-table';
import { loadModemList } from 'reducers/modem/action';
import { companyStateSelector } from 'reducers/company/reducer';
import dayjs from 'dayjs';
import ModemV2Map from 'components/modem-map-v2/modem-v2-map';

const Circle = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin-right: 3px;
`;

const CircleInfo = styled.div`
  margin-right: 3px;
`;

export default function ModemMapV2() {
  const dispatch = useDispatch();

  const { selectedCompany } = useSelector(companyStateSelector);

  const { modemMapData, mapLoading, timeData } = useSelector(
    modemMapV2StateSelector,
  );
  const { modemList } = useSelector(modemStateSelector);

  const [visibleTable, setVisibleTable] = useState(false);
  const [date, setDate] = useState(
    dayjs(new Date().setDate(new Date().getDate())).format('YYYY-MM-DD'),
  );

  const [onlyFirstGps, setOnlyFirstGps] = useState(false);

  const onHandlerOnlyFirstGps = value => {
    setOnlyFirstGps(value);
  };

  function onLoadModemMapV2LogsTimeData(imei, selectedDate) {
    dispatch(changeMapLoadingIndicatorStatus(true));
    dispatch(clearModemMapData());
    dispatch(loadModemMapV2LogsTimeData({ imei, date: selectedDate }));
  }

  // 지도 데이터 조회
  function onLoadMapData(queryString) {
    dispatch(changeMapLoadingIndicatorStatus(true));
    dispatch(clearModemMapData());
    dispatch(loadModemMapV2LogsData(queryString));
  }

  useEffect(() => {
    dispatch(loadModemList());
    dispatch(clearModemMapData());
  }, [selectedCompany]);

  /**
   * 단말기 지도 데이터가 조회했을 때, 존재하는지 확인
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (modemMapData.last && modemMapData.logGroups.length === 0) {
      return toast.info('선택하신 시간대중에 데이터가 없습니다.', {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [modemMapData]);

  return (
    <>
      <PageTitle>단말기 지도</PageTitle>
      <CommonCard>
        <MapSearchInput
          modemList={modemList}
          onLoadModemMapV2LogsTimeData={onLoadModemMapV2LogsTimeData}
          date={date}
          dateHandler={setDate}
          timeData={timeData}
          onLoadMapData={onLoadMapData}
          modemMapDataTotalCount={modemMapData.totalCount}
          onlyFirstGpsHandler={onHandlerOnlyFirstGps}
          onlyFirstGps={onlyFirstGps}
        />
      </CommonCard>
      <div style={{ display: 'flex' }}>
        <Circle style={{ backgroundColor: '#fcc419' }} />
        <CircleInfo>기지국 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#33f08b' }} />
        <CircleInfo>WiFi 리스트가 있는 대표 GPS 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#9c41ff' }} />
        <CircleInfo>WiFi 리스트가 없는 대표 GPS 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#ff00da' }} />
        <CircleInfo>WiFi 데이터로 얻은 대표 GPS 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#339af0' }} />
        <CircleInfo>GPS 데이터</CircleInfo>
        <Circle style={{ backgroundColor: '#e03131' }} />
        <CircleInfo>마지막 GPS 데이터</CircleInfo>
      </div>
      <div
        style={{
          marginTop: 10,
          height: 560,
          width: '100%',
          textAlign: 'center',
        }}
      >
        <ModemV2Map modemMapData={modemMapData} onlyFirstGps={onlyFirstGps} />
        <LoadingIndicator loading={mapLoading} top={-200} />
      </div>
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <Button
          variant="outlined"
          style={{ width: '100%', margin: '5px 0 16px 0' }}
          onClick={() => setVisibleTable(prevState => !prevState)}
        >
          클릭하여 row 데이터 조회
        </Button>
        <DataTable isVisible={visibleTable} tableLogData={modemMapData} />
      </div>
    </>
  );
}
