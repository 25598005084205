import React from 'react';
import { Route } from 'react-router-dom';
import SignIn from 'containers/sign-in';

function IndexRoute() {
  return (
    <Route path="/">
      <Route exact path="sign-in" element={<SignIn />} />
      <Route index element={<SignIn />} />
    </Route>
  );
}

export default IndexRoute;
