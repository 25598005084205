import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'components/common/modal';
import PageTitle from 'components/common/page-title';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { modemMapStateSelector } from 'reducers/modem-map/reducer';
import ContainerSubTitle from 'components/common/sub-title';
import moment from 'moment';

export default function MarkerModal({
  isVisibleMarkerModal,
  onChangeMarkerModalStatus,
}) {
  const onClickBackdrop = () => {
    onChangeMarkerModalStatus(false);
  };

  const { selectedMarkerData } = useSelector(modemMapStateSelector);

  return (
    <CommonModal visible={isVisibleMarkerModal} onClose={onClickBackdrop}>
      <Container
        style={{
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <PageTitle>수집 정보</PageTitle>
        <ContainerSubTitle>수집 시각</ContainerSubTitle>
        <p style={{ padding: 0, margin: '0 5px' }}>
          {moment(
            selectedMarkerData.receivedAt,
            'yyyy-MM-DD[T]HH:mm:ss',
          ).format('YY.MM.DD HH:mm:ss')}
        </p>
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>수집 GPS 리스트</ContainerSubTitle>
        {Object.keys(selectedMarkerData).length !== 0 &&
          selectedMarkerData.logs
            .filter(log => log.dataType === 'G')
            .map(gpsData => (
              <p style={{ padding: 0, margin: '0 5px' }}>
                위도: {gpsData.content?.latitude} 경도:{' '}
                {gpsData.content?.longitude}
              </p>
            ))}
        <div style={{ height: '10px' }} />
        <ContainerSubTitle>수집 WiFi 리스트</ContainerSubTitle>
        {Object.keys(selectedMarkerData).length !== 0 &&
          selectedMarkerData.logs
            .filter(log => log.dataType === 'W')
            .map(wifiData => (
              <p style={{ padding: 0, margin: '0 5px' }}>
                Mac: {wifiData.content?.macAddress} 이름:{' '}
                {wifiData.content?.name} 신호 세기: {wifiData.content?.strength}
              </p>
            ))}
        <div style={{ height: '10px' }} />
      </Container>
    </CommonModal>
  );
}

MarkerModal.propTypes = {
  isVisibleMarkerModal: PropTypes.bool,
  onChangeMarkerModalStatus: PropTypes.func,
};
