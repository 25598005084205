import React, { useEffect } from 'react';
import PageTitle from 'components/common/page-title';
import { Button, Container } from '@mui/material';
import { CommonCard } from 'components/common/card';
import ContainerSubTitle from 'components/common/sub-title';
import RegisterManagerInput from 'components/manager/register-manager-input';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeRegisterConfirmLoginPassword,
  changeRegisterLoginId,
  changeRegisterLoginPassword,
  changeRegisterManagerCellphone,
  changeRegisterManagerEmail,
  changeRegisterManagerName,
  changeUpdateManagerInfoModalStatus,
  changeUpdateManagerList,
  deleteManagerInfo,
  loadCompanyManagerList,
  registerManager,
  updateManagerIsActive,
} from 'reducers/manager/action';
import { managerStateSelector } from 'reducers/manager/reducer';
import ManagerTable from 'components/manager/manager-table';
import { companyStateSelector } from 'reducers/company/reducer';
export default function Manager() {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector(companyStateSelector);
  const {
    managerList,
    registerManagerInfo,
    isVisibleUpdateManagerInfoModal,
  } = useSelector(managerStateSelector);

  function onLoadManagerList() {
    dispatch(loadCompanyManagerList());
  }

  function onChangeManagerName(managerName) {
    dispatch(changeRegisterManagerName(managerName));
  }
  function onChangeManagerCellphone(managerCellphone) {
    dispatch(changeRegisterManagerCellphone(managerCellphone));
  }
  function onChangeManagerEmail(managerEmail) {
    dispatch(changeRegisterManagerEmail(managerEmail));
  }
  function onChangeManagerLoginId(loginId) {
    dispatch(changeRegisterLoginId(loginId));
  }
  function onChangeManagerPassword(password) {
    dispatch(changeRegisterLoginPassword(password));
  }
  function onChangeManagerConfirmPassword(confirmPassword) {
    dispatch(changeRegisterConfirmLoginPassword(confirmPassword));
  }

  function onRegisterManager() {
    dispatch(registerManager());
  }

  function onClickUpdateManager() {
    dispatch(updateManagerIsActive());
  }

  function onChangeUpdateManagerList(updateManagerList) {
    dispatch(changeUpdateManagerList(updateManagerList));
  }

  function onDeleteManagerInfo(uid) {
    const message = `
     해당 관리자 계정을 삭제 하시겠습니까?
    `;

    const result = window.confirm(message);

    if (!result) {
      return;
    }
    dispatch(deleteManagerInfo(uid));
  }

  function onChangeUpdateManagerInfoModalStatus(isVisibleModalStatus) {
    dispatch(changeUpdateManagerInfoModalStatus(isVisibleModalStatus));
  }

  useEffect(() => {
    onLoadManagerList();
  }, [selectedCompany]);

  return (
    <Container
      style={{
        margin: 0,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <PageTitle>관리자 계정 관리</PageTitle>
      <Container style={{ marginBottom: 20, padding: 20 }}>
        <CommonCard>
          <ContainerSubTitle>관리자 등록</ContainerSubTitle>
          <RegisterManagerInput
            managerName={registerManagerInfo.name}
            managerCellphone={registerManagerInfo.cellphone}
            managerEmail={registerManagerInfo.email}
            managerLoginId={registerManagerInfo.loginId}
            managerPassword={registerManagerInfo.password}
            managerConfirmPassword={registerManagerInfo.confirmPassword}
            onChangeManagerName={onChangeManagerName}
            onChangeManagerCellphone={onChangeManagerCellphone}
            onChangeManagerEmail={onChangeManagerEmail}
            onChangeManagerLoginId={onChangeManagerLoginId}
            onChangeManagerPassword={onChangeManagerPassword}
            onChangeManagerConfirmPassword={onChangeManagerConfirmPassword}
            onRegisterManager={onRegisterManager}
          />
        </CommonCard>
      </Container>
      <Container>
        <ContainerSubTitle>
          관리자 리스트
          <Button
            variant="outlined"
            style={{ float: 'right', marginBottom: '5px' }}
            onClick={onClickUpdateManager}
          >
            변경 적용
          </Button>
          <ManagerTable
            data={managerList}
            onDeleteManagerInfo={onDeleteManagerInfo}
            onChangeUpdateManagerList={onChangeUpdateManagerList}
            isVisibleUpdateManagerInfoModal={isVisibleUpdateManagerInfoModal}
            onChangeUpdateManagerInfoModalStatus={
              onChangeUpdateManagerInfoModalStatus
            }
          />
        </ContainerSubTitle>
      </Container>
    </Container>
  );
}
