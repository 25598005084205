import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { PropTypes } from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Pagination,
  Stack,
} from '@mui/material';
import List from '@mui/material/List';
import { useDispatch } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  clearModemMapData,
  clearModemMapTimeData,
} from 'reducers/modem-map-v2/action';
import mouseLeftClickImg from 'images/mouse-left-click.png';
import mouseRightClickImg from 'images/mouse-right-click.png';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';

MapSearchInput.propTypes = {
  modemList: PropTypes.array,
  date: PropTypes.string,
  dateHandler: PropTypes.func,
  onLoadModemMapV2LogsTimeData: PropTypes.func,
  onLoadMapData: PropTypes.func,
  openTableHandler: PropTypes.func,
  timeData: PropTypes.array,
  modemMapDataTotalCount: PropTypes.number,
  onlyFirstGpsHandler: PropTypes.func,
  onlyFirstGps: PropTypes.bool,
};

const time24Array = Array.from(Array(24), (v, i) => i);

export default function MapSearchInput({
  date,
  dateHandler,
  modemList,
  onLoadModemMapV2LogsTimeData,
  timeData,
  onLoadMapData,
  modemMapDataTotalCount,
  onlyFirstGpsHandler,
  onlyFirstGps,
}) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [imei, setImei] = useState('');
  const [checkTimeItems, setCheckTimeItems] = useState([]);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(value);
    setPage(1);
  };

  const checkedItemHandler = (event, index) => {
    // 페이징 초기화
    setPage(1);

    if (event.type === 'click') {
      setCheckTimeItems([...checkTimeItems, index]);
    } else if (checkTimeItems.includes(index) && event.type === 'contextmenu') {
      const filter = checkTimeItems.filter(item => item !== index);
      setCheckTimeItems([...filter]);
    }
  };

  // eslint-disable-next-line consistent-return
  const loadMapLogDataHandler = () => {
    if (checkTimeItems.length > 0) {
      const hours = [];

      checkTimeItems.forEach(myNumber => {
        const formattedNumber = myNumber.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        hours.push(formattedNumber.toString());
      });

      const queryString = {
        imei,
        date,
        hours,
        page: page - 1,
        size: rowsPerPage,
      };
      onLoadMapData({ queryString });

      return () => {
        dispatch(clearModemMapData());
      };
    }
  };

  useEffect(() => {
    loadMapLogDataHandler();
  }, [checkTimeItems, page, rowsPerPage, onlyFirstGps]);

  useEffect(() => {
    setCheckTimeItems([]);
    dispatch(clearModemMapTimeData());
    onLoadModemMapV2LogsTimeData(imei, date);
    setPage([]);
  }, [date, imei]);

  return (
    <>
      <FormControl variant="outlined" size="small">
        <InputLabel>IMEI</InputLabel>
        <Select
          label="IMEI"
          displayEmpty
          value={imei || ''}
          style={{
            width: 225,
            marginRight: 10,
            '.css11u53oeMuiSelectSelectMuiInputBaseInputMuiOutlinedInputInput': {
              padding: '10px 10px',
            },
          }}
          onChange={event => setImei(event.target.value)}
        >
          {modemList.map(modem => (
            <MenuItem key={modem.uid} value={modem.imei}>
              {modem.imei.slice(-7)} / {modem.label} /{' '}
              {modem.modemModel ? modem.modemModel.name : '-'} / {modem.version}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ marginRight: 10, width: 135 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="조회일"
            value={date}
            inputFormat="yyyy-MM-dd"
            mask="____-__-__"
            onChange={event => dateHandler(dayjs(event).format('YYYY-MM-DD'))}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl
        variant="outlined"
        style={{ width: 100, marginRight: 10 }}
        size="small"
      >
        <InputLabel>조회 건수</InputLabel>
        <Select
          label="조회 건수"
          value={rowsPerPage}
          onChange={event => handleChangeRowsPerPage(event.target.value)}
        >
          {[
            { value: 1000, label: 1000 },
            { value: 3000, label: 3000 },
            { value: 5000, label: 5000 },
          ].map(size => (
            <MenuItem key={size.value} value={size.value}>
              {size.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            loadMapLogDataHandler();
          }}
        >
          <ReplayIcon />
        </IconButton>
      </FormControl>
      <div style={{ height: 15 }} />
      <>
        <List
          component={Stack}
          direction="row"
          disablePadding
          disablegutters="true"
        >
          {time24Array.map(time => {
            if (timeData.some(object => Number(object.time) === time)) {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <div
                  key={time}
                  role="button"
                  style={{
                    height: 30,
                    width: 20,
                    cursor: 'pointer',
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: `rgba(0, 0, 0, 0.6)`,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: `rgba(0, 0, 0, 0.6)`,

                    backgroundColor: checkTimeItems.includes(time)
                      ? `rgb(30, 35, 47)`
                      : 'transparent',
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  onClick={event => checkedItemHandler(event, time)}
                  onContextMenu={event => {
                    event.preventDefault();
                    checkedItemHandler(event, time);
                  }}
                >
                  <p
                    style={{
                      color: checkTimeItems.includes(time) ? 'white' : 'black',
                      margin: 0,
                      border: '1.5px solid',
                      borderColor: checkTimeItems.includes(time)
                        ? 'white'
                        : 'black',
                      borderRadius: 15,
                      width: 18,
                    }}
                  >
                    {time}
                  </p>
                </div>
              );
            }
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
              <div
                key={time}
                role="button"
                style={{
                  height: 30,
                  width: 20,
                  cursor: 'pointer',
                  backgroundColor: checkTimeItems.includes(time)
                    ? `rgb(30, 35, 47)`
                    : 'transparent',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderTopColor: `rgba(0, 0, 0, 0.6)`,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1px',
                  borderBottomColor: `rgba(0, 0, 0, 0.6)`,
                }}
                onClick={event => checkedItemHandler(event, time)}
                onContextMenu={event => {
                  event.preventDefault();
                  checkedItemHandler(event, time);
                }}
              >
                <p
                  style={{
                    margin: 0,
                    color: checkTimeItems.includes(time) ? 'white' : 'black',
                  }}
                >
                  {time}
                </p>
              </div>
            );
          })}
        </List>
      </>
      <div style={{ height: 5 }} />
      <div style={{ display: 'flex' }}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}
        >
          <img
            src={mouseLeftClickImg}
            alt="좌클릭"
            style={{ width: '20px', marginRight: '5px' }}
          />
          <p>선택시간 조회</p>
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}
        >
          <img
            src={mouseRightClickImg}
            alt="우클릭"
            style={{ width: '20px', marginRight: '5px', marginTop: '1px' }}
          />
          <p>선택시간 제외</p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyFirstGps}
                  onChange={e => onlyFirstGpsHandler(e.target.checked)}
                />
              }
              label="대표 GPS만 조회"
            />
          </FormGroup>
        </div>
      </div>

      {checkTimeItems.length > 0 && (
        <Pagination
          size="small"
          count={
            modemMapDataTotalCount / rowsPerPage <= 1
              ? 1
              : Math.floor(modemMapDataTotalCount / rowsPerPage) + 1
          }
          page={page}
          onChange={handleChangePage}
        />
      )}
    </>
  );
}
