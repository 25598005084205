/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { CHANGE_DASHBOARD_DATA } from 'reducers/dashboard/action';

const initialState = {
  dashboardData: {
    registerModemCount: 0,
    noRegisterModemCount: 0,
    registerEncryptKeyCount: 0,
  },
};

export const dashboardStateSelector = state => state.dashboardReducer;

export default createReducer(initialState, {
  [CHANGE_DASHBOARD_DATA]: (state, { payload: dashboardData }) => {
    state.dashboardData = dashboardData;
  },
});
