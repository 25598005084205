/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { CHANGE_LOADING_INDICATOR_STATUS } from 'reducers/global/action';

export const initialState = {
  loading: false,
};

export const globalStateSelector = state => state.globalReducer;

export default createReducer(initialState, {
  [CHANGE_LOADING_INDICATOR_STATUS]: (state, { payload: loading }) => {
    state.loading = loading;
  },
});
