import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentType,
  DataContainer,
  DataText,
  SubDataContainer,
} from 'components/common/data-style';

export default function BLEData({ content }) {
  const { name, strength, distance, txPower, macAddress } = content;

  return (
    <DataContainer>
      <SubDataContainer>
        <ContentType>Mac</ContentType>
        <DataText>{macAddress}</DataText>
        <ContentType>이름</ContentType>
        <DataText>{name}</DataText>
        <ContentType>신호세기</ContentType>
        <DataText>{strength}</DataText>
        <ContentType>txPower</ContentType>
        <DataText>{txPower}</DataText>
        <ContentType>거리</ContentType>
        <DataText>{distance}</DataText>
      </SubDataContainer>
    </DataContainer>
  );
}

BLEData.propTypes = {
  content: PropTypes.object,
};
