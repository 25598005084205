import React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { DashboardCard } from 'components/common/card';

const CustomButton = styled(Button)`
  color: #000000;
  :hover {
    color: #1c1c6a;
  }
`;

function BasicCard({ title, data, url }) {
  const navigate = useNavigate();

  return (
    <DashboardCard>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6" component="div" align="right">
          {data}개
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <CustomButton size="small" onClick={() => navigate(url)}>
          자세히 보기
        </CustomButton>
      </CardActions>
    </DashboardCard>
  );
}

BasicCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

export default BasicCard;
