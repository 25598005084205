import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import moment from 'moment';
import ModemDataTableHeader from 'components/modem-map/modem-data-table-header';
import { useDispatch } from 'react-redux';
import {
  changePage,
  changeSize,
  loadTableLogData,
} from 'reducers/modem-map/action';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WiFiData from 'components/modem-map/wifi-data';
import SystemData from 'components/modem-map/system-data';
import MemsData from 'components/modem-map/mems-data';
import CellTowerData from 'components/modem-map/cell-tower-data';
import BLEData from 'components/modem-map/ble-data';
import GPSData from 'components/modem-map/gps-data';

const StyledSubTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    maxWidth: 800,
  },
}))(TableCell);

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    textAlign: 'center !important',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgb(221,243,255)',
    },
    cursor: 'pointer',
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: '100%',
    minWidth: 1000,
  },
  tablePagination: {
    zIndex: 9999,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rgb(221,243,255)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function ContentDataCell(data) {
  if (!data.isParsed) {
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <span>⚠️</span>
        <span className="raw-data"> {data.rawData}</span>
      </>
    );
  }

  if (data.dataType === 'W') {
    return <WiFiData content={data.content} />;
  }
  if (data.dataType === 'S') {
    return <SystemData content={data.content} />;
  }
  if (data.dataType === 'M') {
    return <MemsData content={data.content} />;
  }
  if (data.dataType === 'L') {
    return <CellTowerData content={data.content} />;
  }
  if (data.dataType === 'B') {
    return <BLEData content={data.content} />;
  }
  if (data.dataType === 'G') {
    return <GPSData content={data.content} />;
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function ModemDataTableRow({
  page,
  rowsPerPage,
  logGroup,
  index,
  onHandlerOpenRow,
  openRow,
}) {
  function onClickTableRow(selectedRow) {
    onHandlerOpenRow(selectedRow.uid);
  }

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => onClickTableRow(logGroup)}
          >
            {logGroup.uid === openRow ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{page * rowsPerPage + index + 1}</StyledTableCell>
        <StyledTableCell>{logGroup.imei}</StyledTableCell>
        <StyledTableCell>
          {moment(logGroup.receivedAt, 'yyyy-MM-DD[T]HH:mm:ss').format(
            'YY.MM.DD HH:mm:ss',
          )}
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={logGroup.uid === openRow} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledSubTableCell>IMEI</StyledSubTableCell>
                    <StyledSubTableCell>Txid(수집시각)</StyledSubTableCell>
                    <StyledSubTableCell align="center">
                      데이터 타입
                    </StyledSubTableCell>
                    <StyledSubTableCell align="center">내용</StyledSubTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logGroup.logs.length > 0 &&
                    logGroup.logs.map(log => (
                      <TableRow key={log.uid}>
                        <StyledSubTableCell>{log.imei}</StyledSubTableCell>
                        <StyledSubTableCell>{log.txid}</StyledSubTableCell>
                        <StyledSubTableCell align="center">
                          {log.dataType}
                        </StyledSubTableCell>
                        <StyledSubTableCell align="center">
                          {ContentDataCell(log)}
                        </StyledSubTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

ModemDataTableRow.propTypes = {
  logGroup: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  openRow: PropTypes.string,
  index: PropTypes.number,
  onHandlerOpenRow: PropTypes.func,
};

function ModemDataTable({ tableLogData }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [openRow, setOpenRow] = useState('');

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    dispatch(changePage(page));
    dispatch(loadTableLogData());
  }, [page]);

  useEffect(() => {
    dispatch(changeSize(rowsPerPage));
    dispatch(loadTableLogData());
  }, [rowsPerPage]);

  function onHandlerOpenRow(uid) {
    if (openRow === uid) {
      setOpenRow('');
    } else {
      setOpenRow(uid);
    }
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <ModemDataTableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {tableLogData.logGroups.length > 0 &&
              stableSort(
                tableLogData.logGroups,
                getComparator(order, orderBy),
              ).map((logGroup, index) => (
                <ModemDataTableRow
                  key={logGroup.uid}
                  logGroup={logGroup}
                  index={index}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onHandlerOpenRow={onHandlerOpenRow}
                  openRow={openRow}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={tableLogData.totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
        onPageChange={handleChangePage}
      />
    </>
  );
}

ModemDataTable.propTypes = {
  tableLogData: PropTypes.object,
};
export default ModemDataTable;
