import executeAxios from 'commons/http-request-helper';

export async function requestGetModemList(companyUid, filter) {
  const url = '/modem';
  const method = 'get';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};
  const params = { companyUid, ...filter };
  return executeAxios(url, method, headers, requestBody, params);
}

export async function requestPostModem(
  companyUid,
  modemImei,
  modemVersion,
  modelUid,
  modemLabel,
) {
  const url = '/modem';
  const method = 'post';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    modemList: [
      { imei: modemImei, version: modemVersion, modelUid, label: modemLabel },
    ],
  };
  return executeAxios(url, method, headers, requestBody);
}

export async function requestPutModifyModemStatus(companyUid, modemList) {
  const url = '/modem';
  const method = 'put';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    modemList,
  };
  return executeAxios(url, method, headers, requestBody);
}

export async function requestDeleteModem(companyUid, modemUidList) {
  const url = '/modem';
  const method = 'delete';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {
    companyUid,
    modemUidList,
  };
  return executeAxios(url, method, headers, requestBody);
}
