import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import ManagerTableHeader from 'components/manager/manager-table-header';
import { changeSelectedUpdateManager } from 'reducers/manager/action';
import { useDispatch } from 'react-redux';
import UpdateManagerModal from 'components/manager/update-manager-modal';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    textAlign: 'center !important',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgb(221,243,255)',
    },
    cursor: 'pointer',
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: '100%',
    minWidth: 800,
  },
  tablePagination: {
    zIndex: 9999,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rgb(221,243,255)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function ManagerTable({
  data,
  onDeleteManagerInfo,
  onChangeUpdateManagerList,
  onChangeUpdateManagerInfoModalStatus,
  isVisibleUpdateManagerInfoModal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [originStatus, setOriginStatus] = useState(data);
  const [selectStatus, setSelectStatus] = useState(data);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRadioButton = (row, value) => {
    const { managerUid } = row;
    // 변경된 값 = modem
    const manager = {
      ...row,
      isActive: value,
    };
    // selectStatus 리스트에 uid 체크
    if (selectStatus.findIndex(x => x.managerUid === managerUid) > -1) {
      setSelectStatus(prevState =>
        prevState.map(status =>
          status.managerUid === managerUid ? { ...manager } : { ...status },
        ),
      );
    } else {
      setSelectStatus(prevState => prevState.concat(manager));
    }
  };

  const onClickTableRow = row => {
    dispatch(changeSelectedUpdateManager(row));
    onChangeUpdateManagerInfoModalStatus(true);
  };

  useEffect(() => {
    setOriginStatus(data);
    setSelectStatus(data);
  }, [data]);

  useEffect(() => {
    onChangeUpdateManagerList(selectStatus);
  }, [selectStatus]);

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <ManagerTableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {selectStatus.length > 0 &&
              stableSort(selectStatus, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, key) => (
                  <StyledTableRow
                    key={row.managerUid}
                    onClick={() => onClickTableRow(row)}
                  >
                    <StyledTableCell>
                      {page * rowsPerPage + key + 1}
                    </StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.cellphone}</StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    <StyledTableCell>
                      {moment(row.createdAt).format('YYYY.MM.DD HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {originStatus.find(
                        item => item.managerUid === row.managerUid,
                      ).isActive === 'Y'
                        ? '활성'
                        : '비활성'}
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label={row.managerUid}
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            checked={row.isActive === 'Y'}
                            value="Y"
                            control={<Radio size="small" />}
                            labelPlacement="start"
                            label="활성"
                            onClick={e => e.stopPropagation()}
                            onChange={e =>
                              handleRadioButton(row, e.target.value)
                            }
                          />
                          <FormControlLabel
                            checked={row.isActive === 'N'}
                            value="N"
                            control={<Radio size="small" />}
                            labelPlacement="start"
                            label="비활성"
                            onClick={e => e.stopPropagation()}
                            onChange={e =>
                              handleRadioButton(row, e.target.value)
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        style={{ marginLeft: 15 }}
                        onClick={e => {
                          e.stopPropagation();
                          onDeleteManagerInfo(row.managerUid);
                        }}
                      >
                        삭제
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={selectStatus.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
        onPageChange={handleChangePage}
      />
      <UpdateManagerModal
        isVisibleUpdateManagerInfoModal={isVisibleUpdateManagerInfoModal}
        onChangeUpdateManagerInfoModalStatus={
          onChangeUpdateManagerInfoModalStatus
        }
      />
    </>
  );
}

ManagerTable.propTypes = {
  data: PropTypes.array.isRequired,
  onDeleteManagerInfo: PropTypes.func,
  onChangeUpdateManagerList: PropTypes.func,
  onChangeUpdateManagerInfoModalStatus: PropTypes.func,
  isVisibleUpdateManagerInfoModal: PropTypes.bool,
};

export default ManagerTable;
