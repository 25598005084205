import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import CompanyTableHeader from 'components/company/company-table-header';
import { changeSelectedModifyCompany } from 'reducers/company/action';
import { useDispatch } from 'react-redux';
import ModifyCompanyModal from 'components/company/modify-company-modal';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    textAlign: 'center !important',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgb(221,243,255)',
    },
    cursor: 'pointer',
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: '100%',
    minWidth: 700,
  },
  tablePagination: {
    zIndex: 9999,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rgb(221,243,255)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function CompanyTable({
  data,
  onChangeModifyCompanyModalStatus,
  isVisibleModifyCompanyModal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [selectStatus, setSelectStatus] = useState(data);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setSelectStatus(data);
  }, [data]);

  const onClickTableRow = row => {
    dispatch(changeSelectedModifyCompany(row));
    onChangeModifyCompanyModalStatus(true);
  };

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <CompanyTableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {selectStatus.length > 0 &&
              stableSort(selectStatus, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, key) => (
                  <StyledTableRow
                    key={row.uid}
                    onClick={() => onClickTableRow(row)}
                  >
                    <StyledTableCell>
                      {page * rowsPerPage + key + 1}
                    </StyledTableCell>
                    <StyledTableCell>{row.companyName}</StyledTableCell>
                    <StyledTableCell>{row.companyAddress}</StyledTableCell>
                    <StyledTableCell>{row.companyPhone}</StyledTableCell>
                    <StyledTableCell>{row.companyRegisterNum}</StyledTableCell>
                    <StyledTableCell>
                      {moment(row.createdAt).format('YYYY.MM.DD HH:mm')}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={selectStatus.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
        onPageChange={handleChangePage}
      />
      <ModifyCompanyModal
        isVisibleModifyCompanyModal={isVisibleModifyCompanyModal}
        onChangeModifyCompanyModalStatus={onChangeModifyCompanyModalStatus}
      />
    </>
  );
}

CompanyTable.propTypes = {
  data: PropTypes.array.isRequired,
  onChangeModifyCompanyModalStatus: PropTypes.func,
  isVisibleModifyCompanyModal: PropTypes.bool,
};

export default CompanyTable;
