import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';

const StyledTextField = withStyles(() => ({
  root: {
    '& label': {
      color: 'white',
      borderBottomColor: 'white',
    },
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1976d2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1976d2',
      },
    },
  },
  input: {
    color: 'white',
  },
}))(TextField);

function SignInInput({ label, value, onChange, type }) {
  return (
    <StyledTextField
      label={label}
      value={value}
      variant="outlined"
      onChange={onChange}
      fullWidth
      style={{ width: '80%', marginBottom: '16px' }}
      type={type}
      size="small"
    />
  );
}

SignInInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SignInInput;
