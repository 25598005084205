import { pickBy } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { modemMapStateSelector } from 'reducers/modem-map/reducer';
import { isRequestSuccess } from 'commons/http-request-helper';
import { requestGetLogData } from 'api/logs';

import { isValidToken } from 'commons/token-helper';
import {
  changeLogsDataLoadingIndicatorStatus,
  changeMapLoadingIndicatorStatus,
  changeModemMapLogData,
  changeTableLogData,
  LOAD_MODEM_MAP_LOG_DATA,
  LOAD_TABLE_LOG_DATA,
} from 'reducers/modem-map/action';
import { toast } from 'react-toastify';

/**
 * 로그 데이터 조회 (Log 조회 테이블용)
 */
export function* getTableLogData() {
  if (!isValidToken()) {
    return;
  }
  const { searchFilter } = yield select(modemMapStateSelector);

  const queryString = pickBy(searchFilter, value => value !== '');
  const filter = {
    ...queryString,
  };

  try {
    const response = yield call(requestGetLogData, filter);

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error);
    }
    yield put(changeTableLogData(response.body.data));
  } catch (e) {
    toast.error('단말기 데이터 조회 실패', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changeLogsDataLoadingIndicatorStatus(false));
  }
}

/**
 * 단말기 지도 로그 데이터 조회 (단말기 지도용)
 */
export function* getModemMapLogData() {
  if (!isValidToken()) {
    return;
  }

  try {
    let page = 0;
    while (true) {
      const { mapSearchFilter, modemMapLogData } = yield select(
        modemMapStateSelector,
      );
      const queryString = pickBy(mapSearchFilter, value => value !== '');

      const filter = {
        ...queryString,
        page,
        dataType: 'G, W',
      };

      const response = yield call(requestGetLogData, filter);

      const newData = [
        ...modemMapLogData.logGroups,
        ...response.body.data.logGroups.map(item => item),
      ];

      if (!isRequestSuccess(response.statusCode)) {
        throw new Error(response.body.error);
      }
      yield put(
        changeModemMapLogData({
          totalCount: response.body.data.totalCount,
          last: response.body.data.last,
          logGroups: newData,
        }),
      );

      // 조회한 페이지가 마지막 페이지였을 경우
      if (response.body.data.last) {
        break;
      }
      page += 1;
    }
  } catch (e) {
    toast.error('단말기 지도 데이터 조회 실패', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changeMapLoadingIndicatorStatus(false));
  }
}

export default function* modemMapSaga() {
  yield takeLatest(LOAD_TABLE_LOG_DATA, getTableLogData);
  yield takeLatest(LOAD_MODEM_MAP_LOG_DATA, getModemMapLogData);
}
