/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_ENCRYPT_KEY,
  CHANGE_ENCRYPT_KEY_VERSION,
  CHANGE_SEARCH_ENCRYPT_KEY,
  CHANGE_SEARCH_ENCRYPT_KEY_VERSION,
  CHANGE_SEARCH_ENCRYPT_KEY_IS_ACTIVE,
  LOAD_ENCRYPT_KEY_LIST_SUCCESS,
  LOAD_ENCRYPT_KEY_LIST_FAILED,
  CHANGE_MODIFY_ENCRYPT_KEY_LIST,
  CHANGE_MODIFY_ENCRYPT_KEY_MODAL_STATUS,
  CHANGE_MODIFY_ENCRYPT_KEY,
  CHANGE_MODIFY_ENCRYPT_KEY_VERSION,
  CHANGE_SELECTED_MODIFY_ENCRYPT_KEY,
} from 'reducers/encrypt-key/action';

const initialState = {
  encryptKeyList: [],
  registerEncryptKeyInfo: {
    encryptKey: '',
    encryptKeyVersion: '',
  },
  modifyEncryptKeyList: [],
  searchFilter: {
    encryptKey: '',
    encryptKeyVersion: '',
    isActive: '',
  },
  isVisibleModifyEncryptKeyModal: false,
  modifyEncryptKey: {
    selectedEncryptKey: {},
    encryptKey: '',
    encryptKeyVersion: '',
  },
};

export const encryptKeyStateSelector = state => state.encryptKeyReducer;

export default createReducer(initialState, {
  [LOAD_ENCRYPT_KEY_LIST_SUCCESS]: (state, { payload: encryptKeyList }) => {
    state.encryptKeyList = encryptKeyList;
  },
  [LOAD_ENCRYPT_KEY_LIST_FAILED]: state => {
    state.encryptKeyList = [];
  },
  [CHANGE_ENCRYPT_KEY]: (state, { payload: encryptKey }) => {
    state.registerEncryptKeyInfo.encryptKey = encryptKey;
  },
  [CHANGE_ENCRYPT_KEY_VERSION]: (state, { payload: encryptKeyVersion }) => {
    state.registerEncryptKeyInfo.encryptKeyVersion = encryptKeyVersion;
  },
  [CHANGE_MODIFY_ENCRYPT_KEY_LIST]: (
    state,
    { payload: modifyEncryptKeyList },
  ) => {
    state.modifyEncryptKeyList = modifyEncryptKeyList;
  },
  [CHANGE_SEARCH_ENCRYPT_KEY]: (state, { payload: searchEncryptKey }) => {
    state.searchFilter.encryptKey = searchEncryptKey;
  },
  [CHANGE_SEARCH_ENCRYPT_KEY_VERSION]: (
    state,
    { payload: searchEncryptKeyVersion },
  ) => {
    state.searchFilter.encryptKeyVersion = searchEncryptKeyVersion;
  },
  [CHANGE_SEARCH_ENCRYPT_KEY_IS_ACTIVE]: (
    state,
    { payload: searchEncryptKeyIsActive },
  ) => {
    state.searchFilter.isActive = searchEncryptKeyIsActive;
  },
  [CHANGE_MODIFY_ENCRYPT_KEY_MODAL_STATUS]: (
    state,
    { payload: isVisibleModifyEncryptKeyModal },
  ) => {
    state.isVisibleModifyEncryptKeyModal = isVisibleModifyEncryptKeyModal;
  },
  [CHANGE_MODIFY_ENCRYPT_KEY]: (state, { payload: modifyEncryptKey }) => {
    state.modifyEncryptKey.encryptKey = modifyEncryptKey;
  },
  [CHANGE_MODIFY_ENCRYPT_KEY_VERSION]: (
    state,
    { payload: modifyEncryptKeyVersion },
  ) => {
    state.modifyEncryptKey.encryptKeyVersion = modifyEncryptKeyVersion;
  },
  [CHANGE_SELECTED_MODIFY_ENCRYPT_KEY]: (
    state,
    { payload: selectedModifyEncryptKey },
  ) => {
    state.modifyEncryptKey.selectedEncryptKey = selectedModifyEncryptKey;
    state.modifyEncryptKey.encryptKey = selectedModifyEncryptKey.encryptKey;
    state.modifyEncryptKey.encryptKeyVersion =
      selectedModifyEncryptKey.encryptKeyVersion;
  },
});
