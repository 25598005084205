import styled from 'styled-components';

const CenterLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: ${props =>
    props.loginPage
      ? ' linear-gradient(to bottom, #859398, #283048)'
      : 'white'};
`;

export default CenterLayout;
