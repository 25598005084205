import { pickBy, differenceWith, isEqual } from 'lodash';
import { isRequestSuccess } from 'commons/http-request-helper';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeEncryptKey,
  changeEncryptKeyVersion,
  DELETE_ENCRYPT_KEY,
  LOAD_ENCRYPT_KEY_LIST,
  loadEncryptKeyList,
  loadEncryptKeyListFailed,
  loadEncryptKeyListSuccess,
  MODIFY_ENCRYPT_KEY_INFO,
  MODIFY_ENCRYPT_KEY_LIST,
  REGISTER_ENCRYPT_KEY,
} from 'reducers/encrypt-key/action';

import {
  requestDeleteEncryptKey,
  requestEncryptKey,
  requestGetEncryptKeyList,
  requestPutEncryptKey,
} from 'api/encrypt-key';
import { encryptKeyStateSelector } from 'reducers/encrypt-key/reducer';
import { companyStateSelector } from 'reducers/company/reducer';
import { isValidToken } from 'commons/token-helper';
import { toast } from 'react-toastify';

/**
 * 등록된 암호화 방식 리스트 조회 (활성 여부 상관없이)
 */
export function* getEncryptKeyList() {
  if (!isValidToken()) {
    return;
  }
  try {
    const { selectedCompany } = yield select(companyStateSelector);
    const { searchFilter } = yield select(encryptKeyStateSelector);

    if (!selectedCompany.uid) {
      return;
    }

    const queryString = pickBy(searchFilter, value => value !== '');

    const response = yield call(
      requestGetEncryptKeyList,
      selectedCompany.uid,
      queryString,
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }

    yield put(loadEncryptKeyListSuccess(response.body.data.encryptKeyList));
  } catch (e) {
    yield put(loadEncryptKeyListFailed());
    toast.error('암호화 방식 조회 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

/**
 * 암호화 방식 댠일 등록
 */
export function* postEncryptKey() {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  const { registerEncryptKeyInfo } = yield select(encryptKeyStateSelector);

  if (registerEncryptKeyInfo.encryptKey === '') {
    toast.info('암호화 종류를 선택해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  if (registerEncryptKeyInfo.encryptKeyVersion === '') {
    toast.info('암호화 버전을 입력해주세요.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  if (!/^[A-Z][1-9]$/.test(registerEncryptKeyInfo.encryptKeyVersion)) {
    toast.info(
      '암호화 버전은 A~Z와 1~9까지의 조합으로 입력해주세요. (A1, A2, L1, L2...)',
      {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      },
    );
    return;
  }

  try {
    const response = yield call(
      requestEncryptKey,
      selectedCompany.uid,
      registerEncryptKeyInfo.encryptKey,
      registerEncryptKeyInfo.encryptKeyVersion,
    );
    if (response.body.code === 'E601') {
      toast.info('해당 암호화 키와 버전 정보가 이미 등록되어 있습니다.', {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
  } catch (e) {
    toast.error('암호화 방식 등록을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    yield put(changeEncryptKey(''));
    yield put(changeEncryptKeyVersion(''));
    yield put(loadEncryptKeyList());
  }
}

export function* deleteEncryptKey({ payload: encryptUid }) {
  if (!isValidToken()) {
    return;
  }
  const { selectedCompany } = yield select(companyStateSelector);
  try {
    const response = yield call(
      requestDeleteEncryptKey,
      selectedCompany.uid,
      encryptUid,
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadEncryptKeyList());
  } catch (e) {
    toast.error('암호화 방식 영구 삭제를 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export function* updateEncryptKey() {
  if (!isValidToken()) {
    return;
  }

  const { encryptKeyList, modifyEncryptKeyList } = yield select(
    encryptKeyStateSelector,
  );

  const { selectedCompany } = yield select(companyStateSelector);

  const modifiedEncryptList = differenceWith(
    modifyEncryptKeyList,
    encryptKeyList,
    isEqual,
  ).map(item => ({
    uid: item.uid,
    isActive: item.isActive,
  }));

  try {
    const response = yield call(
      requestPutEncryptKey,
      selectedCompany.uid,
      modifiedEncryptList,
    );
    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }
    yield put(loadEncryptKeyList());
  } catch (e) {
    toast.error('암호화 방식 상태변경을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export function* updateEncryptKeyInfo() {
  if (!isValidToken()) {
    return;
  }

  const { modifyEncryptKey } = yield select(encryptKeyStateSelector);

  const {
    selectedEncryptKey,
    encryptKey,
    encryptKeyVersion,
  } = modifyEncryptKey;

  const { selectedCompany } = yield select(companyStateSelector);

  const modifyEncryptKeyInfo = {
    ...selectedEncryptKey,
    encryptKey,
    encryptKeyVersion,
  };

  try {
    const response = yield call(requestPutEncryptKey, selectedCompany.uid, [
      modifyEncryptKeyInfo,
    ]);

    if (response.body.code === 'E601') {
      toast.info('해당 암호화 키와 버전 정보가 이미 등록되어 있습니다.', {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!isRequestSuccess(response.statusCode)) {
      throw new Error(response.body.error.toString());
    }

    yield put(loadEncryptKeyList());
  } catch (e) {
    toast.error('암호화 방식 정보 수정을 실패하였습니다.', {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export default function* encryptKeySaga() {
  yield takeLatest(LOAD_ENCRYPT_KEY_LIST, getEncryptKeyList);
  yield takeLatest(REGISTER_ENCRYPT_KEY, postEncryptKey);
  yield takeLatest(DELETE_ENCRYPT_KEY, deleteEncryptKey);
  yield takeLatest(MODIFY_ENCRYPT_KEY_LIST, updateEncryptKey);
  yield takeLatest(MODIFY_ENCRYPT_KEY_INFO, updateEncryptKeyInfo);
}
