import React, { useEffect } from 'react';
import PageTitle from 'components/common/page-title';
import ContainerSubTitle from 'components/common/sub-title';
import RegisterEncryptKeyInput from 'components/encrypt-key/register-encrypt-key-input';
import { Button, Container } from '@mui/material';
import EncryptKeyTable from 'components/encrypt-key/encrypt-key-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeEncryptKey,
  changeEncryptKeyVersion,
  deleteEncryptKey,
  loadEncryptKeyList,
  modifyEncryptKeyList,
  registerEncryptKey,
  changeModifyEncryptKeyList,
  changeSearchEncryptKey,
  changeSearchEncryptKeyVersion,
  changeSearchEncryptKeyIsActive,
  changeModifyEncryptKeyModalStatus,
} from 'reducers/encrypt-key/action';
import { encryptKeyStateSelector } from 'reducers/encrypt-key/reducer';
import { companyStateSelector } from 'reducers/company/reducer';
import EncryptKeySearchInput from 'components/encrypt-key/encrypt-key-search-input';
import { CommonCard } from 'components/common/card';

function EncryptKey() {
  const dispatch = useDispatch();

  const { selectedCompany } = useSelector(companyStateSelector);

  const {
    encryptKeyList,
    searchFilter,
    isVisibleModifyEncryptKeyModal,
    registerEncryptKeyInfo,
  } = useSelector(encryptKeyStateSelector);

  function onLoadEncryptKeyList() {
    dispatch(loadEncryptKeyList());
  }

  function onChangeEncryptKey(newEncryptKey) {
    dispatch(changeEncryptKey(newEncryptKey));
  }

  function onChangeEncryptKeyVersion(newEncryptKeyVersion) {
    dispatch(changeEncryptKeyVersion(newEncryptKeyVersion));
  }

  function onRegisterEncryptKey() {
    dispatch(registerEncryptKey());
  }

  function onDeleteEncryptKey(uid) {
    const message = `
     해당 암호화 방식을 삭제 하시겠습니까?
    `;

    const result = window.confirm(message);

    if (!result) {
      return;
    }
    dispatch(deleteEncryptKey(uid));
  }

  function onChangeModifyEncryptKeys(selectedModifyEncryptKeyList) {
    dispatch(changeModifyEncryptKeyList(selectedModifyEncryptKeyList));
  }

  function onModifyEncryptKey() {
    dispatch(modifyEncryptKeyList());
  }

  function onChangeSearchEncryptKey(searchEncryptKey) {
    dispatch(changeSearchEncryptKey(searchEncryptKey));
  }

  function onChangeSearchEncryptKeyVersion(searchEncryptKeyVersion) {
    dispatch(changeSearchEncryptKeyVersion(searchEncryptKeyVersion));
  }

  function onChangeSearchEncryptKeyIsActive(searchEncryptKeyIsActive) {
    dispatch(changeSearchEncryptKeyIsActive(searchEncryptKeyIsActive));
  }

  function onChangeModifyEncryptKeyModalStatus(isVisibleModalStatus) {
    dispatch(changeModifyEncryptKeyModalStatus(isVisibleModalStatus));
  }

  useEffect(() => {
    onLoadEncryptKeyList();
  }, [selectedCompany]);

  return (
    <Container
      style={{
        margin: 0,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <PageTitle>암호화 방식 관리</PageTitle>
      <Container style={{ marginBottom: 20, padding: 20 }}>
        <CommonCard>
          <ContainerSubTitle>암호화 방식 등록</ContainerSubTitle>
          <RegisterEncryptKeyInput
            encryptKey={registerEncryptKeyInfo.encryptKey}
            encryptKeyVersion={registerEncryptKeyInfo.encryptKeyVersion}
            onChangeEncryptKeyVersion={onChangeEncryptKeyVersion}
            onRegisterEncryptKey={onRegisterEncryptKey}
            onChangeEncryptKey={onChangeEncryptKey}
          />
        </CommonCard>
      </Container>
      <Container>
        <ContainerSubTitle>등록 암호화 방식 리스트</ContainerSubTitle>
        <div style={{ height: 20 }} />
        <CommonCard>
          <ContainerSubTitle>검색 조건</ContainerSubTitle>
          <EncryptKeySearchInput
            searchEncryptKey={searchFilter.encryptKey}
            searchEncryptKeyVersion={searchFilter.encryptKeyVersion}
            searchEncryptKeyIsActive={searchFilter.isActive}
            onChangeSearchEncryptKey={onChangeSearchEncryptKey}
            onChangeSearchEncryptKeyVersion={onChangeSearchEncryptKeyVersion}
            onChangeSearchEncryptKeyIsActive={onChangeSearchEncryptKeyIsActive}
            onLoadEncryptKeyList={onLoadEncryptKeyList}
          />
        </CommonCard>
        <Button
          variant="outlined"
          style={{ float: 'right', marginBottom: '5px' }}
          onClick={onModifyEncryptKey}
        >
          변경 적용
        </Button>
        <EncryptKeyTable
          data={encryptKeyList}
          onDeleteEncryptKey={onDeleteEncryptKey}
          onChangeModifyEncryptKeys={onChangeModifyEncryptKeys}
          onChangeModifyEncryptKeyModalStatus={
            onChangeModifyEncryptKeyModalStatus
          }
          isVisibleModifyEncryptKeyModal={isVisibleModifyEncryptKeyModal}
        />
      </Container>
    </Container>
  );
}

export default EncryptKey;
