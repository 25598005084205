export const ARM_LOGIN_ENDPOINT = `${
  process.env.REACT_APP_ARM_ENDPOINT
}/oauth2/authorize?client_id=${
  process.env.REACT_APP_ARM_CLIENT_ID
}&redirect_uri=${encodeURIComponent(
  process.env.REACT_APP_ARM_REDIRECT_URI,
)}&scope=offline_access&response_type=code`;

export const ARM_LOGOUT_ENDPOINT = `${
  process.env.REACT_APP_ARM_ENDPOINT
}/oauth2/logout?client_id=${process.env.REACT_APP_ARM_CLIENT_ID}`;
