import React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { companyStateSelector } from 'reducers/company/reducer';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      backgroundColor: '#ffffff',
    },
  },
}));

export default function CompanySelectInput({
  onChangeSelectedCompany,
  companyList,
}) {
  const { selectedCompany } = useSelector(companyStateSelector);

  const onChangeSelectedCompanyHandler = company => {
    onChangeSelectedCompany(company);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FormControl variant="standard">
        <Select
          displayEmpty
          value={selectedCompany.companyName || ''}
          input={<BootstrapInput />}
          style={{ width: 225 }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em>회사를 선택해주세요.</em>
          </MenuItem>
          {companyList.map(company => (
            <MenuItem
              key={company.uid}
              value={company.companyName}
              onClick={() => onChangeSelectedCompanyHandler(company)}
            >
              {company.companyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

CompanySelectInput.propTypes = {
  onChangeSelectedCompany: PropTypes.func,
  companyList: PropTypes.array,
};
