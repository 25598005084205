import executeAxios from 'commons/http-request-helper';

/**
 * 사용자 정보 수정 API
 */
export async function requestPutUserInfo(updateUserList) {
  const url = '/users';
  const method = 'put';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = updateUserList;

  return executeAxios(url, method, headers, requestBody);
}

/**
 * 사용자 정보 삭제 API
 */
export async function requestDeleteUserInfo(userUid) {
  const url = `/users/${userUid}`;
  const method = 'delete';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = {};

  return executeAxios(url, method, headers, requestBody);
}

/**
 * 사용자 비밀번호 변경 API
 */
export async function requestPutUserPassword(password, newPassword) {
  const url = '/users/password';
  const method = 'put';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const requestBody = { password, newPassword };

  return executeAxios(url, method, headers, requestBody);
}
