import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentType,
  DataContainer,
  DataText,
  SubDataContainer,
} from 'components/common/data-style';

export default function CellTowerData({ content }) {
  const { cell } = content;

  return (
    <DataContainer>
      <ContentType>Cell</ContentType>
      <SubDataContainer>
        <ContentType>ID</ContentType>
        <DataText>{cell.id}</DataText>
        <ContentType>신호세기</ContentType>
        <DataText>{cell.strength}</DataText>
      </SubDataContainer>
    </DataContainer>
  );
}

CellTowerData.propTypes = {
  content: PropTypes.object,
};
